import React, { FC } from 'react'

import { ReferenceField, useRecordContext } from 'react-admin'

type PolymorphicReferenceFieldProps = {
  children: React.ReactElement
  polymorphicRelationship: string
  source: string // this is used strictly for the label

  link?: boolean
  referenceFieldBasePath?: string
}

export const PolymorphicReferenceField: FC<PolymorphicReferenceFieldProps> = ({
  children,
  link,
  polymorphicRelationship,
  referenceFieldBasePath,
}) => {
  const record = useRecordContext()

  if (!record) return null

  const source = `${polymorphicRelationship}Id`
  const relationshipType = record[`${polymorphicRelationship}Type`]

  return (
    <ReferenceField
      label={relationshipType}
      source={source}
      reference={relationshipType}
      link={
        link && referenceFieldBasePath
          ? record => `${referenceFieldBasePath}/${relationshipType.toLowerCase()}/${record.accountableId}`
          : null
      }
    >
      {children}
    </ReferenceField>
  )
}
