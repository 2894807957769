import { buildQuery } from 'ra-data-graphql-simple'
import { gql } from '@apollo/client'

import { capitalizeStr } from 'shared/helpers'

const mapCustomPathToResource = (path: string): string => {
  // path = path.toLowerCase()
  let resource: string = path

  switch (path) {
    // case 'settings':
    //   resource = 'account';
    //   break;
    case 'team':
      resource = 'membership'
      break
    default:
      break
  }

  return resource
}

const resourcePathToResource = (resourcePath: string): string => {
  // to support dash urls such as
  // - admin/user => User
  // - account/user => User
  // - account/team => User (see custom mapper)

  const path = resourcePath.includes('/') ? resourcePath.split('/').pop() : resourcePath

  const resource = capitalizeStr(mapCustomPathToResource(path), false)

  return resource
}

export default introspection => (fetchType, resource, params) => {
  resource = resourcePathToResource(resource)

  const builtQuery = buildQuery(introspection)(fetchType, resource, params)

  // Extend the builder for specialized GET_LIST requests with polymorphic associations
  if (['GET_LIST', 'GET_MANY_REFERENCE'].includes(fetchType)) {
    // if (fetchType == 'GET_LIST' && resource === 'Version') {
    //   return {
    //     // Use the default query variables and parseResponse
    //     ...builtQuery,
    //     // Override the query
    //     query: gql`
    //       query allVersions($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: VersionFilter) {
    //         items: allVersions(
    //           page: $page
    //           perPage: $perPage
    //           sortField: $sortField
    //           sortOrder: $sortOrder
    //           filter: $filter
    //         ) {
    //           id
    //           itemId
    //           itemType
    //           event
    //           whodunnit
    //           createdAt
    //           item {
    //             ... on User {
    //               id
    //               name
    //             }
    //           }
    //         }
    //         total: _allVersionsMeta(page: $page, perPage: $perPage, filter: $filter) {
    //           count
    //         }
    //       }
    //     `,
    //   }
    // } else 
    if (resource === 'Notification') {
      // TODO dynamic attrs for notification and notifiable
      const query = gql`
        query allNotifications(
          $page: Int
          $perPage: Int
          $sortField: String
          $sortOrder: String
          $filter: NotificationFilter
        ) {
          items: allNotifications(
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
            filter: $filter
          ) {
            id
            type
            createdAt
            channel {
              id
              type
            }
            notifiable {
              ... on Form {
                id
                notifiableClass
              }
            }
          }
          total: _allNotificationsMeta(page: $page, perPage: $perPage, filter: $filter) {
            count
          }
        }
      `

      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query,
      }
    }
  }

  return builtQuery
}
