import React, { FC } from 'react'
import type { Connector } from 'wagmi'
import { Button, Typography, Stack } from '@mui/material'
import { colors } from '@core/dash/layout/CustomLayout/theme'

const imgSrc = {
  ['MetaMask']: '/images/crypto/metaMask-wallet-logo.png',
  ['Coinbase Wallet']: '/images/crypto/coinbase-wallet-logo.png',
  ['WalletConnect']: '/images/crypto/walletConnect-logo.png',
}
interface WalletButtonProps {
  onClick?: (connector: Connector) => void
  disabled: boolean
  content?: React.ReactElement | string
  connector: Connector
  helpContent?: React.ReactElement | string
}

export const WalletConnectButton: FC<WalletButtonProps> = ({
  connector,
  onClick = connector => {},
  disabled,
  content,
  helpContent,
}) => {
  return (
    <Button
      key={connector?.id}
      variant='walletConnect'
      onClick={() => onClick(connector)}
      disabled={disabled}
      sx={{ letterSpacing: 0, textAlign: 'left', px: '1.5rem', '&:hover': { color: colors.grey10 } }}
    >
      <img
        src={imgSrc[connector?.name]}
        alt={connector?.name}
        style={{ height: '35px', width: '35px', marginRight: '10px' }}
      />
      <Stack>
        <Typography fontWeight='bold'>{content ? content : connector?.name}</Typography>
        <Typography fontSize='0.70rem'>{helpContent ? helpContent : ''}</Typography>
      </Stack>
    </Button>
  )
}
