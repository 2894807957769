import PubSub from 'pubsub-js'

export enum AnalyticsEvents {
  UserAuthStart = 'analytics.user.auth-start',
  UserRegister = 'analytics.user.register',
  UserLogin = 'analytics.user.login',

  // Purchase flow.
  AssetPurchase1 = 'analytics.asset.purchase.1-start',
  AssetPurchase2 = 'analytics.asset.purchase.2-checkout-confirmed',
  AssetPurchase3 = 'analytics.asset.purchase.3-txn-pending',
  AssetPurchase4 = 'analytics.asset.purchase.4-txn-confirmed',
  // Asset transfers.
  AssetTransfer1 = 'analytics.asset.transfer.1-start',
  AssetTransfer2 = 'analytics.asset.transfer.2-transfer-confirmed',
  AssetTransfer3 = 'analytics.asset.transfer.3-txn-pending',
  AssetTransfer4 = 'analytics.asset.transfer.4-txn-confirmed',
  // Asset interactions.
  UnlockableContentOpen = 'analytics.unlockable-content.open',
  // Connections.
  UserWalletLink = 'analytics.user.connections.web3-wallet-link',
  // Misc.
  PageView = 'analytics.pageview',
  NotFound = 'analytics.portal/404', // "_error.html.slim" page uses hardcoded "portal/404" value.
}

export class Analytics {
  static publishEvent = (event: AnalyticsEvents, data?: any) => {
    PubSub.publish(event, data)
  }

  static subscribeToEvent = (callback: (event: string, data: any) => void) => {
    PubSub.subscribe('analytics', callback)
  }
}
