// import React, { useState } from 'react'
// Components
import { Divider, styled } from '@mui/material'
// Hooks
// import { useRedirect } from 'react-admin'
// // Utils
// import PubSub from 'pubsub-js'
// import { PlausibleEvents } from '@core/dash/utils/plausible'
import { colors } from '@core/dash/layout/CustomLayout/theme'
// import { simpleFetch } from 'clients/fetch'
// import { User } from 'shared/types'
import { LoginForm } from './LoginForm'
// import { ReconfirmEmail } from './ReconfirmEmail'
// import { ConfirmLoginEmail } from './ConfirmLoginEmail'
// import { ConfirmRegistrationEmail } from './ConfirmRegistrationEmail'
// import { SignupForm } from './SignupForm'
// import { manuallyValidatePasswordlessAuth } from './ManuallyVerifyPasswordlessAuthButton'

export enum AuthFlowType {
  Login = 'login',
  // Signup = 'signup',
  // ConfirmRegistrationEmail = 'confirmRegistrationEmail',
  // ConfirmPasswordlessLogin = 'confirmPasswordlessLogin',
  // ReconfirmEmail = 'reconfirmEmail',
}

// export const triggerAuthLink = ({ url, onSuccess }) => {
//   simpleFetch({
//     onSuccess,
//     url,
//   })
// }

export type CachedAuthDataType = { channelId: string; event: string; remember_me?: boolean } | null

export const AuthFlow = () => {
  // const [cachedAuthData, _setCachedAuthData] = useState<CachedAuthDataType>()
  // const [authFlowStep, setAuthFlowStep] = useState(AuthFlowType.Login)
  // const [userData, setUserData] = useState<User>()
  // const redirect = useRedirect()

  // const cachedAuthDataRef = React.useRef<CachedAuthDataType>(cachedAuthData)

  // const setCachedAuthData = (data: CachedAuthDataType) => {
  //   // required to set a ref for the onFocus event

  //   cachedAuthDataRef.current = data
  //   _setCachedAuthData(data)
  // }

  // const handleAuthFlowStepChange = (authFlowStep: AuthFlowType) => {
  //   setAuthFlowStep(authFlowStep)
  // }

  return (
    <LoginForm
    // stepChange={handleAuthFlowStepChange}
    // setCachedAuthData={setCachedAuthData}
    // setUserData={setUserData}
    />
  )

  // const fetchAuthLinkOnWindowFocus = () => {
  //   const authData = cachedAuthDataRef.current

  //   if (!authData?.channelId) return

  //   const onValidateAuthSuccess = () => {
  //     switch (authData.event) {
  //       case 'confirm':
  //         PubSub.publish(PlausibleEvents.UserRegisterConfirmedEmail)
  //         break
  //       case 'login':
  //         PubSub.publish(PlausibleEvents.UserLoginConfirmedLink)
  //     }

  //     redirect('/')
  //   }

  //   manuallyValidatePasswordlessAuth({ authData, onManualValidationSuccess: onValidateAuthSuccess })
  // }

  // React.useEffect(() => {
  //   window.addEventListener('focus', fetchAuthLinkOnWindowFocus)

  //   return () => {
  //     window.removeEventListener('focus', fetchAuthLinkOnWindowFocus)
  //   }
  // }, [])

  // if (authFlowStep === AuthFlowType.Login) {
  //   return (
  //     <LoginForm
  //       stepChange={handleAuthFlowStepChange}
  //       setCachedAuthData={setCachedAuthData}
  //       setUserData={setUserData}
  //     />
  //   )
  // }

  // if (authFlowStep === AuthFlowType.Signup) {
  //   return (
  //     <SignupForm
  //       stepChange={handleAuthFlowStepChange}
  //       setCachedAuthData={setCachedAuthData}
  //       setUserData={setUserData}
  //     />
  //   )
  // }

  // if (authFlowStep === AuthFlowType.ConfirmRegistrationEmail) {
  //   return (
  //     <ConfirmRegistrationEmail
  //       cachedAuthData={cachedAuthData}
  //       stepChange={handleAuthFlowStepChange}
  //       userData={userData}
  //     />
  //   )
  // }

  // if (authFlowStep === AuthFlowType.ReconfirmEmail) {
  //   return <ReconfirmEmail cachedAuthData={cachedAuthData} stepChange={handleAuthFlowStepChange} userData={userData} />
  // }

  // return <ConfirmLoginEmail cachedAuthData={cachedAuthData} stepChange={handleAuthFlowStepChange} userData={userData} />
}

export const StyledDivider = styled(Divider)(() => ({
  borderColor: colors.grey90,
  borderStyle: 'solid',
}))
