import React, { FC } from 'react'
import { CircularProgress, ContainerProps, Box } from '@mui/material'
interface SpinnerProps extends ContainerProps {
  size?: number
}
export const Spinner: FC<SpinnerProps> = ({ size = 60, ...rest }) => {
  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', ...rest.sx }}>
      <CircularProgress size={size} />
    </Box>
  )
}
