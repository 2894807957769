import { SvgIcon, SvgIconProps } from '@mui/material'

export const ViewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M13.75 16.75C14.4404 16.75 15 16.1904 15 15.5C15 14.8096 14.4404 14.25 13.75 14.25C13.0596 14.25 12.5 14.8096 12.5 15.5C12.5 16.1904 13.0596 16.75 13.75 16.75Z'
        fill='#F7F3F2'
      />
      <path
        d='M18.6106 15.1741C18.2243 14.1903 17.5579 13.3415 16.6939 12.7329C15.83 12.1242 14.8064 11.7825 13.75 11.75C12.6936 11.7825 11.67 12.1242 10.8061 12.7329C9.94206 13.3415 9.27566 14.1903 8.88944 15.1741L8.75 15.5L8.88944 15.8259C9.27566 16.8097 9.94206 17.6585 10.8061 18.2671C11.67 18.8758 12.6936 19.2175 13.75 19.25C14.8064 19.2175 15.83 18.8758 16.6939 18.2671C17.5579 17.6585 18.2243 16.8097 18.6106 15.8259L18.75 15.5L18.6106 15.1741ZM13.75 18C13.2555 18 12.7722 17.8534 12.3611 17.5787C11.95 17.304 11.6295 16.9135 11.4403 16.4567C11.2511 15.9999 11.2016 15.4972 11.298 15.0123C11.3945 14.5273 11.6326 14.0819 11.9822 13.7322C12.3319 13.3826 12.7773 13.1445 13.2623 13.048C13.7472 12.9516 14.2499 13.0011 14.7067 13.1903C15.1635 13.3795 15.554 13.7 15.8287 14.1111C16.1034 14.5222 16.25 15.0055 16.25 15.5C16.2493 16.1628 15.9856 16.7983 15.5169 17.2669C15.0483 17.7356 14.4128 17.9993 13.75 18ZM4.375 11.125H7.5V12.375H4.375V11.125ZM4.375 8H11.875V9.25H4.375V8ZM4.375 4.875H11.875V6.125H4.375V4.875Z'
        fill='#F7F3F2'
      />
      <path
        d='M13.75 1.75H2.5C2.16877 1.75096 1.85139 1.88296 1.61717 2.11717C1.38296 2.35139 1.25096 2.66877 1.25 3V18C1.25096 18.3312 1.38296 18.6486 1.61717 18.8828C1.85139 19.117 2.16877 19.249 2.5 19.25H7.5V18H2.5V3H13.75V9.875H15V3C14.999 2.66877 14.867 2.35139 14.6328 2.11717C14.3986 1.88296 14.0812 1.75096 13.75 1.75Z'
        fill='#F7F3F2'
      />
    </SvgIcon>
  )
}
