import React, { FC } from 'react'
import type { Connector } from 'wagmi'
import { WalletConnectButton } from './WalletConnectButton'
import { useNetwork } from 'wagmi'
import { ALERT_MESSAGE } from '@atmos/components/constants/general'
import { Stack, Alert } from '@mui/material'

const getWalletDisplay = (address: string) => {
  return address?.substring(0, 6) + '...' + address?.substring(address.length - 4)
}

interface ConnectorButtonProps {
  accountData: Record<string, any>
  connectAsync: (connector: Connector) => Promise<any>
  connectors: Connector[]
  errorMessage: string
  isConnected: boolean
  isDisabled: boolean
  isSignLoading: boolean
  setErrorMessage: (message: string) => void
  setIsDisabled: (isDisabled: boolean) => void
  signMessageAsync: () => void
}

const HELPER_CONTENT = {
  ['MetaMask']: 'Using a browser extension',
  ['Coinbase Wallet']: 'Using your mobile or browser extension',
  ['WalletConnect']: 'Using a mobile wallet',
}

export const ConnectorButtons: FC<ConnectorButtonProps> = ({
  accountData,
  connectAsync,
  connectors,
  errorMessage,
  isConnected,
  isDisabled,
  isSignLoading,
  setErrorMessage,
  setIsDisabled,
  signMessageAsync,
}) => {
  const { activeChain } = useNetwork()

  const onClick = connector => {
    setErrorMessage('')
    // If wallet connector is connected but message is not signed, sign it.
    if (isConnected) {
      signMessageAsync()
      setIsDisabled(true)
      // Open metamask website if no wallet is installed.
    } else if (connector.id === 'metaMask' && !connector.ready) {
      window.open('https://metamask.io/download', '_blank')
      // Else connect.
    } else {
      connectAsync(connector)
      setIsDisabled(true)
    }
  }

  switch (true) {
    // If wallet is connected, but message needs to be signed.
    case !!accountData: {
      const walletAddress = getWalletDisplay(accountData.address)
      const authContent = isSignLoading ? 'Requesting Signature' : `Verify ${walletAddress}`
      const authHelpContent = isSignLoading ? 'Please sign the message in your wallet' : 'Click to verify this address'
      const connector = connectors.find(connector => connector.id === accountData.connector?.id)

      return (
        <Stack spacing={3}>
          <WalletConnectButton
            connector={connector}
            disabled={isDisabled}
            content={authContent}
            helpContent={authHelpContent}
            onClick={onClick}
          />
          {!isSignLoading && (
            <Alert severity='info' variant='outlined'>
              {ALERT_MESSAGE.switchAccount}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity='error' variant='outlined'>
              {errorMessage}
            </Alert>
          )}
        </Stack>
      )
    }
    // If there are no wallets installed show download metamask button.
    case !window.ethereum:
      return (
        <Stack spacing={3}>
          <WalletConnectButton
            connector={connectors.find(connector => connector.id === 'metaMask')}
            disabled={isDisabled}
            onClick={onClick}
            content='Download MetaMask'
            helpContent='Install or enable MetaMask'
          />
          <WalletConnectButton
            connector={connectors.find(connector => connector.id === 'coinbaseWallet')}
            disabled={isDisabled}
            onClick={onClick}
            content='Download Coinbase Wallet'
            helpContent='Install or enable Coinbase Wallet'
          />
        </Stack>
      )

    default:
      return (
        <Stack spacing={3}>
          {connectors.map(connector =>
            connector.ready ? (
              <WalletConnectButton
                connector={connector}
                disabled={isDisabled}
                onClick={onClick}
                helpContent={HELPER_CONTENT[connector.name]}
              />
            ) : null
          )}
          {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
        </Stack>
      )
  }
}
