import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Close'

interface DeleteButtonProps {
  onClick: () => void
}

export const DeleteButton: FC<DeleteButtonProps> = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  )
}
