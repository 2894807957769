import { useEffect, useRef } from 'react'

export const parseURLParams = (keys: { key: string; parsedKey?: string }[]): { [key: string]: string } => {
  // search isn't always reliable ex: https://core.test/dash#/login?reset_password_token=abcd
  const [domain, urlParams] = window.location.href.split('?')
  const params = new URLSearchParams(urlParams)
  const parsedParams = {}

  keys.forEach(k => {
    if (params.get(k.key)) parsedParams[k.parsedKey || k.key] = params.get(k.key)
  })

  return parsedParams
}

export const capitalizeStr = (word: string, lowerRest = true): string => {
  if (word.length == 0) return word

  const first = word[0]
  const rest = word.slice(1)

  return first.toUpperCase() + (lowerRest ? rest.toLowerCase() : rest)
}

export const debounce = (callback: (...args: any[]) => void, wait: number): ((...args: any[]) => void) => {
  let timeout: NodeJS.Timeout

  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => callback(...args), wait)
  }
}

// https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
export function usePrevious(value: any): any {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
