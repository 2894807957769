import { useState } from 'react'
// import { Button } from '@atmos/components/buttons/Button'
import {
  // Alert,
  Stack,
  // TextField,
  Box,
  Typography,
  // Chip
} from '@mui/material'
// import { useForm, Controller } from 'react-hook-form'
// import apolloClient from 'clients/apollo'
// import PubSub from 'pubsub-js'
// import { PlausibleEvents } from '@core/dash/utils/plausible'
import { LoginWithPassportButton } from './LoginWithPassportButton'
// import { AuthFlowType, StyledDivider } from '.'
// import { gql } from '@apollo/client'

// export const USER_LOGIN = gql`
//   mutation userRequestLoginLink($userKey: String!, $rememberMe: Boolean!) {
//     userRequestLoginLink(userKey: $userKey, rememberMe: $rememberMe) {
//       user {
//         id
//         email
//         authChannelId
//       }
//       errors
//     }
//   }
// `

export const LoginForm = () =>
  // { stepChange, setCachedAuthData, setUserData }
  {
    // const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    // const form = useForm({ defaultValues: { userKey: '', rememberMe: false } })

    const setIsSubmittingTrue = () => setIsSubmitting(true)
    const setIsSubmittingFalse = () => setIsSubmitting(false)

    // Form values
    // const { formState, handleSubmit, setError } = form

    // const onLoginUser = async formData => {
    //   setCachedAuthData(null)

    //   try {
    //     setIsSubmittingTrue()

    //     const { data } = await apolloClient.mutate({
    //       context: {
    //         headers: {
    //           timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
    //         },
    //       },
    //       mutation: USER_LOGIN,
    //       variables: formData,
    //     })

    //     setIsSubmittingFalse()

    //     const { errors } = data.userRequestLoginLink

    //     if (errors.length > 0 && errors[0] != 'unconfirmed_email') {
    //       setError('userKey', { message: errors[0].toUpperCase() })
    //       return
    //     }

    //     const { user } = data.userRequestLoginLink

    //     let step: string

    //     if (errors[0] === 'unconfirmed_email') {
    //       setCachedAuthData({ channelId: user.authChannelId, event: 'confirm' })
    //       step = AuthFlowType.ReconfirmEmail
    //     } else {
    //       PubSub.publish(PlausibleEvents.UserLoginStart)
    //       setCachedAuthData({ channelId: user.authChannelId, event: 'login', remember_me: formData.rememberMe })
    //       step = AuthFlowType.ConfirmPasswordlessLogin
    //     }

    //     setUserData(user)
    //     stepChange(step)
    //   } catch (e) {
    //     setIsSubmittingFalse()
    //     console.error(e)
    //     setErrorMessage('Something went wrong. Please try again.')
    //   }
    // }

    // const instructionSx = { fontFamily: 'Exo', fontSize: 16, fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }

    return (
      <Stack spacing={5} sx={{ animation: `fadeIn 1s`, width: '100%', maxWidth: 450, pl: 4, pr: 4 }}>
        <Box>
          <Typography component='h1' variant='h2'>
            WELCOME TO PLANET ATMOS
          </Typography>
        </Box>
        <Box sx={{ flexDirection: 'column', width: '100%', display: 'flex', justifyContent: 'center' }}>
          {/* <Box sx={{ mb: 2 }}>
          <Typography paragraph sx={instructionSx}>
            If you are new to Planet Atmos
          </Typography>
        </Box> */}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <LoginWithPassportButton
              onClick={setIsSubmittingTrue}
              onError={setIsSubmittingFalse}
              onSuccess={setIsSubmittingFalse}
              disabled={isSubmitting}
            />
          </Box>

          {/* <StyledDivider sx={{ mt: 4, mb: 4 }}>
          <Chip label='OR' size='small' />
        </StyledDivider>
        <Box sx={{ mb: 1 }}>
          <Typography paragraph sx={instructionSx}>
            If you already have an account
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <form onSubmit={handleSubmit(async formData => await onLoginUser(formData))}>
            <Stack spacing={2}>
              <Controller
                name='userKey'
                control={form.control}
                rules={{ required: 'EMAIL IS REQUIRED' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!formState.errors.userKey}
                    fullWidth
                    label='EMAIL ADDRESS'
                    placeholder='EXAMPLE@EMAIL.COM'
                    helperText={formState.errors.userKey?.message}
                  />
                )}
              />
              <Button
                type='submit'
                label='Sign in with Email'
                variant='contained'
                sx={{ width: 'auto' }}
                disabled={isSubmitting}
              />

              <Controller
                name='rememberMe'
                control={form.control}
                render={({ field }) => <FormControlLabel control={<Checkbox {...field} />} label='REMEMBER ME' />}
              />
              {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
            </Stack>
          </form>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography paragraph sx={instructionSx}>
            Email login will be going away soon. To maintain your Planet Atmos account, sign in with your email address,
            then sign into Passport using the “Connections” tab in the preferences section.
          </Typography>
        </Box> */}
        </Box>
      </Stack>
    )
  }
