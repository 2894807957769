import React, { FC } from 'react'

import {
  ReferenceManyField,
  useDataProvider,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
  useLogout,
} from 'react-admin'
import { ConfirmModal } from '@core/dash/resources/user/me/ConfirmModal'
import AppState from '@core/dash/app_state'
import ConnectButton from '@atmos/components/Web3ConnectButton'
import { Stack, Typography, Box, Chip } from '@mui/material'
import { useAccount } from 'wagmi'
// Enums
import { WalletActionTypes } from '@core/shared/types'
import { Spinner } from '@atmos/components/Spinner'
import { EthIcon } from '@atmos/icons/EthIcon'
import { colors } from '@core/dash/layout/CustomLayout/theme'
import { IconCircleWrapper } from '../connections/IconCircleWrapper'
import { useProviders } from '../connections/providers/useProviders'

interface IdentityActionsFieldProps {
  identityId: string
  displayedAddress: string
  deletedWalletAddress: string
}

// Disabled for now.
const IdentityActionsField: FC<IdentityActionsFieldProps> = ({
  identityId,
  displayedAddress,
  deletedWalletAddress,
}) => {
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()
  const record = useRecordContext()
  const logout = useLogout()

  const onDelete = async () => {
    try {
      await dataProvider.delete('Identity', { id: identityId, previousData: record })
      notify('Your wallet has been unlinked', { type: 'success' })

      // Remove deleted wallet from app state.
      const currentUser = AppState.getCurrentUser()
      const web3Accounts = currentUser.web3Accounts.filter(userAddress => userAddress !== deletedWalletAddress)
      AppState.setCurrentUser({ ...currentUser, web3Accounts })
      refresh()
    } catch (e) {
      console.error(e)
      notify('There was an error unlinking your wallet. Our team has been notified.', { type: 'error' })
    }
  }

  return (
    <ConfirmModal
      modalMessage={<Typography pb={6}>Are you sure you want to unlink your wallet "{displayedAddress}"?</Typography>}
      modalTitle='Confirm Unlink'
      onConfirm={onDelete}
      confirm='Unlink'
      cancel='Cancel'
    />
  )
}

export const WalletList: FC = ({passportSvg}) => {
  const { data, isLoading } = useListContext()
  const providers = useProviders()

  const immutableProvider = providers.find(provider => provider.key === 'immutable_passport')

  const { data: accountData } = useAccount()
  const selectedAccount = accountData?.address

  if (isLoading) {
    return <Spinner />
  }

  if (!data.length) {
    return null
  }

  return (
    <Stack spacing={4}>
      {data.map(item => {
        const connectedViaPassport = item.response.info?.provider == 'immutable_passport'
        const passportWallet = connectedViaPassport && item.response.info?.type == 'account'

        return (
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center'>
              {passportWallet ? immutableProvider.icon :  <IconCircleWrapper icon={<EthIcon />} /> }
              <Box>
                <Typography paragraph sx={{ mx: 3, my: 'auto' }}>
                  {item.displayUid}
                </Typography>
                {connectedViaPassport && (
                  <Typography variant="subtitle2" sx={{ mx: 3, fontSize: '0.5rem' }}>{passportWallet ? 'Passport Wallet' : 'Connected via Passport'}</Typography>
                )}
              </Box>
              {selectedAccount?.toUpperCase() === item.uid.toUpperCase() && (
                <Chip
                  label='ACTIVE'
                  sx={{
                    color: colors.green30,
                    backgroundColor: colors.green90,
                    borderRadius: 0,
                    display: { xxs: 'none', xs: 'inline-flex' },
                  }}
                />
              )}
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IdentityActionsField
                identityId={item.id}
                displayedAddress={item.displayUid}
                deletedWalletAddress={item.uid}
              />
            </Box>
          </Stack>
        )
      })}
    </Stack>
  )
}

export const WalletListView: FC = () => {
  return (
    <>
      <Typography variant='body2' mb={3}>
        Link one or more Ethereum wallets to view your Atmos collectibles.
      </Typography>
      <ReferenceManyField filter={{ provider: ['web3'] }} reference='Identity' target='userId'>
        <WalletList />
      </ReferenceManyField>
      <Box mt={5}>
        <ConnectButton variant='contained' walletActionType={WalletActionTypes.ADD_WALLET} />
      </Box>
    </>
  )
}
