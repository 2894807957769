import React, { FC } from 'react'
import { Button as MuiButton, ButtonProps as MuiButtonProps, Stack } from '@mui/material'
import { EthIcon } from '@atmos/icons/EthIcon'

export interface ButtonProps extends MuiButtonProps {
  label?: string | React.ReactNode
  showArrow?: boolean
  target?: string
  component?: any
  to?: string
}

export const Button: FC<ButtonProps> = ({
  label,
  showArrow = false,
  variant = 'contained' as const,
  component,
  to,
  ...rest
}) => {
  return (
    <MuiButton variant={variant} component={component} to={to} {...rest}>
      {label}
      {showArrow && <EthIcon fontSize='small' sx={{ ml: 1 }} />}
    </MuiButton>
  )
}
