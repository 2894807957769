import React, { FC, useState } from 'react'

import UsernameForm from '@atmos/components/UsernameInput'
import { Button as MuiButton, Stack, TextField, Select, MenuItem, InputLabel, FormControl, Box, Alert, Typography } from '@mui/material'
// Hooks
import { useDataProvider, useNotify, useRecordContext, useRefresh } from 'react-admin'
import { useForm, Controller } from 'react-hook-form'
// Utils
import apolloClient from 'clients/apollo'
import { gql } from '@apollo/client'
import { hydrateCurrentUser } from 'dash/utils/hydrateCurrentUser'
import AppState from 'dash/app_state'
import { languages } from '@atmos/components/constants/languages'
import { Button } from '@atmos/components/buttons/Button'

import {CachedAuthDataType} from 'dash/auth/AuthFlow'
// import { ManuallyVerifyPasswordlessAuthButton } from '@core/dash/auth/AuthFlow/ManuallyVerifyPasswordlessAuthButton'
// import { useAuthWebsocket } from '@core/dash/auth/AuthFlow/useAuthWebsocket'
import { AccessCodesField } from './AccessCodesField'

const VALIDATE_USERNAME_UNIQUENESS = gql`
  query validateUsernameUniqueness($username: String!) {
    validateUsernameUniqueness(username: $username)
  }
`

// const ConfirmEmailSocketAndManualTrigger = ({cachedAuthData}: {cachedAuthData: CachedAuthDataType}) => {
//   const notify = useNotify()
//   const refresh = useRefresh()

//   const onConfirmSuccess = async()=> {
//     await hydrateCurrentUser()
//     refresh()
//     notify('Profile updated', { type: 'success' })
//   }

//   useAuthWebsocket({
//     channelId: cachedAuthData?.channelId,
//     action: 'confirmed',
//     eventName: 'auth/confirmation',
//     onSuccess: onConfirmSuccess
//   })

//   return <ManuallyVerifyPasswordlessAuthButton cachedAuthData={cachedAuthData} onSuccess={onConfirmSuccess} />
// }

interface ProfileFormProps {}

const ProfileForm: FC<ProfileFormProps> = () => {
  const [cachedAuthData, setCachedAuthData] = React.useState<CachedAuthDataType>(null)
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const currentUser = AppState.getCurrentUser()
  const unconfirmed = !!currentUser.unconfirmedEmail

  const form = useForm({
    defaultValues: {
      email: record.email,
      username: record.username.split('#')[0],
      tag: record.username.split('#')[1],
      language: record.language || 'en',
    },
  })

  // Form values
  const { formState, handleSubmit } = form

  const validateUsernameUniqueness = async username => {
    try {
      const {
        data: { validateUsernameUniqueness: isUnique },
      } = await apolloClient.query({ query: VALIDATE_USERNAME_UNIQUENESS, variables: { username } })

      return isUnique == 'true'
    } catch (e) {
      console.error(e)

      return false
    }
  }

  const onSaveUser = async formData => {
    try {
      const { email, username, tag, language } = formData

      const fullUsername = `${username}#${tag}`
      const { data } = (await dataProvider.update('User', {
        id: record.id,
        previousData: record,
        data: { id: record.id, email, username: fullUsername, language },
      })) as any

      if (data.unconfirmedEmail) {
        setCachedAuthData({ event: 'confirm', channelId: data.authChannelId})
        notify(
          'Email confirmation has been sent to your new email address. You need to confirm your new email address to save it.',
          { type: 'success' }
        )
      } else {
        notify('Profile updated', { type: 'success' })
      }

      await hydrateCurrentUser()
      refresh()
      form.reset({ email, username, tag, language })
    } catch (error) {
      console.error(error)
      notify(`There was an error updating your profile: ${JSON.stringify(error?.message)}`, { type: 'error' })
      refresh()
    }
  }

  if (!record) return null

  return (
    <Box sx={{ width: { xxs: '100%', md: 600 } }}>
      <Stack>
        <UsernameForm
          form={form}
          defaultFullUsername={record.username}
          validateUniquenessFunc={validateUsernameUniqueness}
        />
        <Controller
          name='email'
          control={form.control}
          rules={{ required: 'Email is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!formState.errors.email}
              onChange={e => {
                field.onChange(e.target.value)
                form.trigger('email')
              }}
              label='EMAIL'
              placeholder='EXAMPLE@EMAIL.COM'
              helperText={formState.errors.email?.message}
            />
          )}
        />
        {/* {unconfirmed && (
          <Alert severity='info' sx={{ mb: 3 }}>
            <Stack spacing={1}>
              <Typography>PENDING EMAIL CHANGE</Typography>
              <Typography>
                YOU HAVE ATTEMPTED TO CHANGE YOUR EMAIL ADDRESS TO{' '}
                <Typography fontWeight='bold' textTransform='uppercase' display='inline-block'>
                  {currentUser.unconfirmedEmail}
                </Typography>
              </Typography>
              <Typography>
                WE CAN'T UPDATE YOUR EMAIL UNTIL YOU CONFIRM IT'S YOUR ACCOUNT BY CLICKING THE LINK IN THE CONFIRMATION
                EMAIL WE JUST SENT.
              </Typography>
              {cachedAuthData?.channelId && (<ConfirmEmailSocketAndManualTrigger cachedAuthData={cachedAuthData} />)}
            </Stack>
          </Alert>
        )} */}
        <Controller
          name='language'
          control={form.control}
          render={({ field }) => (
            <FormControl variant='outlined'>
              <InputLabel id='language-select-label'>PREFERRED LANGUAGE</InputLabel>
              <Select {...field} labelId='language-select-label'>
                {languages.map(language => (
                  <MenuItem value={language.code}>{language.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Button
          onClick={handleSubmit(async formData => await onSaveUser(formData))}
          label='Save'
          variant='contained'
          // Make button disabled if form is not dirty or invalid
          disabled={!formState.isDirty || !formState.isValid}
        />
      </Stack>
      <AccessCodesField source='accessCodes' />
    </Box>
  )
}

export default ProfileForm
