import React from 'react'
import { Link, LinkProps } from '@mui/material'

export const BLOCK_EXPLORER_NETWORK_URL = {
  ethereum: 'etherscan.io',
  imtbl_zkevm: 'explorer.immutable.com',
  polygon: 'polygonscan.com',
}

export const BLOCK_EXPLORER_CHAIN_PREFIX = {
  mainnet: '',
  goerli: 'goerli.',
  mumbai: 'mumbai.',
  sepolia: 'sepolia.',
  'imtbl-zkevm-mainnet': ''
}

export const OPENSEA_CHAIN_PATH = {
  ethereum: 'ethereum',
  polygon: 'matic',
}
export interface CryptoLinkProps extends LinkProps {
  transaction?: string
  block?: string
  address?: string
  chain?: string
  network?: string
  showFull?: boolean
  linkContent?: JSX.Element
  className?: string
  // href?: string;
}

interface cryptoHrefGeneratorParams {
  target: string
  network: string
  block?: string
  linkType: string
  chain: string
}

export const cryptoHrefGenerator: (params: cryptoHrefGeneratorParams) => string = ({
  target,
  network,
  chain,
  block,
  linkType,
}) => `https://${BLOCK_EXPLORER_CHAIN_PREFIX[network]}${BLOCK_EXPLORER_NETWORK_URL[chain]}/${linkType}/${target}`

export const CryptoLink: React.FC<CryptoLinkProps> = ({
  transaction,
  block,
  address,
  showFull = false,
  chain = 'ethereum',
  network = 'mainnet',
  linkContent,
  className,
  ...rest
}) => {
  const target = block || transaction || address
  const linkType = block ? 'block' : transaction ? 'tx' : 'address'

  if (!target) return null

  const truncatedTarget = target.length > 13 ? `${target.slice(0, 5)}...${target.slice(target.length - 4)}` : target
  const displayAs = showFull ? target : truncatedTarget

  const href = cryptoHrefGenerator({ target, block, linkType, network, chain })
  
  return (
    <Link {...rest} href={href} title={target} target='_blank' className={className}>
      {linkContent || displayAs}
    </Link>
  )
}
