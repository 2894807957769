import { gql } from '@apollo/client'

export const FETCH_CURRENT_USER = gql`
  query {
    CurrentUser {
      id
      name
      email
      roles
      username
      web3Accounts
      unconfirmedEmail
      type
      identities {
        provider
        uid
        id
        createdAt
      }
    }
  }
`

export const FETCH_ACCOUNT = gql`
  query Account($id: ID!) {
    Account(id: $id) {
      id
      name
      createdAt
    }
  }
`

export const FETCH_CURRENT_PAYMENT_ACCOUNT = gql`
  query CurrentPaymentAccount($provider: String!) {
    CurrentPaymentAccount(provider: $provider) {
      id
      provider
      providerKey
      payableType
      payableId
      createdAt
    }
  }
`

export const FETCH_SERVICE = gql`
  query Service($id: ID!) {
    Service(id: $id) {
      id
      name
    }
  }
`

export const FETCH_SERVICES = gql`
  query allServices($page: Int, $perPage: Int, $filter: ServiceFilter) {
    allServices(page: $page, perPage: $perPage, filter: $filter) {
      id
      name
      productConfig
    }
  }
`

export const FETCH_SUBSCRIPTIONS = gql`
  query allSubscriptions($page: Int, $perPage: Int, $filter: SubscriptionFilter) {
    allSubscriptions(page: $page, perPage: $perPage, filter: $filter) {
      id
      createdAt
      providerKey
      status
      accountableId
      service {
        id
      }
      createdBy {
        id
        name
      }
      paidBy {
        id
        name
      }
    }
  }
`

export const ACTIVE_MEMBERSHIPS_WITH_ACCOUNTS = gql`
  query allMemberships($page: Int, $perPage: Int, $filter: MembershipFilter) {
    allMemberships(page: $page, perPage: $perPage, filter: $filter) {
      account {
        id
        name
      }
      status
    }
  }
`

export const FIRE_AASM_TRANSITION = gql`
  mutation updateMembership($id: ID!, $aasmTransition: String) {
    updateMembership(id: $id, aasmTransition: $aasmTransition) {
      id
      status
      account {
        id
        name
      }
    }
  }
`

export const ACCOUNT_FORM_ENTRIES_COUNT = gql`
  query _allEntriesMeta($filter: EntryFilter) {
    _allEntriesMeta(filter: $filter) {
      count
    }
  }
`

export const ACCOUNT_FORMS_COUNT = gql`
  query _allFormsMeta($filter: FormFilter) {
    _allFormsMeta(filter: $filter) {
      count
    }
  }
`

export const ACCOUNT_MEMBERSHIPS_COUNT = gql`
  query _allMembershipsMeta($filter: MembershipFilter) {
    _allMembershipsMeta(filter: $filter) {
      count
    }
  }
`

export const ACCOUNT_TEAM_MEMBERS = gql`
  query allMemberships($page: Int, $perPage: Int, $filter: MembershipFilter) {
    items: allMemberships(page: $page, perPage: $perPage, filter: $filter) {
      account {
        id
      }
      user {
        id
        name
      }
    }
    total: _allMembershipsMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
    }
  }
`
