import React, { FC } from 'react'
import { Dialog, Typography, Stack, DialogContent, IconButton } from '@mui/material'
import { DeleteButton } from '@atmos/components/buttons/DeleteButton'
import { Button } from '@atmos/components/buttons/Button'
import { colors } from '@core/dash/layout/CustomLayout/theme'
import CloseIcon from '@mui/icons-material/Close'

interface ConfirmModalProps {
  onConfirm: () => void
  modalTitle?: string
  modalMessage: React.ReactNode
  confirm?: string
  cancel?: string
  isConfirmDisabled?: boolean
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  onConfirm,
  modalTitle = 'Confirm Action',
  modalMessage,
  confirm = 'Confirm',
  cancel = 'Cancel',
  isConfirmDisabled,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleModal = () => setIsOpen(!isOpen)
  return (
    <>
      <DeleteButton onClick={toggleModal} />
      <Dialog open={isOpen}>
        <DialogContent>
          <Stack spacing={3} padding={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant='h3' textTransform='uppercase'>
                {modalTitle}
              </Typography>
              <IconButton onClick={toggleModal}>
                <CloseIcon />
              </IconButton>
            </Stack>

            <Typography variant='h5'>{modalMessage}</Typography>
          </Stack>
          <Stack
            direction={{ xxs: 'column-reverse', md: 'row' }}
            sx={{
              position: 'sticky',
              bottom: 0,
              justifyContent: 'stretch',
              gap: '1px',
              backgroundColor: colors.grey90,
              zIndex: 100,
            }}
          >
            <Button variant='modalGhost' onClick={toggleModal} label={cancel} />
            <Button
              variant='modalDanger'
              disabled={isConfirmDisabled}
              onClick={() => {
                toggleModal()
                onConfirm()
              }}
              label={confirm}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}
