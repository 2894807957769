import { SvgIcon, SvgIconProps } from '@mui/material'

export const EthErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 20 20' {...props}>
      <path d='M3.61557 17.5269L2.73169 16.643L16.8738 2.50084L17.7577 3.38472L3.61557 17.5269Z' fill='#F1C21B' />
      <path
        d='M4.58969 10.6494L9.99915 0.551758L12.4605 5.14635L11.5371 6.06975L9.99915 3.19882L6.15826 10.3685L6.86271 10.7442L5.50038 12.1065L4.58969 10.6494Z'
        fill='#F1C21B'
      />
      <path
        d='M6.8602 14.2822L9.99915 19.3045L15.4086 10.6494L13.6939 7.44856L12.7705 8.37196L13.84 10.3685L9.99915 12.417L9.16849 11.9739L8.24458 12.8979L9.99915 13.8336L12.917 12.2774L9.99915 16.946L7.76732 13.3751L6.8602 14.2822Z'
        fill='#F1C21B'
      />
    </SvgIcon>
  )
}
