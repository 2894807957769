import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Analytics, AnalyticsEvents } from './analytics'

export const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    const cleanPath = window.location.href.replace('#', '')
    const url = cleanPath + location.search

    Analytics.publishEvent(AnalyticsEvents.PageView, { url, title: document.title })
  }, [location.pathname])
}
