import { Button } from '@atmos/components/buttons/Button'
import { Typography, Stack } from '@mui/material'
import { useEffect } from 'react'
import { Analytics, AnalyticsEvents } from '@core/dash/utils/analytics'

export const NotFound = () => {
  useEffect(() => {
    const path = '/dash' + document.location.hash
    Analytics.publishEvent(AnalyticsEvents.NotFound, { path })
  }, [])

  return (
    <div className='not-found-page-background'>
      <Stack
        spacing={3.5}
        sx={{
          margin: {
            xxs: '4rem 2rem',
            md: '4rem 4rem',
            lg: '4rem 10rem',
          },
          maxWidth: 500,
        }}
      >
        <Typography
          variant='h1'
          sx={{
            fontWeight: 800,
            fontSize: '40px',
            lineHeight: '50px',
            color: '#F7F3F2',
          }}
        >
          404
        </Typography>
        <Typography
          variant='h3'
          sx={{
            textTransform: 'uppercase',
            fontSize: '24px',
            fontWeight: '500',
            lineHeight: '32px',
            color: '#F7F3F2',
          }}
        >
          THIS PAGE WAS LOST IN TRANSMISSION, PILOT
        </Typography>
        <Typography variant='body2' sx={{ fontSize: '18px', pb: 1 }}>
          The page you are trying to access doesn’t exist or is not available right now.
        </Typography>
        <Button label='Back to home page' href='/' sx={{ width: '100%' }} />
      </Stack>
    </div>
  )
}
