import React, { FC } from 'react'
import { Box, SxProps } from '@mui/material'
import { colors } from '@core/dash/layout/CustomLayout/theme'

interface IconCircleWrapperProps {
  icon: React.ReactNode
  sx?: SxProps
}

export const IconCircleWrapper: FC<IconCircleWrapperProps> = ({ icon, sx }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 44,
      height: 44,
      borderRadius: '50%',
      border: `1px solid ${colors.grey10}`,
      flexShrink: 0,
      ...sx,
    }}
  >
    {icon}
  </Box>
)
