// *** IMPORTANT IMPLEMENTATION NOTE - READ BEFORE USE ***
// https://github.com/marmelab/react-admin/blob/872480a9a4a8491066eb8b1f0f651e48f47e9080/packages/ra-ui-materialui/src/form/TabbedForm.tsx#L209
// react-admin renders ALL tabs w/in TabbedForm and then uses css to hide tabs that aren't in focus.

import React, { FC } from 'react'

import { FormTab, TabbedForm } from 'react-admin'

export const CoreRATabbedForm: FC<{
  children: any
  saving?: any
  variant?: 'standard' | 'outlined' | 'filled'
}> = ({ children, ...rest }) => {
  // Disables children FormTabs when form is saving.
  // *** NOTE this only works when parent component (Create / Edit) have undoable toggled off ***
  return (
    <TabbedForm {...rest}>
      {children.map((child, i) => React.cloneElement(child, { disabled: rest.saving, key: i }))}
    </TabbedForm>
  )
}

export const CoreRAFormTab: FC<{ children: any; hidden?: boolean; label: string; path: string }> = ({
  children,
  ...tabProps
}) => {
  // TabbedForm passes a hidden props to it's children FormTabs.
  // This component passes that hidden props through to the FormTab's
  // children.
  return <FormTab {...tabProps}>{React.cloneElement(children, { hidden: tabProps.hidden })}</FormTab>
}
