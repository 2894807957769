import Me from './me'
import Assets from '@atmos/web3/react-admin/resources/user/assets'
import Home from './home'
// import Releases from '@atmos/react-admin/resources/user/releases'

export default class User {
  static Asset = Assets
  static Me = Me
  static Home = Home
  // static Release = Releases
}
