import PubSub from 'pubsub-js'
import { Analytics, AnalyticsEvents } from './analytics'

declare global {
  interface Window {
    plausible: any
  }
}

export enum PlausibleEvents {
  UserAuthStart = 'plausible.user/auth3/auth-start',
  UserRegister = 'plausible.user/auth3/register',
  UserLogin = 'plausible.user/auth3/login',

  AssetPurchase1 = 'plausible.asset/purchase/1-start',
  AssetPurchase2 = 'plausible.asset/purchase/2-checkout-confirmed',
  AssetPurchase3 = 'plausible.asset/purchase/3-txn-pending',
  AssetPurchase4 = 'plausible.asset/purchase/4-txn-confirmed',

  AssetTransfer1 = 'plausible.asset/transfer/1-start',
  AssetTransfer2 = 'plausible.asset/transfer/2-transfer-confirmed',
  AssetTransfer3 = 'plausible.asset/transfer/3-txn-pending',
  AssetTransfer4 = 'plausible.asset/transfer/4-txn-confirmed',

  UnlockableContentOpen = 'plausible.unlockable-content/open',

  UserWalletLink = 'plausible.user/connections/web3-wallet-link',

  PageView = 'plausible.pageview',
  NotFound = 'plausible.portal/404', // "_error.html.slim" page uses hardcoded "portal/404" value.
}

const plausibleAnalyticsMap = {
  [AnalyticsEvents.UserAuthStart]: PlausibleEvents.UserAuthStart,
  [AnalyticsEvents.UserRegister]: PlausibleEvents.UserRegister,
  [AnalyticsEvents.UserLogin]: PlausibleEvents.UserLogin,
  [AnalyticsEvents.AssetPurchase1]: PlausibleEvents.AssetPurchase1,
  [AnalyticsEvents.AssetPurchase2]: PlausibleEvents.AssetPurchase2,
  [AnalyticsEvents.AssetPurchase3]: PlausibleEvents.AssetPurchase3,
  [AnalyticsEvents.AssetPurchase4]: PlausibleEvents.AssetPurchase4,
  [AnalyticsEvents.AssetTransfer1]: PlausibleEvents.AssetTransfer1,
  [AnalyticsEvents.AssetTransfer2]: PlausibleEvents.AssetTransfer2,
  [AnalyticsEvents.AssetTransfer3]: PlausibleEvents.AssetTransfer3,
  [AnalyticsEvents.AssetTransfer4]: PlausibleEvents.AssetTransfer4,
  [AnalyticsEvents.UnlockableContentOpen]: PlausibleEvents.UnlockableContentOpen,
  [AnalyticsEvents.UserWalletLink]: PlausibleEvents.UserWalletLink,
  [AnalyticsEvents.PageView]: PlausibleEvents.PageView,
  [AnalyticsEvents.NotFound]: PlausibleEvents.NotFound,
}

class Plausible {
  private static instance: Plausible

  private constructor() {
    Analytics.subscribeToEvent((msg, data) => this.handleEvent(msg, data))
    PubSub.subscribe('plausible', (msg, data) => this.handleEvent(msg, data))
  }

  static getInstance(): Plausible {
    if (!Plausible.instance) {
      Plausible.instance = new Plausible()
    }
    return Plausible.instance
  }

  private handleEvent(event: string, data: any) {
    const plausibleEvent = (plausibleAnalyticsMap[event as PlausibleEvents] ?? event).replace('plausible.', '')
    const eventData = plausibleEvent === 'pageview' ? { u: data.url } : { props: data }
    window.plausible(plausibleEvent, eventData)
  }

  static publishEvent(event: PlausibleEvents, data?: any) {
    PubSub.publish('plausible', event, data)
  }
}

export default Plausible.getInstance()
