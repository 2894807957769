import React, { useState, useRef, FC } from 'react'
import { Popover as MuiPopover, Box } from '@mui/material'

interface PopoverProps {
  anchor: React.ReactElement
  popoverContent: React.ReactNode
}

export const Popover: FC<PopoverProps> = ({ anchor, popoverContent }) => {
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null)

  const popoverEnter = () => {
    setOpenedPopover(true)
  }

  const popoverLeave = () => {
    setOpenedPopover(false)
  }
  return (
    <>
      {React.cloneElement(anchor, {
        ref: popoverAnchor,
        onMouseEnter: popoverEnter,
        onMouseLeave: popoverLeave,
        'aria-haspopup': true,
        onClick: event => {
          event.stopPropagation()
          setOpenedPopover(!openedPopover)
        },
      })}

      <MuiPopover
        open={openedPopover}
        sx={{ pointerEvents: 'none' }}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
          onClick: event => {
            event.stopPropagation()
            popoverLeave()
          },
        }}
        disableRestoreFocus
      >
        <Box sx={{ pointerEvents: 'auto', padding: 1, width: 300 }}>{popoverContent}</Box>
      </MuiPopover>
    </>
  )
}
