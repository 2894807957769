import React from 'react'
import { useListContext } from 'react-admin'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

export const SortListDropdown = () => {
  const { sort, setSort } = useListContext()

  const handleChange = event => {
    const selectedSort = event.target.value
    const [field, order] = selectedSort.split(':')
    setSort({ field, order })
  }

  const selectValue = `${sort.field}:${sort.order}`

  return (
    <FormControl variant='outlined' size='small' sx={{ minHeight: 'unset' }}>
      <InputLabel htmlFor='sort-select'>SORT BY</InputLabel>
      <Select value={selectValue} onChange={handleChange} sx={{ minWidth: { xs: 'unset', sm: 345 } }}>
        <MenuItem value='tokenId:ASC'>TOKEN ID: ASCENDING</MenuItem>
        <MenuItem value='createdAt:ASC'>DATE CREATED: OLDEST</MenuItem>
        <MenuItem value='createdAt:DESC'>DATE CREATED: NEWEST</MenuItem>
        <MenuItem value='transfers.createdAt:DESC'>MOST RECENTLY TRANSACTED</MenuItem>
      </Select>
    </FormControl>
  )
}
