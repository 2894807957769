import React from 'react'
import { gql } from '@apollo/client'
import apolloClient from 'clients/apollo'

// Get collections list hook
export const useGetCollectionsList = () => {
  const [collections, setCollections] = React.useState<any>([])

  const GET_COLLECTIONS_LIST = gql`
    query allCollections($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: CollectionFilter) {
      items: allCollections(
        page: $page
        perPage: $perPage
        sortField: $sortField
        sortOrder: $sortOrder
        filter: $filter
      ) {
        id
        name
        imageUrl
        openseaKey
        description
      }
      total: _allCollectionsMeta(page: $page, perPage: $perPage, filter: $filter) {
        count
      }
    }
  `
  const fetchCollections = async () => {
    try {
      const {
        data: { items },
      } = await apolloClient.query({
        query: GET_COLLECTIONS_LIST,
        variables: {
          pagination: { page: 0, perPage: 10 },
          filter: {},
          sort: { field: 'createdAt', order: 'ASC' },
        },
      })

      setCollections(items)
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    fetchCollections()
  }, [])

  return collections
}
