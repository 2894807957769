import { Typography, Card, CardMedia, Button, useMediaQuery, Theme } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { styled } from '@mui/system'
import { colors, devices } from '@core/dash/layout/CustomLayout/theme'
import AppState from '@core/dash/app_state'

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: colors.grey80,
  display: 'flex',
  bottom: 0,
  right: 0,
  height: 200,
  zIndex: 10,
  position: 'absolute',
  padding: 16,
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  border: `2px solid ${colors.grey80}`,
  [theme.breakpoints.down('md')]: {
    height: 150,
  },
}))

interface StyledCardProps {
  withHover?: boolean
}

const StyledCard = styled(Card)<StyledCardProps>(({ withHover }) => ({
  position: 'relative',
  cursor: withHover ? 'pointer' : 'default',
  '& img': {
    transition: 'transform 0.5s ease',
  },
  '&:hover': {
    '& img': {
      transform: withHover ? 'scale(1.05)' : 'scale(1)',
    },
  },
}))

export const UserCollection = ({ name, imgUrl }) => {
  const ownerIds = AppState.getCurrentUser()?.web3Accounts

  return (
    <StyledCard sx={{ height: '600px', width: '100%', maxWidth: devices.xxl }}>
      <CardMedia component='img' height='600px' image={imgUrl} alt={name} />
      <Link
        to={{
          pathname: '/user/asset',
          search: `filter=${JSON.stringify({
            ownerIds,
            ownershipEnforced: true,
          })}&order=ASC&page=1&perPage=12&sort=tokenId`,
        }}
      >
        <StyledButton sx={{ width: { xxs: '100%', sm: 360, lg: 500 } }} variant='default'>
          <Typography variant='h5' alignSelf='flex-start'>
            {name}
          </Typography>
          <ArrowForwardIcon sx={{ alignSelf: 'flex-end' }} />
        </StyledButton>
      </Link>
    </StyledCard>
  )
}

export const SecondaryCollection = ({ name, imgUrl, id }) => {
  return (
    <Link
      to={{
        pathname: '/user/asset',
        search: id ? `filter=${JSON.stringify({ collectionId: id })}&order=ASC&page=1&perPage=12&sort=tokenId` : '',
      }}
    >
      <StyledCard sx={{ height: 'fit-content' }} withHover>
        <CardMedia component='img' sx={{ height: { xxs: '500px', md: 'fit-content' } }} image={imgUrl} alt={name} />
        <StyledButton sx={{ width: { xxs: '100%', sm: 360 }, height: 150 }} variant='default'>
          <Typography variant='h5' alignSelf='flex-start'>
            {name}
          </Typography>
          <ArrowForwardIcon sx={{ alignSelf: 'flex-end' }} />
        </StyledButton>
      </StyledCard>
    </Link>
  )
}
