import React from 'react'
import { Typography, Container, Box, Stack } from '@mui/material'
import { UserCollection, SecondaryCollection } from './CollectionCards'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2 (recommmended)
import { useGetCollectionsList } from '@atmos/web3/react-admin/resources/user/assets/list/useGetCollectionsList'
import { Spinner } from '@atmos/components/Spinner'
import { cloneDeep } from 'lodash'

// After adding collections in here make sure to add them in the Admin panel as well with the same open sea key.
// Collections with no img url will not be displayed on the home page, however collections filter will be present.
export const COLLECTIONS = {
  collectibles: {
    'planet-atmos-mkiv-exo-genesis': {
      name: 'MKIV EXO: GENESIS',
      imgUrl: '/images/mkiv-exo-genesis.webp',
    }, 
    'atmos-mkiv-proto-helm': {
      name: 'MKIV PROTO HELM',
      imgUrl: '/images/mkiv-proto-helm-collection.webp',
    },
    'atmosxyz-mkiv-datacard': {
      name: 'MKIV DATACARD',
      // imgUrl: '/images/mkiv-datacard-collection-upgrade.webp',
    },
    'atmos-jetpack': {
      name: 'ATMOS JETPACK',
      imgUrl: '',
    },
  },
  exordium: {
    'atmosxyz-exordium-03': {
      name: 'CHAPTER 03',
      imgUrl: '/images/chapter-03-collection.webp',
    },
    'atmosxyz-exordium-02': {
      name: 'CHAPTER 02',
      imgUrl: '/images/chapter-02-collection.webp',
    },
    'atmosxyz-exordium-01': {
      name: 'CHAPTER 01',
      imgUrl: '/images/chapter-01-collection.webp',
    },
  },
}

export const Show = () => {
  const collections = useGetCollectionsList()

  if (!collections.length) {
    return <Spinner />
  }

  const exordiumKeys = Object.keys(COLLECTIONS.exordium)
  const collectiblesKeys = Object.keys(COLLECTIONS.collectibles)
  // This is a temporary solution to add images and names to collections and group them by category.
  // Those fields should be added in the Admin panel and this code should be removed.
  const accumulator = cloneDeep(COLLECTIONS)
  const displayCollections = collections.reduce((acc, collection) => {
    if (exordiumKeys.includes(collection.openseaKey)) {
      acc.exordium[collection.openseaKey] = {
        ...acc.exordium[collection.openseaKey],
        id: collection.id,
      }
    } else if (collectiblesKeys.includes(collection.openseaKey)) {
      acc.collectibles[collection.openseaKey] = {
        ...acc.collectibles[collection.openseaKey],
        id: collection.id,
      }
    } else {
      return acc
    }
    return acc
  }, accumulator)

  return (
    <>
      <Container maxWidth='xxl' sx={{ px: 6, position: 'relative' }}>
        <Typography variant='h1' sx={{ position: 'absolute', zIndex: 10, top: '50px' }}>
          HOME
        </Typography>
      </Container>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <UserCollection name='MY COLLECTION' imgUrl='/images/Hero.webp' />
      </Box>
      <Container maxWidth='xxl' sx={{ pt: 8 }}>
        <Typography variant='h2'>COLLECTIBLES</Typography>
        <Grid container columns={2} spacing={4} mt={1} justifyContent='space-between'>
          {collectiblesKeys.map(
            (key, index) =>
              displayCollections.collectibles[key].imgUrl && (
                <Grid xs={2} lg={1} key={index}>
                  <SecondaryCollection
                    name={displayCollections.collectibles[key].name}
                    imgUrl={displayCollections.collectibles[key].imgUrl}
                    id={displayCollections.collectibles[key].id}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Container>
      <Container maxWidth='xxl' sx={{ py: 8 }}>
        <Typography variant='h2'>EXORDIUM</Typography>
        <Grid container columns={2} spacing={4} mt={1} justifyContent='space-between'>
          {exordiumKeys.map(
            (key, index) =>
              displayCollections.exordium[key].imgUrl && (
                <Grid xs={2} lg={1} key={index}>
                  <SecondaryCollection
                    name={displayCollections.exordium[key].name}
                    imgUrl={displayCollections.exordium[key].imgUrl}
                    id={displayCollections.exordium[key].id}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Container>
    </>
  )
}
