import React from 'react'
import { AppBar, Box, Divider, IconButton, Toolbar, Drawer, Stack, Tabs, Tab, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { Link, useLocation } from 'react-router-dom'
import AppState from '@core/dash/app_state'
import { Logo } from 'shared/atoms'

import { Menu } from 'react-admin'

import { WalletStatus } from './WalletStatus'
import { UserMenu } from './UserMenu'

const drawerWidth = '100vw'
const tooltipProps = { open: false, children: null, title: null }

export const CustomAppBar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const isAdmin = JSON.parse(localStorage.getItem('currentUser'))['roles'].includes('admin')
  const ownerIds = AppState.getCurrentUser()?.web3Accounts
  const { pathname } = useLocation()
  // Hiding nav bar on unlockable content pages.
  const hideNaveBar = pathname.includes('/asset/') && pathname.includes('/content/')

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const commonMenuItemProps = {
    onClick: handleDrawerToggle,
    tooltipProps,
  }

  const drawer = (
    <Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center' p={2}>
        <Logo />
        <IconButton onClick={handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Menu sx={{ '&.RaMenu-closed, &.RaMenu-open': { width: drawerWidth } }}>
        <Menu.Item primaryText='Home' to='/' {...commonMenuItemProps} />
        <Menu.Item primaryText='Collections' to={{ pathname: '/user/asset' }} {...commonMenuItemProps} />
      </Menu>
    </Stack>
  )

  if (hideNaveBar) return null

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component='nav' position='static'>
        <Toolbar sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }} variant='dense'>
          <IconButton aria-label='open drawer' onClick={handleDrawerToggle} sx={{ p: '14px', display: { sm: 'none' } }}>
            <Logo />
          </IconButton>
          <IconButton sx={{ display: { xxs: 'none', sm: 'block' }, pb: 0 }} onClick={() => (location.href = '/')}>
            <Logo styleDesktop={{ height: 30 }} />
          </IconButton>
          <Tabs value={pathname} sx={{ display: { xxs: 'none', sm: 'flex' }, color: 'text.secondary' }}>
            <Tab label='Home' to='/' value='/' component={Link} />
            <Tab
              label='Collections'
              to={{
                pathname: '/user/asset',
                search: `filter=${JSON.stringify({
                  ownerIds,
                  ownershipEnforced: true,
                })}&order=ASC&page=1&perPage=12&sort=tokenId`,
              }}
              value='/user/asset'
              component={Link}
            />
          </Tabs>
          <Stack direction='row' height='100%'>
            <Tooltip title='Admin Panel'>
              <IconButton onClick={() => (location.href = '/admin')} sx={{ display: isAdmin ? 'flex' : 'none' }}>
                <AdminPanelSettingsIcon viewBox='3 3 18 18' />
              </IconButton>
            </Tooltip>
            <UserMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xxs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  )
}
