import React, { FC } from 'react'
import { Stack, Typography, IconButton } from '@mui/material'
import { Contract } from '../../shared/types'
import { Button } from '@atmos/components/buttons/Button'
import { cryptoHrefGenerator } from '@components/Crypto/Link'
import CloseIcon from '@mui/icons-material/Close'

interface CompleteTransactionProps {
  txHash: string
  handleCloseModal: () => void
  handleCloseTransferModal: () => void
  selectedAsset: { network: string; chain: string }
}

export const CompleteTransaction: FC<CompleteTransactionProps> = ({
  txHash,
  handleCloseModal,
  handleCloseTransferModal,
  selectedAsset,
}) => {
  const closeAllModals = () => {
    handleCloseModal()
    handleCloseTransferModal()
  }
  return (
    <Stack spacing={2} alignItems='center' marginY={2}>
      <Typography variant='h2' mb={2}>
        TRANSACTION COMPLETE
      </Typography>
      <IconButton onClick={handleCloseTransferModal} sx={{ position: 'absolute', top: 0, right: 8 }}>
        <CloseIcon />
      </IconButton>

      <Button
        href={cryptoHrefGenerator({
          linkType: 'tx',
          network: selectedAsset.network,
          chain: selectedAsset.chain,
          target: txHash,
        })}
        target='_blank'
        label='View on Block Explorer'
        variant='text'
      />
      <Button variant='text' label='Go to assets' onClick={closeAllModals} />
    </Stack>
  )
}
