import React, { FC } from 'react'
import { useAuthState } from 'react-admin'
import { Navigate } from 'react-router-dom'
import { AuthFlow } from './AuthFlow'
import AppState from '../app_state'

export const DashAuthView = () => {
  const { isLoading: authStateLoading, authenticated } = useAuthState()

  // https://marmelab.com/react-admin/useAuthState.html
  // authenticated returns true when loading.
  // isLoading is only true on first mount.
  // this leaves a possible race condition. so also
  // check the auth cookie to see if we are authenticated

  if (authStateLoading) return null
  else if (authenticated && AppState.authCookiePresent()) return <Navigate to='/' replace />

  return <AuthFlow />
}
