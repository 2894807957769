import * as React from 'react'
import { FC } from 'react'
import { CardActions, Typography, Box } from '@mui/material'

interface CustomLayoutWithHeaderProps {
  // basePath?: string
  children?: any
  header: JSX.Element | string
  // record?: { [key: string]: any }
  redirect?: string | boolean
  variant?: 'outlined'
}

export const CustomLayoutWithHeader: FC<CustomLayoutWithHeaderProps> = (props: CustomLayoutWithHeaderProps) => {
  const { children, header, ...rest } = props

  return (
    <>
      {/* {hasCreate || hasEdit && ( */}
      <CardActions
        style={{
          zIndex: 2,
          display: 'inline-block',
          float: 'right',
        }}
      >
        {/* {hasEdit && <EditButton basePath={basePath} record={record} />} */}
        {/* Add your custom actions
        <Button color="primary" onClick={() => console.log('custom action')}>Custom Action</Button> */}
      </CardActions>
      {/* )} */}
      <Typography variant='h2' sx={{ mt: 2, ml: 2 }}>
        {header}
      </Typography>
      {React.cloneElement(React.Children.only(children), rest)}
    </>
  )
}
