import React, { FC, useEffect } from 'react'
import { Layout, Sidebar } from 'react-admin'
import { Box, Link } from '@mui/material'
import { CustomAppBar } from './AppBar'
import { usePageTracking } from 'dash/utils/usePageTracking'

// Sidebar prop doesn't accept null, so we need to create a custom placeholder component.
const MySidebar = props => <Sidebar sx={{ display: 'none' }} open={false} {...props} />

const CustomLayout: FC = (props): JSX.Element => {
  useEffect(() => {
    // Add a background gradient to RaLayout-contentWithSidebar element.
    const appFrame = document.querySelector('.RaLayout-contentWithSidebar')
    if (appFrame) {
      const span = document.createElement('span')
      span.id = 'background-gradient'
      appFrame.appendChild(span)
    }
  }, [])

  usePageTracking()

  const links = [
    {
      text: 'Privacy Policy',
      url: 'https://planetatmos.com/privacy-policy/',
      newTab: true,
    },
    {
      text: 'Terms of Use',
      url: 'https://planetatmos.com/terms-of-use/',
      newTab: true,
    },
    {
      text: 'Cookie Policy',
      url: 'https://planetatmos.com/cookie-policy/',
      newTab: true,
    },
  ]

  return (
    <>
      <Layout {...props} appBar={CustomAppBar} id={'appLayout'} sidebar={MySidebar} />
      <Box component='footer' sx={{ gap: 1, p: 1, display: 'flex', justifyContent: 'center' }}>
        {links.map(({ text, url, newTab }) => (
          <Link href={url} key={text} target={newTab ? '_blank' : '_self'} variant='body2'>
            {text}
          </Link>
        ))}
        <Link
          href='#'
          onClick={e => {
            e.preventDefault()
          }}
          className='termly-display-preferences'
          variant='body2'
        >
          Consent Preferences
        </Link>
      </Box>
    </>
  )
}

export default CustomLayout
