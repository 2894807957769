import React, { useState, useCallback, memo } from 'react'
// Components
import { List, useListContext, Pagination as RaPagination } from 'react-admin'
import { Box, Typography, IconButton, Stack } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2 (recommmended)
import { UserAssetCard } from '@atmos/web3/react-admin/resources/user/assets/UserAssetCard'
import { Button } from '@atmos/components/buttons/Button'
import { Spinner } from '@atmos/components/Spinner'
import { AssetViewModal } from '../AssetViewModal'
import { AssetFilterSidebar } from './FilterListSidebar'
import ConnectButton from '@atmos/components/Web3ConnectButton'
// Utils
import { WalletActionTypes } from '@core/shared/types'
import { COLLECTIONS } from '@core/dash/resources/user/home/show'
import { colors } from '@core/dash/layout/CustomLayout/theme'
import { getEnvironment } from '@atmos/components/utils'
import { SortListDropdown } from './SortListDropdown'
import { useGetCollectionsList } from './useGetCollectionsList'

const env = getEnvironment()

const Pagination = props => {
  const { isFetching, isLoading, data: assets } = useListContext()

  // Hide pagination when loading or no data.
  if (isFetching || isLoading || !assets.length) return null
  return (
    <RaPagination
      labelRowsPerPage='Assets per page'
      rowsPerPageOptions={[12, 24, 48]}
      {...props}
      sx={{
        overflow: 'hidden',
        '& .MuiToolbar-root': {
          backgroundColor: colors.grey100,
          borderTop: `1px solid ${colors.grey70}`,
        },
      }}
    />
  )
}

function getCategoryNameAndDescription(collections, filters) {
  if (!collections.length) return { name: '', description: '' }
  const { ownershipEnforced, collectionId } = filters
  switch (true) {
    case ownershipEnforced && !collectionId: {
      return { name: 'MY COLLECTION', description: '' }
    }
    case !!collectionId: {
      const { openseaKey, description } = collections.find(collection => collection.id === collectionId)

      // Temporary fix until we come up with display collection names.
      for (const collectionCategory in COLLECTIONS) {
        const name = COLLECTIONS[collectionCategory][openseaKey]?.name
        if (name) {
          return { name, description }
        }
      }
    }
    default: {
      return { name: 'COLLECTIONS', description: '' }
    }
  }
}

const NoOwnedAssets = () => {
  const { setFilters, displayedFilters } = useListContext()
  return (
    <Stack maxWidth={700} spacing={2}>
      <Typography variant='h3'>NO ASSETS TO SHOW</Typography>
      <Typography variant='body2'>
        There are no Atmos assets in your wallet, you can try connecting a different wallet, or view collections to find
        assets to purchase
      </Typography>
      <Box pt={5}>
        <Button label='VIEW COLLECTIONS' variant='contained' onClick={() => setFilters({}, displayedFilters)} />
      </Box>
    </Stack>
  )
}

const NoWalletConnected = () => (
  <Stack maxWidth={700} spacing={2}>
    <Typography variant='h3'>NO WALLET LINKED</Typography>
    <Typography variant='body2'>
      Unable to view your assets because you have no wallet linked to your account
    </Typography>
    <Box pt={5}>
      <ConnectButton variant='contained' showArrow walletActionType={WalletActionTypes.ADD_WALLET} />
    </Box>
  </Stack>
)

const ListContext = () => {
  const [selectedAsset, setSelectedAsset] = useState(null)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const toggleFilter = (event: React.KeyboardEvent | React.MouseEvent) => {
    // Prevents the drawer from closing when the user clicks on the filters.
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsFilterOpen(!isFilterOpen)
  }
  const { data: assets, filterValues } = useListContext()
  const collections = useGetCollectionsList()

  const collectionCategory = React.useMemo(
    () => getCategoryNameAndDescription(collections, filterValues),
    [collections, filterValues]
  )
  // This function had to be memoized to prevent unnecessary re-renders of the asset grid on every asset selection.
  const handleSelectAsset = useCallback(
    assetId => {
      const asset = assets.find(asset => asset.id === assetId)
      setSelectedAsset(asset)
    },
    [assets]
  )

  const handleCloseModal = useCallback(() => setSelectedAsset(null), [])
  const hasAssets = assets?.length > 0

  return (
    <>
      <Stack direction='row' height='100%' spacing={2}>
        <AssetFilterSidebar collections={collections} isFilterOpen={isFilterOpen} toggleFilter={toggleFilter} />

        <Box width='100%' px={{ xxs: '8px', sm: '40px' }} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h1' mt='50px' mb='24px'>
            {collectionCategory.name}
          </Typography>
          <Typography variant='body2' mb={5}>
            {collectionCategory.description}
          </Typography>
          <Stack
            direction='row'
            justifyContent={{ xxs: 'space-between', md: 'flex-end' }}
            flexWrap='wrap'
            alignItems='end'
            mb={2}
            display={hasAssets ? 'flex' : 'none'}
          >
            <IconButton sx={{ display: { xxs: 'flex', md: 'none' }, height: 'fit-content' }}>
              <FilterAltOutlinedIcon onClick={toggleFilter} />
            </IconButton>
            <SortListDropdown />
          </Stack>
          {filterValues.ownershipEnforced && !filterValues.ownerIds?.length && <NoWalletConnected />}
          <AssetGrid assets={assets} handleSelectAsset={handleSelectAsset} />
        </Box>
      </Stack>
      {!!selectedAsset && (
        <AssetViewModal
          selectedAsset={selectedAsset}
          handleCloseModal={handleCloseModal}
          assets={assets}
          setSelectedAsset={setSelectedAsset}
        />
      )}
    </>
  )
}

const AssetGrid = memo(({ assets, handleSelectAsset }) => {
  const { isLoading, isFetching, filterValues } = useListContext()

  if (isLoading || isFetching) {
    return <Spinner />
  }

  // Need to keep NoWalletConnected outside of AssetGrid component,
  // because it gets remounted when clicking away from the window and wallet connect modal gets closed.
  if (filterValues.ownershipEnforced && !filterValues.ownerIds?.length) {
    return null
  }

  if (assets.length === 0) {
    return <NoOwnedAssets />
  }
  return (
    <Grid container columns={12} spacing={2}>
      {assets.map((asset, index) => (
        <Grid xxs={12} sm={6} md={4} xl={3} xxl={2} key={index}>
          <UserAssetCard asset={asset} handleSelectAsset={handleSelectAsset} />
        </Grid>
      ))}
    </Grid>
  )
})

export const AssetList = () => {
  return (
    <List
      sort={{ field: 'tokenId', order: 'ASC' }}
      perPage={12}
      empty={false}
      exporter={false}
      component='div'
      actions={false}
      pagination={<Pagination />}
      // filterDefaultValues={{ ownerIds: AppState.getCurrentUser()?.web3Accounts }}
      sx={{
        minHeight: 'calc(100vh - 49px)', // 49px is the height of the navbar
        '.RaList-main': {
          position: 'relative',
          '.RaList-content': {
            height: '100%',
          },
        },
        '.MuiPaper-root': {
          flexShrink: 0,
          backgroundColor: colors.grey100,
        },
      }}
    >
      <ListContext />
    </List>
  )
}
