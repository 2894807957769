import { FC } from 'react'

import { Edit, FormTab, TabbedForm, TabbedFormTabs } from 'react-admin'
import { Box, Typography } from '@mui/material'
import './avatar.scss'
import { PageTitle } from 'ra/shared'
import { Connections } from './connections'
import { Wallets } from './wallets'
import AppState from 'dash/app_state'
import ProfileForm from '@atmos/react-admin/resources/user/me/ProfileForm'
import { colors } from '@core/dash/layout/CustomLayout/theme'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box)(() => ({
  padding: '1rem',
  width: '100%',
}))

export const MeEdit: FC = () => {
  if (!AppState.getCurrentUser()) return null

  const onSubmitTransform = (data: { password: string }) => {
    if (data.password == '' || data.password == undefined || data.password == null) delete data.password
    return data
  }

  // const passportSvg = <ImmutableSvg />

  return (
    <Edit
      redirect={false}
      title={<PageTitle resource='CurrentUser' source='name' />}
      resource='CurrentUser'
      id={AppState.getCurrentUser().id}
      transform={onSubmitTransform}
      // These styles are necessary to make the preferences page fill the entire #main-content container.
      sx={{
        m: 0,
        height: '100%',
        '& .RaEdit-main': { m: 0, height: '100%' },
        '& .RaEdit-card': {
          height: '100%',
          backgroundColor: colors.grey100,
          pt: { xxs: 3, lg: '4rem' },
          pl: { xxs: 1, md: 2, lg: '6rem' },
          boxSizing: 'border-box',
        },
      }}
    >
      <Typography variant='h1' mb={3} sx={{ fontSize: { xxs: 30, md: 40 }, ml: 3 }}>
        PREFERENCES
      </Typography>
      <TabbedForm
        tabs={
          <TabbedFormTabs
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
            sx={{ gridTemplateColumns: '30px auto 30px', display: 'grid' }}
          />
        }
        toolbar={null}
        sx={{
          display: 'inline-block',
          width: { xxs: '100%', md: 680 },
          '& .MuiDivider-root': {
            borderStyle: 'solid',
          },
        }}
      >
        <FormTab label='Profile' path='profile'>
          <StyledBox>
            <ProfileForm />
          </StyledBox>
        </FormTab>
        <FormTab label='Wallets' path='wallets'>
          <StyledBox>
            <Wallets.List />
          </StyledBox>
        </FormTab>
        {/* <FormTab label='Referrals' path='referrals'>
          <StyledBox>
            <Referral.List />
          </StyledBox>
        </FormTab> */}
        <FormTab label='Connections' path='connections'>
          <StyledBox>
            <Connections.List />
          </StyledBox>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
