import { Account, User } from 'shared/types'
import { generateFetchRequest } from 'clients/fetch'
import AppState from 'dash/app_state'
import { hydrateCurrentUser } from 'dash/utils/hydrateCurrentUser'
import { usePassport, usePassportLogout } from '@core/immutable/passport/usePassport'

type LoginCredentials = { email: string; password: string; rememberMe: boolean }
type LoginResponse = { success?: boolean; resource?: User }
// type Identity = { id: string; fullName?: string; avatart?: string; account: Account; user: User }

export const useAuthProvider = () => {
  const { passportInstance, passportProvider, isLoading } = usePassport()
  const { logout: passportLogout } = usePassportLogout()

  return {
    isLoading: isLoading,
    provider: isLoading ? null : {
      handleCallback: async () => {
        const passportLoggedOut = window.location.href.includes('passport_logged_out=true')
        const passportLoggedIn = window.location.href.includes('passport_logged_in=true')

        try {
          if (passportLoggedOut) await passportInstance.logoutSilentCallback('https://atmos.test/dash#/user/me/connections');
          else if (passportLoggedIn) await passportInstance.loginCallback()
        } catch(e) {
          console.error('Error handling passport callback', e)
        }

        return { redirectTo: false }
      },
      login: async ({ email, password, rememberMe: remember_me }: LoginCredentials): Promise<void | User> => {
        const request = generateFetchRequest({
          body: JSON.stringify({
            user: { email, password, remember_me },
          }),
          method: 'POST',
          path: 'users/sign_in',
        })
    
        return fetch(request).then(async response => {
          const data = await response.json()
          if (response.status != 200) {
            throw new Error(data.message)
          }
    
          const { resource: user }: LoginResponse = data
    
          await AppState.setIdentity({ user })
        
          return user
        })
      },
      checkError: (error: { networkError: { statusCode: number } }): Promise<void> => {
        // called when dataProvider returns an error
        // rejected promise automatically calls authProvider.logout()
    
        if (error && error.networkError && [401, 403].includes(error.networkError.statusCode)) {
          return Promise.reject()
        }
        return Promise.resolve()
      },
      checkAuth: async (): Promise<void> => {
        // AUTHENTICATION
        // Checking Credentials During Navigation
        // called each time the user navigates to a list, edit, create or show page
        // rejected promise automatically calls authProvider.logout()

        if (AppState.authCookiePresent()) {
          if (AppState.getCurrentUser() != null) return Promise.resolve()
          else {
            // hydrate current user
            try {
              await hydrateCurrentUser()
              return Promise.resolve()
            } catch (e) {
              console.error(e)
              return Promise.reject({ message: false })
            }
          }
        } else {
          return Promise.reject({ message: false })
        }
      },
      getPermissions: (): Promise<string[]> => {
        // AUTHORIZATION
    
        return Promise.resolve(AppState.getRoles())
      },
      // getIdentity: (): Promise<Identity> => {
      //   const { account, user } = AppState.getIdentity()
      //   try {
      //     return Promise.resolve({ id: user ? user.id : null, account, user })
      //   } catch (error) {
      //     return Promise.reject(error)
      //   }
      // },
      logout: async () => {
        // called on user logout (logout button, checkError rejection, checkAuth rejection)
        // should handle current authentication data clean up (i.e. reset local storage)

        try {
          const passportUserInfo = await passportInstance.getUserInfo()

          if (passportUserInfo) await passportLogout()
        } catch(e) {
          console.error('Error logging out', e)
          // NOTE this should only happen when token is expired.
          // call silent logout to clear session
          await passportLogout()
        }

        const request = generateFetchRequest({
          method: 'DELETE',
          path: 'users/sign_out',
        })

        try {
          const response = await fetch(request)
          if (response.status != 204 && response.status != 200) {
            throw new Error('Error logging out')
          } else {
            AppState.reset()
            return 'login'
          }
        } catch(e) {
          console.error('Error logging out', e)
          throw e
        }
      },
    }
  }
}
