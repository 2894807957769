import { SvgIcon, SvgIconProps } from '@mui/material'

export const DownloadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M16.25 15.5V18H3.75V15.5H2.5V18C2.5 18.3315 2.6317 18.6495 2.86612 18.8839C3.10054 19.1183 3.41848 19.25 3.75 19.25H16.25C16.5815 19.25 16.8995 19.1183 17.1339 18.8839C17.3683 18.6495 17.5 18.3315 17.5 18V15.5H16.25ZM16.25 9.25L15.3687 8.36875L10.625 13.1062V1.75H9.375V13.1062L4.63125 8.36875L3.75 9.25L10 15.5L16.25 9.25Z'
        fill='#F7F3F2'
      />
    </SvgIcon>
  )
}
