import React, { useState, useEffect } from 'react'
import { useNotificationContext, useTranslate, undoableEventEmitter, useNotify } from 'react-admin'
import { Alert, Snackbar, Slide, Stack, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

const TransitionLeft = props => {
  return <Slide {...props} direction='left' timeout={500} onAnimationEnd={props.onExited} />
}

// Custom notification component.
export const Notification = () => {
  const { notifications, takeNotification } = useNotificationContext()
  const [messageInfo, setMessageInfo] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (notifications.length && !messageInfo) {
      setMessageInfo(takeNotification())
      setIsOpen(true)
    }
  }, [notifications, messageInfo, takeNotification])

  const handleClose = (_, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setIsOpen(false)
  }

  // Reset message info after snackbar is closed. This is necessary to prevent
  // the snackbar from flashing with wrong color when a new notification is closed.
  const resetMessageInfo = () => setMessageInfo(null)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      autoHideDuration={8000}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      TransitionProps={{ onExited: resetMessageInfo }}
    >
      <Alert severity={messageInfo?.type} sx={{ width: '100%' }}>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography textTransform='uppercase'>{messageInfo?.message}</Typography>
          <IconButton aria-label='close' onClick={handleClose} sx={{ p: 0.5 }}>
            <Close sx={{ height: 18, width: 18 }} />
          </IconButton>
        </Stack>
      </Alert>
    </Snackbar>
  )
}
