import { Stack, TextField, Checkbox, FormControlLabel, Typography, Alert, Link } from '@mui/material'
import { Button } from '@atmos/components/buttons/Button'
import { Controller, useForm } from 'react-hook-form'

export const RegistrationForm = ({ onRegister, displayError, errorMessage }) => {
  const form = useForm({ defaultValues: { email: '', callsign: '', touAccepted: false }, mode: 'onChange' })

  // Form values
  const { formState, handleSubmit } = form

  const onSubmitClick = async formData => {
    try {
      onRegister(formData)
    } catch (error) {
      displayError(error)
    }
  }

  const isTouAccepted = form.watch('touAccepted')

  const callSignValidation = {
    required: 'Call sign is required',
    pattern: { value: /^[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ0-9\-_]/, message: 'Must start with alpha character' },
    message: 'Must start with alpha character!',
    maxLength: { value: 15, message: 'Must be less than 15 characters' },
    minLength: { value: 4, message: 'Must be more than 4 characters' },
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitClick)}>
        <Stack spacing={2} alignItems='center'>
          <Controller
            name='email'
            control={form.control}
            rules={{ required: 'Email is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!formState.errors.email}
                label='Email'
                helperText={formState.errors.email?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name='callsign'
            control={form.control}
            rules={callSignValidation}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!formState.errors.callsign}
                label='Call Sign'
                helperText={formState.errors.callsign?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name='touAccepted'
            control={form.control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={
                  <Typography>
                    I accept the{' '}
                    <Link href='https://atmos.xyz/legal/terms-of-use/' target='_blank'>
                      Atmos Terms of Use
                    </Link>
                  </Typography>
                }
              />
            )}
          />
          {errorMessage && (
            <Alert severity='error' variant='outlined'>
              {errorMessage}
            </Alert>
          )}
          <Button
            disabled={!formState.isValid || !isTouAccepted}
            type='submit'
            onClick={handleSubmit(onSubmitClick)}
            label='Sign up'
          />
        </Stack>
      </form>
      <Stack spacing={1} mt={4}>
        <Typography textAlign='center'>Already have an Atmos account?</Typography>
        <Typography textAlign='center'>Make sure you are connected to the correct wallet</Typography>
      </Stack>
    </>
  )
}
