import * as React from 'react'
import moment from 'moment'
import { FC } from 'react'
import { useRecordContext } from 'react-admin'
// export const TimeAgo = datetime => {
//   return moment(datetime).fromNow();
// };

// TimeAgo.propTypes = {
//   datetime: PropTypes.datetime.isRequired
// };

export const timeAgo = timestamp => {
  if (!timestamp) return null

  const m = moment(timestamp)
  return <span title={m.format('dddd, MMMM Do YYYY, h:mm a')}>{m.fromNow()}</span>
}

interface TimeAgoFieldProps {
  label?: string
  record?: { [key: string]: any }
  source: string
}

export const TimeAgoField: FC<TimeAgoFieldProps> = ({ source }) => {
  const record = useRecordContext()
  return timeAgo(record[source])
}
