import React, { FC } from 'react'

import { Typography, Stack, Alert, Box } from '@mui/material'

import {
  ReferenceManyField,
  useListContext,
  useDataProvider,
  useNotify,
  useRefresh,
  RaRecord,
} from 'react-admin'
import { useSearchParams } from 'react-router-dom'

import { ConfirmModal } from '@core/dash/resources/user/me/ConfirmModal'
import { Button, ButtonProps } from '@atmos/components/buttons/Button'
import { Spinner } from '@atmos/components/Spinner'
import { Provider, useProviders } from './providers/useProviders'

const DisconnectButton = ({ provider: { onDisconnectSuccess }, record }: { record: RaRecord, provider: Provider}) => {
  const dataProvider = useDataProvider()

  const notify = useNotify()
  const refresh = useRefresh()

  const onDelete = async () => {
    try {
      await dataProvider.delete('Identity', { id: record.id, previousData: record })
      notify('Your identity has been disconnected', { type: 'success' })
      refresh()
      if (onDisconnectSuccess) await onDisconnectSuccess()
    } catch (e) {
      console.error(e)
      notify('There was an error disconnecting your identity. Our team has been notified.', { type: 'error' })
    }
  }

  return (
    <ConfirmModal
      modalTitle='Disconnect Identity'
      modalMessage='You will need to reauthenticate once this is done. Are you sure?'
      onConfirm={onDelete}
      confirm='Disconnect'
      cancel='Cancel'
    />
  )
}

const ConnectionList = ({ passportSvg }) => {
  const { data: connections, isLoading } = useListContext()
  const providers = useProviders()

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Stack sx={{ width: '100%', mb: 5 }} spacing={4}>
      {providers.map(provider => {
        const connection = connections?.find(d => d.provider === provider.key)
        if (connection) {
          const [username, discriminator] = connection.displayUid.split('#')

          return (
            <Stack direction='row' justifyContent='space-between' width='100%'>
              <Stack direction='row' alignItems='center'>
                {provider.icon}
                <Stack direction='row' my='auto' px={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography>{username}</Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: '0.5rem' }}>Connected to {provider.display}</Typography>
                  </Box>
                  {discriminator && <Typography>#{discriminator}</Typography>}
                </Stack>
              </Stack>

              <DisconnectButton provider={provider} record={connection} />
            </Stack>
          )
        } else {
          const connectBtnProps: ButtonProps = {
            variant: 'outlined',
            label: 'Connect',
            sx: { flexShrink: 0 },
            ...provider.cntBtnProps
          }

          return (
            <Stack direction='row' justifyContent='space-between' width='100%' spacing={2}>
              <Stack direction='row' alignItems='center' spacing={2}>
                {provider.icon}
                <Typography
                  sx={{ my: 'auto', fontSize: { xxs: '0.8rem', xs: '1rem' }, textTransform: 'uppercase' }}
                >{`Link to ${provider.display}`}</Typography>
              </Stack>
              <Button {...connectBtnProps} />
            </Stack>
          )
        }
      })}
    </Stack>
  )
}

export const ConnectionListView: FC = () => {
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error')
  const providers = useProviders()

  return (
    <>
      {error && (
        <Alert severity='error' variant='outlined'>
          {error}
        </Alert>
      )}
      <ReferenceManyField filter={{ provider: providers.map(p => p.key) }} reference='Identity' target='userId'>
        <ConnectionList />
      </ReferenceManyField>
      <Stack width='fit-content' spacing={4}>
        <Button
          variant='containedSecondary'
          className='join-discord'
          href='https://discord.gg/planetatmos'
          target='_blank'
          label='Join us on discord'
        />
        <Button
          variant='containedSecondary'
          className='follow-twitter'
          href='https://x.com/planet_atmos'
          target='_blank'
          label='Follow us on X'
        />
      </Stack>
    </>
  )
}
