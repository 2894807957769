import * as React from 'react'
import { FC } from 'react'
import { useGetOne } from 'react-admin'

interface PageTitleProps {
  record?: { [key: string]: any }
  resource: string
  source: string
}

const PageTitle: FC<PageTitleProps> = ({ record, resource, source }) =>
  record && <span>{record[source] || `${resource} ${record.id}`}</span>

export default PageTitle

interface PageTitleWithAssociationProps extends PageTitleProps {
  association: {
    key: string
    name: string
    resource: string
    source: string
  }
}

export const PageTitleWithAssociation: FC<PageTitleWithAssociationProps> = ({
  association,
  record,
  resource,
  source,
}) => {
  if (!record) return null

  const recordTitle = <PageTitle record={record} resource={resource} source={source} />

  if (!record[association.name]) return recordTitle

  const associationID = record[association.name][association.key]
  const { data: associatedRecord, isLoading, error } = useGetOne(association.resource, { id: associationID })

  if (error) console.log('error loading page title with association', error)
  if (isLoading) return null

  return (
    <div>
      <PageTitle record={associatedRecord} resource={association.resource} source={association.source} />: {recordTitle}
    </div>
  )
}
