import * as React from 'react'
import { forwardRef, useState } from 'react'
import { useLogout } from 'react-admin'
import ExitIcon from '@mui/icons-material/PowerSettingsNew'
import { MenuItem, Typography, Stack } from '@mui/material'

export const LogoutButton = forwardRef((_props: any, ref: any) => {
  const [loggingOut, setLoggingOut] = useState(false)
  
  const logout = useLogout()

  const handleClick = async () => {
    setLoggingOut(true)

    try {
      await logout()
    } catch (e) {
      console.log('error', e)
    }

    setLoggingOut(false)
  }

  return (
    <MenuItem onClick={handleClick} ref={ref} disabled={loggingOut}>
      <Typography variant='h6'>LOG OUT</Typography>
    </MenuItem>
  )
})
