import { Box, Typography, Link } from '@mui/material';
import { useRecordContext, FieldProps, ListContextProvider, useList, SingleFieldList,Labeled } from 'react-admin';

import { TypographyCopyable } from '@atmos/components/buttons/TypographyCopyable';

const accessCodeGroupsConfig = {
  'epic_exogp_season_zero': {
    label: 'EPIC GAMES STORE CODE - EXOGP SEASON ZERO',
    empty: {
      label: 'No Access Codes are Unlocked',
      CTA: (
        <Typography sx={{ mt: 2 }} variant='body1'><Link target="_blank" rel='noreferrer' sx={{ border: 'none' }} href="https://magiceden.io/collections/ethereum/0x2a0c44ace671744edc884ea182bea52cebac5431">BUY AN EXO</Link> to unlock ExoGP Season Zero on EPIC</Typography>
      )
    }
  }
};

export const AccessCodesField = (_props: FieldProps) => {
  const record = useRecordContext();

  const listContext = useList({ data: record?.accessCodes || [], isLoading: !record });

  return (
    <Box sx={{ marginTop: 7 }}>
      <Typography variant='h6'>ACCESS CODES</Typography>
      <ListContextProvider value={listContext}>
        <SingleFieldList
          sx={{ mt: 3 }}
          empty={<EmptyAccessCodesContent {...accessCodeGroupsConfig['epic_exogp_season_zero'].empty}/>}
          linkType={false}
        >
          <AccessCodeField />
        </SingleFieldList>
      </ListContextProvider>
    </Box>
  );
};

const AccessCodeField = (props: FieldProps) => {
  const record = useRecordContext();

  if (!record) return null

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
      <Labeled label={accessCodeGroupsConfig[record.group].label}>
        <TypographyCopyable sx={{ variant: 'body1' }}>{record.code}</TypographyCopyable>
      </Labeled>
    </Box>
  )
}

const EmptyAccessCodesContent = ({ label, CTA }) => (
  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
    <Typography variant='body1'>{label}</Typography>
    {CTA}
  </Box>
)
