import React, { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import { useWaitForTransaction } from 'wagmi'
import { useNotify } from 'react-admin'
import { Spinner } from '@atmos/components/Spinner'
import { Button } from '@atmos/components/buttons/Button'
import { cryptoHrefGenerator } from '@components/Crypto/Link'
import { ModalType } from './types'
import { Analytics, AnalyticsEvents } from '@core/dash/utils/analytics'

interface PendingTransactionProps {
  isVisible: boolean
  setIsButtonDisabled: (isDisabled: boolean) => void
  setTransferStep: (step: ModalType) => void
  onCancel: () => void
  txHash: string
  selectedAsset: { network: string }
}

export const PendingTransaction: FC<PendingTransactionProps> = ({
  selectedAsset,
  txHash,
  setTransferStep,
  setIsButtonDisabled,
}) => {
  const notify = useNotify()

  useWaitForTransaction({
    hash: txHash,
    onSuccess(data) {
      // console.log('tx success', data)
      notify('Transfer is complete.', { type: 'success' })
      setTransferStep(ModalType.Complete)
      setIsButtonDisabled(false)
      Analytics.publishEvent(AnalyticsEvents.AssetTransfer4)
    },
    onError(error) {
      // console.log('tx error', error)
      notify(`Transfer failed: ${error.message}`, { type: 'error' })
      setTransferStep(ModalType.Review)
      setIsButtonDisabled(false)
    },
  })

  return (
    <Stack spacing={2} alignItems='center' marginY={2}>
      <Typography variant='h2' mb={2}>
        TRANSFER PENDING...
      </Typography>
      <Spinner sx={{ pb: 2 }} />
      <Button
        href={cryptoHrefGenerator({
          linkType: 'tx',
          network: selectedAsset.network,
          chain: selectedAsset.chain,
          target: txHash,
        })}
        target='_blank'
        label='View on Block Explorer'
        variant='text'
      />
    </Stack>
  )
}
