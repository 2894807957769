import { useRefresh } from 'react-admin'

// import EpicSvg from './epic-logo.svg'
import DiscordSvg from './discord-logo.svg'
import TwitterSvg from './twitter-logo.svg'
import ImmutableSvg from './immutable-symbol-BLK-RGB.svg'
import { IconCircleWrapper } from '../IconCircleWrapper'
import { 
  usePassportConnectWeb3Identities,
  usePassportLogin, 
  usePassportLogout 
} from '@core/immutable/passport/usePassport'
import { csrfToken } from '@core/clients/fetch'
import { Box } from '@mui/material'

// const discordHint = () => (
//   <p style={{ marginTop: '40px', fontSize: '14px' }}>
//     Visit our community on{' '}
//     <Link to='https://discord.gg/mAKJu5quqn' target='_blank'>
//       Discord
//     </Link>
//   </p>
// )

export interface Provider {
  key: string;
  display: string;
  icon: JSX.Element;
  cntBtnProps: {
    href?: string;
    onClick?: () => void;
  };
  onDisconnectSuccess?: () => void;
}

export const useProviders = () => {
  const { login: passportLogin, isLoading: passportIsLoading } = usePassportLogin()
  const { logout: passportLogout } = usePassportLogout()
  const { connectWeb3Identities: connectPassportWeb3Identities } = usePassportConnectWeb3Identities()

  const refresh = useRefresh()

  const onPassportLoginSuccess = async () => {
    try {
      await connectPassportWeb3Identities()
    } catch (e) {
      console.error('error connecting passport web3 identities', e)
    }

    refresh()
  }

  if (passportIsLoading) return []

  const providers: Provider[] = [
    {
      key: 'discord',
      display: 'Discord',
      icon: <IconCircleWrapper icon={<DiscordSvg />} />,
      // hint: discordHint,
      cntBtnProps: {
        href: `/users/auth/discord?authenticity_token=${csrfToken()}`
      }
    },
    { 
      key: 'twitter', 
      display: 'X',
      icon: <IconCircleWrapper icon={<TwitterSvg />} /> ,
      cntBtnProps: {
        href: `/users/auth/twitter?authenticity_token=${csrfToken()}`
      }
    },
    // { 
    //   key: 'epic', 
    //   display: 'Epic',
    //   icon: <IconCircleWrapper icon={<EpicSvg />} />,
    //   cntBtnProps: {
    //     href: `/users/auth/epic?authenticity_token=${csrfToken()}`
    //   }
    // },
    { 
      key: 'immutable_passport', 
      display: 'Passport',
      icon: (
        <IconCircleWrapper 
          icon={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'primary.main',
                borderRadius: '50%',
                width: 35,
                height: 35
              }}
            >
              <ImmutableSvg style={{ transform: 'scale(7)'}} />
            </Box>
          } 
        />
      ),
      cntBtnProps: {
        onClick: () => passportLogin({ onSuccess: onPassportLoginSuccess })
      },
      onDisconnectSuccess: passportLogout
    }
  ]

  return providers
}
