import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, Stack, Typography, Box, IconButton, Tooltip } from '@mui/material'
import { Button } from '@atmos/components/buttons/Button'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AppState from 'dash/app_state'
import { assetTitle, AssetImage } from '@atmos/web3/react-admin/resources/user/assets/assetUtils'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2 (recommmended)
import { CryptoChainBadge } from './CryptoChainBadge'
import { RedirectionSideEffect, useRedirect, useNotify } from 'react-admin'
import { useAccount, useNetwork } from 'wagmi'
import { BLOCK_EXPLORER_CHAIN_PREFIX, BLOCK_EXPLORER_NETWORK_URL, OPENSEA_CHAIN_PATH } from '@components/Crypto/Link'
import { AssetTransferModal } from '@atmos/web3/components/AssetTransferModal'
import { ModalType } from '@atmos/web3/components/AssetTransferModal/types'
import { colors } from '@core/dash/layout/CustomLayout/theme'
import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import { checkIfSupportedNetwork } from '@atmos/components/utils'
import { DownloadIcon } from '@atmos/icons/DownloadIcon'
import { ViewIcon } from '@atmos/icons/ViewIcon'
import { SendIcon } from '@atmos/icons/SendIcon'
import { Analytics, AnalyticsEvents } from '@core/dash/utils/analytics'

// Grid styles.
const border = '1px solid'
const gridStyles = {
  borderLeft: border,
  borderColor: 'divider',
  '& > div': {
    borderRight: border,
    borderBottom: border,
    borderColor: 'divider',
  },
  // first and second grid children should have top border
  '& > div:nth-child(1)': {
    borderTop: border,
    borderColor: 'divider',
  },
  '& > div:nth-child(2)': {
    borderTop: border,
    borderColor: 'divider',
  },
}

const responsiveDirection = {
  xxs: 'column',
  md: 'row',
} as const

const mediaStyle = { objectFit: 'cover' }
const navButtonsLrgScreenStyle = {
  position: 'fixed',
  zIndex: 100,
  top: '50%',
  display: { xxs: 'none', md: 'inline-flex' },
}
const navButtonsSmScreenStyle = {
  position: 'absolute',
  zIndex: 100,
  top: '50%',
  transform: 'translateY(-50%)',
  display: { xxs: 'none', sm: 'inline-flex', md: 'none' },
}

const ResponsiveImageContainer = styled(Box)(({ theme }) => ({
  // Smallest mobile xxs.
  alignSelf: 'center',
  position: 'relative',
  height: 350,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  // Medium mobile.
  [theme.breakpoints.up('xs')]: {
    height: 500,
  },
  // Tablet.
  [theme.breakpoints.up('sm')]: {
    height: 500,
    width: 500,
  },
  // Desktop.
  [theme.breakpoints.up('md')]: {
    height: 460,
    width: 460,
    alignSelf: 'flex-start',
  },
}))

const fakeTraits = [
  {
    trait_type: 'Background',
    value: 'Blue',
  },
  {
    trait_type: 'Cover Artist',
    value: 'John Doe',
  },
  {
    trait_type: 'Edition',
    value: '1/1',
  },
  {
    trait_type: 'Flight Crew',
    value: 'Jill Doe',
  },
  {
    trait_type: 'Logo',
    value: 'Atmos',
  },
  {
    trait_type: 'Name',
    value: 'Exordium',
  },
  {
    trait_type: 'Rarity',
    value: 'Common',
  },
  {
    trait_type: 'Series',
    value: 'Exordium',
  },
  {
    trait_type: 'Type',
    value: 'NFT',
  },
  {
    trait_type: 'Background',
    value: 'Blue',
  },
  {
    trait_type: 'Cover Artist',
    value: 'John Doe',
  },
  {
    trait_type: 'Edition',
    value: '1/1',
  },
  {
    trait_type: 'Flight Crew',
    value: 'Jill Doe',
  },
  {
    trait_type: 'Logo',
    value: 'Atmos',
  },
  {
    trait_type: 'Name',
    value: 'Exordium',
  },
  {
    trait_type: 'Rarity',
    value: 'Common',
  },
  {
    trait_type: 'Series',
    value: 'Exordium',
  },
  {
    trait_type: 'Type',
    value: 'NFT',
  },
]

export const AssetViewModal = ({ selectedAsset, handleCloseModal, assets, setSelectedAsset }) => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true)
  const [transferModalVisible, setTransferModalVisible] = React.useState(false)
  const [detailsMenuAnchorEl, setDetailsMenuAnchorEl] = React.useState(null)
  const [canToggleNext, setCanToggleNext] = React.useState(false)
  const [canTogglePrev, setCanTogglePrev] = React.useState(false)

  const handleOpenDetailsMenu = event => setDetailsMenuAnchorEl(event.currentTarget)
  const handleCloseDetailsMenu = () => setDetailsMenuAnchorEl(null)

  const redirect = useRedirect()
  const notify = useNotify()

  const [transferStep, setTransferStep] = React.useState(ModalType.Review)

  const hideTransferModal = () => setTransferModalVisible(false)
  const showTransferModal = () => setTransferModalVisible(true)
  const { data: accountData } = useAccount()
  const { activeChain } = useNetwork()

  const connectedAccount = { data: accountData, chain: activeChain }

  const onMenuClick = (key: RedirectionSideEffect) => redirect(key)

  const handleAssetChange = indexDelta => {
    const currentIndex = assets.findIndex(asset => asset.id === selectedAsset.id)
    const nextIndex = currentIndex + indexDelta
    const nextAsset = assets[nextIndex]
    setSelectedAsset(nextAsset)
    setCanToggleNext(nextIndex < assets.length - 1)
    setCanTogglePrev(nextIndex > 0)
  }

  const handleShowTransferModal = () => {
    if (selectedAsset.chain === 'imtbl_zkevm')
      window.open('https://toolkit.immutable.com/transfer-nfts/', '_blank')
    else {
      setTransferStep(ModalType.Review)
      Analytics.publishEvent(AnalyticsEvents.AssetTransfer1)
      showTransferModal()
    }
  }

  const validateTransfer = onTransferValidationSuccess => {
    const connectedNetwork = connectedAccount.chain?.network
    const noTransferableAssets = transferableAssetCount(connectedAccount?.data?.address) === 0

    if (!connectedNetwork) {
      notify('You need to connect your web3 wallet to transfer assets.', { type: 'error' })
      return
    }

    if (noTransferableAssets) {
      notify(
        'The currently selected wallet does not have any assets to transfer. Please change your selected wallet.',
        { type: 'error' }
      )
      return
    }

    const isSupportedNetwork = checkIfSupportedNetwork(connectedNetwork, selectedAsset.chain, selectedAsset.network)
    if (isSupportedNetwork) {
      onTransferValidationSuccess()
    } else {
      notify(
        `This transfer can only be performed on the ${selectedAsset.chain} ${selectedAsset.network} network. Please change to that network in your wallet.`,
        { type: 'error' }
      )
    }
  }

  useEffect(() => {
    const currentIndex = assets.findIndex(asset => asset.id === selectedAsset.id)
    setCanToggleNext(currentIndex < assets.length - 1)
    setCanTogglePrev(currentIndex > 0)
  }, [])

  const transferableAssetCount = (walletAddress: string) => {
    // handle checksummed address as well as all lower case address (BE is citext)
    return (
      selectedAsset.ownershipDetail[walletAddress] || selectedAsset.ownershipDetail[walletAddress?.toLowerCase()] || 0
    )
  }

  // Place default "READ" button at the end of the list so it displays on the right.
  const sortedUnlockableContent = selectedAsset.unlockableContent.sort((a, b) => {
    if (a.title.toUpperCase().includes('BONUS')) {
      return -1
    } else if (b.title.toUpperCase().includes('BONUS')) {
      return 1
    } else {
      return 0
    }
  })

  const unlockableContent = sortedUnlockableContent.map(content => {
    return {
      type: 'unlockableContent',
      variant: 'modalContainedSecondary',
      label: `${content.title.toUpperCase()}`,
      key: `/asset/${selectedAsset.id}/content/${content.id}`,
      onClick: () => onMenuClick(`/asset/${selectedAsset.id}/content/${content.id}`),
    }
  })

  const userAssociatedWallets = AppState.getCurrentUser().web3Accounts
  const assetOwners = Object.keys(selectedAsset.ownershipDetail)
  const isOwnedByUser = userAssociatedWallets.some(walletAddress => assetOwners.includes(walletAddress.toLowerCase()))
  const userAssetOwnerAddresses = userAssociatedWallets.filter(wallet => assetOwners.includes(wallet.toLowerCase()))
  const prefix = BLOCK_EXPLORER_CHAIN_PREFIX[selectedAsset.network]
  const url = BLOCK_EXPLORER_NETWORK_URL[selectedAsset.chain]

  const displayedOwnerAddress = owner => (
    <Button
      label={owner.slice(0, 6) + '...' + owner.slice(-4)}
      variant='text'
      href={`https://${prefix}${url}/address/${owner}`}
      target='_blank'
      sx={{ textDecoration: 'underline' }}
    />
  )

  const displayedOwnerAddresses = owner => (
    <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
      {displayedOwnerAddress(owner)}
      <Typography>OWNS {selectedAsset.ownershipDetail[owner.toLowerCase()]}</Typography>
    </Stack>
  )

  const modalActionButtons = [
    ...(selectedAsset.network === 'mainnet'
      ? [
          {
            variant: 'modalContainedSecondary',
            label: 'VIEW ON OPENSEA',
            key: 'OpenSea',
            onClick: () => {
              window.open(
                `https://opensea.io/assets/${OPENSEA_CHAIN_PATH[selectedAsset.chain]}/${
                  selectedAsset.contractAddress
                }/${selectedAsset.tokenId}`,
                '_blank'
              )
            },
          },
        ]
      : []),
    ...unlockableContent,
  ]
  const modalMenuOptions = [
    ...(selectedAsset.imageUrl && isOwnedByUser
      ? [
          {
            label: 'DOWNLOAD IMAGE',
            key: 'DownloadImage',
            icon: <DownloadIcon />,
            onClick: () => {
              // Fetch the image
              fetch(selectedAsset.imageUrl)
                .then(response => response.blob())
                .then(blob => {
                  // Create a URL for the blob
                  const url = URL.createObjectURL(blob)

                  // Create a new anchor element
                  const a = document.createElement('a')
                  a.download = 'image.jpg' // Set the file name
                  a.href = url // Set the URL created for the blob

                  // Programmatically click the anchor to download the file
                  a.click()

                  // Clean up the URL object
                  URL.revokeObjectURL(url)
                })
                .catch(error => console.error(error))
            },
          },
        ]
      : []),
    ...(isOwnedByUser
      ? [
          {
            label: 'TRANSFER ASSET',
            icon: <SendIcon />,
            key: 'TransferAsset',
            onClick: () => validateTransfer(handleShowTransferModal),
          },
        ]
      : []),
    {
      label: 'VIEW ON BLOCK EXPLORER',
      key: 'BlockExplorer',
      icon: <ViewIcon />,
      onClick: () => {
        window.open(`https://${prefix}${url}/address/${selectedAsset.contractAddress}`, '_blank')
      },
    },
  ]

  // Make the last modal action button primary red.
  if (modalActionButtons.length) {
    modalActionButtons[modalActionButtons.length - 1].variant = 'modalContained'
  }
  // NFT traits.
  const selectedAssetTraits = selectedAsset.traits || []

  const description = selectedAsset.blockchainMeta?.description

  return (
    <>
      <Dialog open={!!selectedAsset} fullWidth maxWidth='lgModal' sx={{ m: 0 }}>
        <DialogContent sx={{ position: 'relative' }}>
          <Button
            variant='small'
            onClick={() => handleAssetChange(-1)}
            disabled={!canTogglePrev}
            label={<ArrowBackIcon />}
            sx={{
              ...navButtonsLrgScreenStyle,
              left: '50%',
              transform: 'translate(-900%, -50%)',
            }}
          />
          <Button
            variant='small'
            onClick={() => handleAssetChange(1)}
            disabled={!canToggleNext}
            label={<ArrowForwardIcon />}
            sx={{
              ...navButtonsLrgScreenStyle,
              right: '50%',
              transform: 'translate(900%, -50%)',
            }}
          />
          <Stack spacing={3} padding={2} pb={3}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant='h3'>{assetTitle(selectedAsset)}</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            {isOwnedByUser && selectedAsset.contractStandard === 'ERC1155' && (
              <Stack spacing={1} width='fit-content'>
                {userAssetOwnerAddresses.map(owner => displayedOwnerAddresses(owner))}
              </Stack>
            )}
            {selectedAsset.contractStandard === 'ERC721' && (
              <Stack direction='row' flexWrap='wrap' spacing={1} alignItems='center'>
                <Typography>OWNED BY:</Typography>
                {displayedOwnerAddress(assetOwners[0])}
              </Stack>
            )}
            <Stack direction={responsiveDirection} justifyContent='space-around'>
              <ResponsiveImageContainer>
                <Button
                  variant='small'
                  onClick={() => handleAssetChange(-1)}
                  disabled={!canTogglePrev}
                  label={<ArrowBackIcon />}
                  sx={{ ...navButtonsSmScreenStyle, left: '-90px' }}
                />
                <Button
                  variant='small'
                  onClick={() => handleAssetChange(1)}
                  disabled={!canToggleNext}
                  label={<ArrowForwardIcon />}
                  sx={{ ...navButtonsSmScreenStyle, right: '-90px' }}
                />
                <AssetImage asset={selectedAsset} mediaStyle={mediaStyle} />
              </ResponsiveImageContainer>
              <Stack p={{ xxs: '24px 0 0 0', md: '0 0 0 24px' }} flexBasis='50%'>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='space-between'>
                  <Typography variant='h5'>DETAILS</Typography>
                  <Stack direction='row'>
                    {modalMenuOptions.map(item => (
                      <Tooltip title={item.label} placement='bottom'>
                        <IconButton
                          onClick={() => {
                            item.onClick()
                            handleCloseDetailsMenu()
                          }}
                          sx={{ p: 1 }}
                        >
                          {item.icon}
                        </IconButton>
                      </Tooltip>
                    ))}
                  </Stack>
                </Stack>
                <Stack direction='row' alignItems='center' pb={3} pt={1} spacing={1}>
                  <CryptoChainBadge chain={selectedAsset.chain} />
                  <Typography color='text.secondary'>
                    {selectedAsset.chain.toUpperCase()} | TOKEN ID: {selectedAsset.tokenId}
                  </Typography>
                </Stack>
                <TraitsGrid selectedAssetTraits={selectedAssetTraits} />
              </Stack>
            </Stack>
            <Typography variant='h4'>COLLECTION DESCRIPTION</Typography>
            <Typography variant='body2' color='text.secondary'>
              {description}
            </Typography>
          </Stack>
          <Stack
            direction={{ xxs: 'column-reverse', md: 'row' }}
            sx={{
              position: 'sticky',
              bottom: 0,
              justifyContent: 'stretch',
              gap: '1px',
              backgroundColor: colors.grey90,
              zIndex: 100,
            }}
          >
            <Button variant='modalGhost' onClick={handleCloseModal} label='CLOSE' />
            {modalActionButtons.map(button =>
              button.type === 'unlockableContent' ? (
                <Button
                  variant={button.variant}
                  label={button.label}
                  component={Link}
                  to={button.key}
                  target='_blank'
                />
              ) : (
                <Button variant={button.variant} label={button.label} onClick={button.onClick} />
              )
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      <AssetTransferModal
        selectedAsset={selectedAsset}
        handleCloseModal={handleCloseModal}
        validateTransfer={validateTransfer}
        isVisible={transferModalVisible}
        setIsVisible={setTransferModalVisible}
        isButtonDisabled={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
        transferStep={transferStep}
        setTransferStep={setTransferStep}
        onCancel={hideTransferModal}
        transferableAssets={transferableAssetCount(connectedAccount?.data?.address)}
      />
    </>
  )
}

const TraitsGrid = ({ selectedAssetTraits = [] }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (selectedAssetTraits.length === 0) return null

  const visibleTraits = isOpen ? selectedAssetTraits : selectedAssetTraits.slice(0, 6)
  const buttonLabel = isOpen ? (
    <>
      HIDE
      <ExpandLessIcon />
    </>
  ) : (
    <>
      VIEW ALL
      <ExpandMoreIcon />
    </>
  )

  const renderTraits = traits => (
    <Grid container columns={2} sx={gridStyles}>
      {traits.map(trait => (
        <Grid xxs={1} p={2}>
          <Typography variant='h6' color='text.secondary' textTransform='uppercase'>
            {trait.trait_type}
          </Typography>
          <Typography variant='h6' textTransform='uppercase'>
            {trait.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )

  return (
    <Stack spacing={2}>
      <Typography variant='h5'>ATTRIBUTES</Typography>
      {renderTraits(visibleTraits)}
      {selectedAssetTraits.length > 6 && (
        <Button variant='text' onClick={() => setIsOpen(!isOpen)} label={buttonLabel} sx={{ width: '100%' }} />
      )}
    </Stack>
  )
}
