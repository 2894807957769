import React, { FC, useState } from 'react'
import { debounce } from '@core/shared/helpers'
import { useProvider } from 'wagmi'

import { TextField } from '@mui/material'

interface WalletInputProps {
  style?: React.CSSProperties
  value: string
  isValidAddress: boolean
  onChange: (value: string) => void
  setIsButtonDisabled: (disabled: boolean) => void
  setVerifyingAddress: (verifying: boolean) => void
  setIsValidAddress: (valid: boolean) => void
}

export const WalletInput: FC<WalletInputProps> = ({
  setIsButtonDisabled,
  value,
  setToAddress,
  setVerifyingAddress,
  setIsValidAddress,
  isValidAddress,
}) => {
  const provider = useProvider()

  const verifyAddress = React.useCallback(
    debounce(async (walletAddress: string) => {
      let isValid = false

      try {
        isValid = !!(await provider.resolveName(walletAddress)) // Make a call to the provider to verify the address.
        setIsValidAddress(isValid)
      } catch (error) {
        setIsValidAddress(false)
      }
      setIsButtonDisabled(!isValid)
      setVerifyingAddress(false)
    }, 1000),
    []
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const walletAddress = e.target.value
    setIsButtonDisabled(true) // Disable button until address is verified
    if (walletAddress.length > 255) return // Prevents long addresses from being entered
    setToAddress(walletAddress) // Update input value
    setVerifyingAddress(true) // Show loading indicator
    verifyAddress(walletAddress) // Verify address
  }

  return (
    <TextField
      label='DESTINATION ADDRESS'
      value={value}
      onChange={onChange}
      placeholder='Input a Valid Wallet Address'
      error={!isValidAddress}
      helperText={!isValidAddress ? 'Invalid Address' : 'ENS Addresses Supported'}
    />
  )
}
