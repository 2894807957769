import { FC } from 'react'

import {
  AdminContext,
  AdminUI,
  Authenticated,
  CustomRoutes,
  defaultI18nProvider,
  localStorageStore,
  Resource,
} from 'react-admin'
import { Route } from 'react-router-dom'
import { QueryClient } from 'react-query'

import AppState from 'dash/app_state'
import Auth from 'dash/auth'
import CustomLayout from 'dash/layout/CustomLayout'
import Theme from 'dash/layout/CustomLayout/theme'
import UserResources from 'dash/resources/user'
import { hydrateCurrentUser } from 'dash/utils/hydrateCurrentUser'
import { NFTViewer } from '@atmos/components/NFTViewer'
import { Notification } from '@atmos/components/notification'
import { NotFound } from 'dash/layout/CustomLayout/NotFound'

interface DashboardAppProps {
  dataProvider: any
}

const DashboardUI: FC = () => {
  return (
    <AdminUI catchAll={NotFound} layout={CustomLayout} notification={Notification} loginPage={Auth.Login} requireAuth>
      {async (permissions: string[]) => {
        // This is called once (on login, or on app load if already logged in)

        // hydrate user if it hasn't been set yet
        // primarily for oauth flow that doesn't call
        // authProvider.login()
        if (AppState.authCookiePresent() && AppState.getCurrentUser() == null) {
          // hydrate current user
          try {
            await hydrateCurrentUser()
          } catch (e) {
            console.error(e)
          }
        }

        // const accountID = AppState.getAccountIdFromURL()
        // if (accountID && accountID != AppState.getCurrentAccount()?.id)
        //   await AppState.updateCurrentAccount({ id: accountID })

        return (
          <>
            {/* <Resource
              key='user-release-resource'
              name='user/release'
              options={[{ label: 'Releases', menu: 'user' }]}
              list={UserResources.Release.List}
              icon={UserResources.Release.Icon}
            /> */}
            <Resource
              key='user-asset-resource'
              name='user/asset'
              // options={[{ label: 'Assets', menu: 'user' }]}
              list={UserResources.Asset.List}
              // show={UserResources.Asset.Show}
            />
            {/* API resources */}
            <Resource key='current-user-resource' name='CurrentUser' />
            <Resource key='identity-resource' name='Identity' />
            <Resource key='referral-link-resource' name='Referral' />
            <Resource key='collection-resource' name='Collection' />
            <Resource key='contract-resource' name='Contract' />
            <Resource key='transfer-resource' name='Transfer' />
            <Resource key='unlockable-content-resource' name='UnlockableContent' />
            <Resource key='access-codes' name='AccessCode' />
            {/* <Resource key="account-resource" name="Account" /> */}
            {/* <Resource key="asset-resource" name="Asset" /> */}
            {/* <Resource key="channel-resource" name="Channel" /> */}
            {/* <Resource key="entry-resource" name="Entry" /> */}
            {/* <Resource key="geo-resource" name="Geo" /> */}
            {/* <Resource key="membership-resource" name="Membership" /> */}
            {/* <Resource key="notification-resource" name="Notification" /> */}
            {/* <Resource key="user-resource" name="User" /> */}
            {/* <Resource key="service" name="Service" /> */}
            {/* <Resource key="subscription" name="Subscription" /> */}
            {/* <Resource key="robust-stripe-subscripition" name="StripeSubscription" /> */}
            {/* <Resource key="version-resource" name="Version" /> */}

            {/* custom routes that aren't associated with a resource */}
            <CustomRoutes>
              <Route
                key='home-route'
                path='/'
                element={
                  <Authenticated>
                    <UserResources.Home.Show />
                  </Authenticated>
                }
              />
              <Route
                key='current-user-route'
                path='/user/me/*'
                element={
                  <Authenticated>
                    <UserResources.Me.Edit />
                  </Authenticated>
                }
              />
              <Route
                key='nft-viewer-route'
                path='/asset/:assetId/content/:contentId'
                element={
                  <Authenticated>
                    <NFTViewer />
                  </Authenticated>
                }
              />
            </CustomRoutes>
          </>
        )
      }}
    </AdminUI>
  )
}

const DashboardApp: FC<DashboardAppProps> = ({ dataProvider }) => {
  const store = localStorageStore()
  const { isLoading: authProviderLoading, provider: authProvider } = Auth.useAuthProvider()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  if (authProviderLoading) return null

  return (
    <AdminContext
      authProvider={authProvider}
      dataProvider={dataProvider}
      queryClient={queryClient}
      i18nProvider={defaultI18nProvider}
      store={store}
      theme={Theme}
    >
      <DashboardUI />
    </AdminContext>
  )
}

export default DashboardApp
