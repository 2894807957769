export enum ModalType {
  Review = 'REVIEW',
  Pending = 'TRANSACTION_PENDING',
  Complete = 'TRANSACTION_COMPLETE',
}

export enum ContractStandard {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}
