import React, { FC } from 'react'

import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import buildGraphQLProvider from 'ra-data-graphql-simple'
import * as ReactDOM from 'react-dom'

// import 'antd/dist/antd.dark.css'
import '../../../../engines/atmos/app/theme/atmos-theme.css'
import '../dash/custom.scss'

import { ErrorBoundary } from '@core/shared/bugsnag'
import { PassportProvider } from '@core/immutable/passport/PassportProvider'
import apolloClient from 'clients/apollo'
import DashLayout from 'dash/layout'
import customBuildQuery from 'ra/custom_build_query'

import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'

import { infuraProvider } from 'wagmi/providers/infura'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { useAnalytics } from '@core/shared/useAnalytics'

const { chains, provider } = configureChains(
  [chain.mainnet, chain.goerli, chain.polygon, chain.polygonMumbai], // Supported chains
  [
    alchemyProvider({ alchemyId: '30dj3lvbQSwbMJzqmjvA-Sj1omAoxCYL', priority: 0 }), // used in dev for mainnet
    infuraProvider({ infuraId: '19ea9cfcece6426b8480c57c307243e7', priority: 1 }), // used in dev for goerli
    publicProvider({ priority: 2 }),
  ]
)

// Set up connectors
const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: 'Atmos' },
    }),
    // new WalletConnectConnector({
    //   chains,
    //   options: { qrcode: true },
    // }),
  ],
  provider,
})

const DashboardApp: FC = () => {
  // State
  const [client, setClient] = React.useState<ApolloClient<NormalizedCacheObject>>()
  const [dataProvider, setDataProvider] = React.useState<any>()
  useAnalytics()

  // Helpers

  const init = async () => {
    setClient(apolloClient)

    try {
      const dataProvider = await buildGraphQLProvider({
        buildQuery: customBuildQuery,
        client: apolloClient,
        introspection: {
          operationNames: {}, // this was included to avoid a ts error, not sure why it's required in the ra-data-graphql-simple IntrospectionOptions type
          include: ['CurrentUser'], // resources we want included that don't implement the required GET_ONE & GET_MANY react-admin actions
        },
      })
      setDataProvider(() => dataProvider) // https://github.com/marmelab/react-admin/issues/3340#issuecomment-530236739
    } catch (e) {
      console.log('error in initDataProvider', e)
    }
  }

  // Effects

  React.useEffect(() => {
    init()
  }, []) // only init once

  if (!dataProvider) return <></>

  return (
    <ApolloProvider client={client}>
      <PassportProvider>
        <WagmiConfig client={wagmiClient}>
          <DashLayout dataProvider={dataProvider} />
        </WagmiConfig>
      </PassportProvider>
    </ApolloProvider>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  // ReactDOM.render(<DashboardApp />, document.body.appendChild(document.createElement('div')));

  const APP = ErrorBoundary ? (
    <ErrorBoundary>
      <DashboardApp />
    </ErrorBoundary>
  ) : (
    <DashboardApp />
  )

  ReactDOM.render(APP, document.body.appendChild(document.createElement('div')))
})
