import AppState from 'dash/app_state'
import { FETCH_CURRENT_USER } from 'shared/gql'
import apolloClient from 'clients/apollo'

export const hydrateCurrentUser = async () => {
  // hydrate current user
  try {
    const {
      data: { CurrentUser },
    } = await apolloClient.query({ query: FETCH_CURRENT_USER, fetchPolicy: 'no-cache' })
    if (!!CurrentUser) {
      await AppState.setCurrentUser(CurrentUser)
      // console.log('checkAuth AppState.currentUser', AppState.getCurrentUser()?.id)

      return Promise.resolve({ success: true, error: null })
    }
  } catch (e) {
    return Promise.reject({ success: true, error: e })
  }
}
