import React, { useState } from 'react'
import { Tooltip, Typography } from 'antd'
import { FC } from 'react'

import { Identity } from 'shared/types'

interface ProviderIconProps {
  identity: Identity
}

export const ProviderIconImg: FC<ProviderIconProps> = ({ identity }) => {
  const { provider, uid, response } = identity

  const passportWallet = response?.info?.provider == 'immutable_passport' && response?.info?.type == 'account'

  const exploreWeb3 = () => {
    if (provider === 'web3') {
      const explorerDomain = passportWallet ? 'https://explorer.immutable.com' : 'https://etherscan.io'
      window.open(`${explorerDomain}/address/${uid}`, '_blank')
    }
  }

  const imgProvider = passportWallet ? 'immutable_passport' : provider == 'web3' ? 'ethereum' : provider

  return (
    <img
      style={{ marginRight: 5 }}
      width='24'
      height='24'
      className={provider === 'web3' ? 'with-pointer' : ''}
      onClick={exploreWeb3}
      src={`images/social/${imgProvider}-dark.svg`}
    />
  )
}

export const ProviderIcon: FC<ProviderIconProps> = ({ identity }) => {
  return (
    <Tooltip
      overlayStyle={{ maxWidth: 450 }}
      title={
        <Typography.Paragraph copyable style={{ color: '#fff', marginBottom: 0 }}>
          {identity.uid}
        </Typography.Paragraph>
      }
    >
      <div>
        <ProviderIconImg identity={identity} />
      </div>
    </Tooltip>
  )
}
