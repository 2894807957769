// Subscription Plans

export type SubscriptionPlan = {
  name: string
  description: string
  startingPrice?: string
  basePrice?: string
  supportedFeatures: {
    key: string
    name?: string
    type?: 'addon'
    price?: string
    value?: boolean | string
    description?: string
  }[]
}

// Antd

export type FieldData = {
  touched?: boolean
  validating?: boolean
  errors?: string[]
  name: string[]
  value: any
}

// Models

export type Account = {
  id: string
  createdAt?: string
  name?: string
  members?: User[]
  memberships?: Membership[]
}

export type Activity = {
  id: string
  type: string
  actorId?: string
  actorType?: string
  humanizedType?: string
  objectId?: string
  objectType?: string
  targetId?: string
  targetType?: string
  meta?: { [key: string]: any }
  note?: string
  summary?: string
  createdAt: string
}

export type ChannelType =
  | 'Channels::EmailChannel'
  | 'Channels::SlackChannel'
  | 'Channels::WebhookChannel'
  | 'Channels::ZapierChannel'

export type Channel = {
  id: number
  config: { [key: string]: any }
  createdAt: string
  notificationsCount?: number
  notificationsLastAt?: string
  status: string
  testedAt?: string
  type: ChannelType
  updatedAt: string
  website?: string
}

export type Document = {
  id?: string
  byteSize?: number
  checksum?: string
  createdAt?: string
  checkedAt?: string
  updatedAt?: string
  downloadUrl?: string
  geos?: string[]
  geosList?: Geo[]
  kind?: string
  previewUrl?: string
  url?: string
  title?: string
}

export type Job = {
  id: string
  createdAt: string
}

// TODO: Move these into engines
export type Entry = {
  id: string
  data: any
  form: Form
  createdAt: string
  spam: boolean
  spamDetectionType: string
  spamEvaluation: any
  spamFingerprint: string
}

export type Form = {
  id: string
  compileSpec?: any
  key: string
  options?: any
  name: string
  source: string
  description?: string
  entriesCount?: number
  entriesLastAt?: string
  createdAt?: string
  updatedAt?: any
  config?: { name?: string; type?: string; label?: string; required?: boolean }[]
}

export type Geo = {
  id: string
  kind: string
  country: string
  region?: string
  subregion?: string
  createdAt: string
  updatedAt: string
}

export type Identity = {
  id: string
  displayOnProfile: boolean
  provider: string
  response: any
  uid: string
  user?: User
  createdAt: string
  updatedAt: string
}

export type Industry = Lookup

export type Lookup = {
  id: string
  displayWeight: number
  type: string
  name: string
}

export type Membership = {
  id: string
  availableAasmEvents: string[]
  roles: string[]
  status: string
  account: Account
  user: User
}

export type Metric = {
  key: string
  period: string
  value: number
  data: { [key: string]: any }
  createAt: string
}

export type Notification = {
  id: string
  createdAt: string
  channel: Channel
  notifiable: any
  type: string
}

// export type QueLocker = {
//   host: string;
//   pid: number;
//   workers: number;
// };

export type UserLite = {
  id: string
  email: string
  name: string
  username: string
  slug: String
}

export type User = UserLite & {
  authChannelId: string;
  createdAt: string
  roles: string[]
  memberships: Membership[]
  teams: Account[]
  type: string;
  web3Accounts: string[]
  unconfirmedEmail?: string
}

// REACT ADMIN

export type ReactAdminShow = {
  actions?: JSX.Element[] | JSX.Element
  aside?: JSX.Element
  component?: JSX.Element
  title?: string | JSX.Element
}

// MISC
export type Event = {
  createdAt: string
  description: string
}

export type VersionItem = Document | User

export type Version = {
  id: string
  itemId: string
  itemType: string
  event: string
  whodunnit: string
  createdAt: string
  // item: VersionItem;
  item: any
}

// BILLING
export type PayMethod = {
  expMonth: string
  expYear: string
  kind: string
  last4: string
  postalCode: string

  name?: string
}

export type Payment = {
  date: moment.Moment
  description: string
  payMethod: PayMethod
  amount: string
}

export type PaymentAccount = {
  id: string
  provider: string
  providerKey: string
  payableType: string
  payableId: string
}

export type Service = {
  id: string
  name: string
  productConfig: { [key: string]: any }
}

export type Subscription = {
  id: string
  providerKey: string
  status: string
  account: Account
  service: Service
  createdBy: User
  paidBy: User
}

export enum WalletActionTypes {
  ADD_WALLET = 'add_wallet',
  AUTH = 'authentication', // registration and login
}
export type WalletActionType = WalletActionTypes.ADD_WALLET | WalletActionTypes.AUTH
