import * as React from 'react'
import styled from 'styled-components'

const StyledAddress = styled.div`
  &&& {
    .address-static {
      font-size: 14px;
    }
    .placeholder {
      color: #bfbfbf;
    }
`

type AddressProps = {
  address?: string
  placeholder?: string
}

export const Address: React.FC<AddressProps> = ({ address, placeholder }) => {
  if (!address) return null

  const splitAddressArr = address.split(',')
  const addr1 = splitAddressArr[0]
  const addr2 = splitAddressArr.slice(1).join(',')

  return (
    <StyledAddress>
      <div className={`address-static${address == placeholder ? ' placeholder' : ''}`}>
        <div>{addr1}</div>
        <div>{addr2}</div>
      </div>
    </StyledAddress>
  )
}
