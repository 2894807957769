import React, { FC, useState } from 'react'

import { useAccount, useContractWrite, useSigner } from 'wagmi'
import { useNotify } from 'react-admin'
import { Dialog, DialogContent } from '@mui/material'
import { CollectTransferInput } from './CollectTransferInput'
import { PendingTransaction } from './PendingTransfer'
import { CompleteTransaction } from './CompleteTransfer'
import { ModalType } from './types'
import { Contract } from '../../shared/types'
import { ALERT_MESSAGE } from '@atmos/components/constants/general'
import { normalizeWalletError } from '@atmos/components/utils'
import { Analytics, AnalyticsEvents } from '@core/dash/utils/analytics'

const safeTransfer721 =
  '["function safeTransferFrom(address from, address to, uint256 tokenId, bytes calldata data) external"]'
const safeTransfer1155 =
  '["function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes calldata data) external"]'

interface AssetTransferModalProps {
  contract: Contract
  tokenId: number
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => any
  isButtonDisabled: boolean
  setIsButtonDisabled: (isDisabled: boolean) => void
  transferStep: ModalType
  setTransferStep: (step: ModalType) => void
  onCancel: () => any
  transferableAssets: number
  validateTransfer: (onTransferValidationSuccess: () => void) => void
  handleCloseModal: () => void
  selectedAsset: {
    contractAddress: string
    network: string
  }
}

export const AssetTransferModal: FC<AssetTransferModalProps> = ({
  validateTransfer,
  handleCloseModal,
  selectedAsset,
  setIsVisible,
  setIsButtonDisabled,
  transferStep,
  setTransferStep,
  ...props
}) => {
  const [txHash, setTxHash] = useState('')
  const notify = useNotify()

  const { data: accountData } = useAccount()
  const { data: signer } = useSigner({
    onError: error => {
      const error_msg = ALERT_MESSAGE.signerError + ' : ' + error.message
      console.log(error_msg)
      notify(error_msg, { type: 'error' })
    },
  })

  const handleCloseTransferModal = () => {
    setIsVisible(false)
  }

  const is721 = selectedAsset.contractStandard === 'ERC721'
  const contractInterface = is721 ? safeTransfer721 : safeTransfer1155

  const { write } = useContractWrite(
    {
      addressOrName: selectedAsset.contractAddress,
      contractInterface,
      signerOrProvider: signer,
    },
    'safeTransferFrom',
    {
      onError: transferError => {
        setTransferStep(ModalType.Review)
        // @ts-ignore: Some error messages are nested inside another error object.
        const message = normalizeWalletError(transferError.error ? transferError.error.message : transferError.message)
        notify(message, { type: 'error' })
        setIsButtonDisabled(false)
      },
      onSuccess: data => {
        setTxHash(data.hash)
        Analytics.publishEvent(AnalyticsEvents.AssetTransfer3)
        setTransferStep(ModalType.Pending)
        notify('Transaction is sent', { type: 'success' })
      },
    }
  )

  const onTransfer = async ({ toAddress, quantity }) => {
    Analytics.publishEvent(AnalyticsEvents.AssetTransfer2)
    setIsButtonDisabled(true)
    if (is721) {
      write({ args: [accountData.address, toAddress, selectedAsset.tokenId, 0] })
    } else {
      write({ args: [accountData.address, toAddress, selectedAsset.tokenId, quantity, 0] })
    }
  }

  // Next step modals take different props.
  const nextStepModalProps = {
    isVisible: props.isVisible,
    onCancel: props.onCancel,
    txHash,
    selectedAsset,
    setIsButtonDisabled,
    setTransferStep,
  }

  // Show different modal content based on the current checkout step.
  const getModalContent = () => {
    switch (transferStep) {
      case ModalType.Review:
        return (
          <CollectTransferInput
            onConfirm={onTransfer}
            validateTransfer={validateTransfer}
            setIsButtonDisabled={setIsButtonDisabled}
            selectedAsset={selectedAsset}
            {...props}
          />
        )
      case ModalType.Pending:
        return <PendingTransaction {...nextStepModalProps} />
      case ModalType.Complete:
        return (
          <CompleteTransaction
            handleCloseModal={handleCloseModal}
            handleCloseTransferModal={handleCloseTransferModal}
            {...nextStepModalProps}
          />
        )
    }
  }

  return (
    <Dialog open={props.isVisible} fullWidth maxWidth={transferStep === ModalType.Review ? 'lgModal' : 'sm'}>
      <DialogContent>{getModalContent()}</DialogContent>
    </Dialog>
  )
}
