import React from 'react'
import { Asset as Web3Asset } from '@atmos/web3/shared/types'

import AppState from '@core/dash/app_state'
import { CryptoLink } from '@components/Crypto/Link'

import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import { Popover } from '@atmos/web3/react-admin/resources/user/assets/Popover'
import { assetTitle, AssetImage } from '@atmos/web3/react-admin/resources/user/assets/assetUtils'
import { CryptoChainBadge } from '@atmos/web3/react-admin/resources/user/assets/CryptoChainBadge'
import { colors } from '@core/dash/layout/CustomLayout/theme'

type AssetType = {
  id: number
  imageUrl: string
  animationUrl: string
  name: string
  displayName: string
  chain: string
}

type CompositeAsset = AssetType & Web3Asset // join component library asset type w/ web3 asset type
type UserAssetCardProps = { asset: CompositeAsset; connectedAccount: { data: any; chain: any } }

export const UserAssetCard: React.FC<UserAssetCardProps> = ({ asset, handleSelectAsset }) => {
  const generateAssetsByWalletCount = () =>
    AppState.getCurrentUser()?.web3Accounts.map(walletAddress => ({
      address: walletAddress,
      owned: transferableAssetCount(walletAddress),
    }))

  const transferableAssetCount = (walletAddress: string) => {
    // handle checksummed address as well as all lower case address (BE is citext)
    return asset.ownershipDetail[walletAddress] || asset.ownershipDetail[walletAddress?.toLowerCase()] || 0
  }

  const assetsByWalletCountArr = generateAssetsByWalletCount()
  const totalAssetsOwnedByUser = assetsByWalletCountArr.reduce((total, wallet) => total + wallet.owned, 0)

  const popoverContent = assetsByWalletCountArr.map(wallet => (
    <Typography sx={{ color: 'primary.dark' }}>
      <CryptoLink
        address={wallet.address}
        network={asset.network}
        sx={{ color: 'primary.dark', textDecoration: 'underline' }}
      />
      {` owns ${wallet.owned}`}
    </Typography>
  ))

  const subtitle = { content: `${totalAssetsOwnedByUser} Owned`, popover: popoverContent }
  const is721 = asset.contractStandard === 'ERC721'

  return (
    <Card
      sx={{
        maxWidth: '100%',
        paddingTop: 0,
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        cursor: 'pointer',
        color: colors.grey40,
        '&:hover': {
          backgroundColor: colors.grey10,
          color: colors.grey90,
          transition: 'background-color 0.3s ease-out',
          '.assetInfo': {
            color: colors.grey90,
          },
          '.assetTitle': {
            color: colors.grey90,
          },
        },
      }}
      onClick={() => handleSelectAsset(asset.id)}
    >
      <CardMedia>
        <AssetImage asset={asset} onlyImage />
      </CardMedia>
      <CardContent>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          {/* Owners Popover */}
          {is721 ? (
            <Typography sx={{ color: 'text.secondary' }} className='assetInfo'>
              TOKEN ID: {asset.tokenId}
            </Typography>
          ) : (
            <Popover
              anchor={
                <Typography sx={{ cursor: 'pointer' }} className='assetInfo'>
                  {subtitle.content}
                </Typography>
              }
              popoverContent={
                <Stack spacing={0.5} p={0.5}>
                  {subtitle.popover}
                </Stack>
              }
            />
          )}
          <CryptoChainBadge chain={asset.chain} />
        </Stack>
        <Typography mt={2} variant='h4' color='primary.main' textTransform='uppercase' className='assetTitle'>
          {assetTitle(asset)}
        </Typography>
      </CardContent>
    </Card>
  )
}
