import { createTheme, responsiveFontSizes } from '@mui/material/styles'

// interface ButtonStyleOverrides extends Partial<OverridesStyleRules<keyof ButtonClasses, 'MuiButton'>> {
//   modalContained?: React.CSSProperties
//   modalContainedSecondary?: React.CSSProperties
// }

declare module '@mui/material/styles' {
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties
  }
  interface ButtonVariantsOptions {
    small?: React.CSSProperties
    containedSecondary?: React.CSSProperties
    modalContained?: React.CSSProperties
    modalContainedSecondary?: React.CSSProperties
    modalGhost?: React.CSSProperties
    modalDanger?: React.CSSProperties
    default?: React.CSSProperties
    walletConnect?: React.CSSProperties
  }
  interface BreakpointOverrides {
    xxs: true
    xxl: true
    lgModal: true
  }
}

// Custom Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true
  }
}

// Custom Button variant prop options
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    small: true
    containedSecondary: true
    modalContained: true
    modalContainedSecondary: true
    modalGhost: true
    modalDanger: true
    walletConnect: true
    default: true
  }
}

const fonts = {
  exo: 'Exo, sans-serif',
  orbitron: 'Orbitron, sans-serif',
}

const commonFontStyle = {
  fontSize: '1rem',
  fontWeight: 400,
  letterSpacing: '0.06rem',
  lineHeight: '1.25rem',
}

export const colors = {
  green30: '#6FDC8C',
  green40: '#42BE65',
  green90: '#022D0D',
  yellow30: '#F1C21B',
  yellow40: '#D2A106',
  yellow50: '#B28600',
  yellow90: '#302400',
  red50: '#FA4D56',
  red60: '#DA1E28',
  red70: '#A2191F',
  red80: '#750E13',
  red90: '#520408',
  red100: '#2D0709',
  grey10: '#F7F3F2',
  grey30: '#CAC5C4',
  grey40: '#ADA8A8',
  grey50: '#8F8B8B',
  grey60: '#726E6E',
  grey70: '#565151',
  grey80: '#3C3838',
  grey90: '#272525',
  grey100: '#171414',
  teal20: '#9EF0F0',
  teal30: '#3DDBD9',
  teal90: '#022B30',
  transparent: '#00000000',
}

const getNotchedButtonStyle = (backgroundColor: string, borderColor: string) => ({
  clipPath: 'polygon(0% 0%, 100% 0%, 100% calc(100% - 8.5px), calc(100% - 8.5px) 100%, 0% 100%)',
  background: `linear-gradient(to bottom right, ${backgroundColor} 49.75%, ${borderColor} 49.5%) bottom right / 11px 11px no-repeat border-box`,
  border: `2px solid ${borderColor}`,
  backgroundColor: backgroundColor,
})

const getButtonStyle = (backgroundColor: string, borderColor: string) => ({
  border: `2px solid ${borderColor}`,
  backgroundColor: backgroundColor,
})

const commonModalButtonStyle = {
  minHeight: '4rem',
  width: '100%',
  alignItems: 'flex-start',
}

const getAlertStyle = (backgroundColor: string, color: string) => ({
  backgroundColor,
  color,
  borderLeft: `3px solid ${color}`,
})

export const devices = {
  // Screen sizes.
  xxs: 0,
  xs: 428,
  sm: 768,
  md: 1280,
  lg: 1440,
  xl: 1920,
  xxl: 2560,
  // Component sizes.
  lgModal: 960,
}

let customTheme = createTheme({
  // ...defaultTheme,
  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    values: devices,
  },
  components: {
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          backgroundColor: 'unset',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width: 1280px)': {
            'padding-left': '4rem',
            'padding-right': '4rem',
          },
          '@media (min-width: 1920px)': {
            'padding-left': '10rem',
            'padding-right': '10rem',
          },
        },
      },
    },
    // Button.
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          display: 'flex',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          justifyContent: 'flex-start',
          color: colors.grey10,
          padding: '0.75rem 1rem',
          textTransform: 'uppercase',
          width: 'fit-content',
          transition: 'none',
          '&:hover': getButtonStyle(colors.grey70, colors.grey70),
          '&:focus': getButtonStyle(colors.grey80, colors.grey10),
          '&:hover:focus': getButtonStyle(colors.grey70, colors.grey10),
          '&:hover:focus:active': getButtonStyle(colors.grey80, colors.grey10),
          '&.Mui-disabled': {
            color: colors.grey50,
          },
        },
        // @ts-ignore
        default: {
          fontWeight: 500,
          fontSize: '1.5rem',
        },
        contained: {
          ...getNotchedButtonStyle(colors.red60, colors.red60),
          '&:hover': getNotchedButtonStyle(colors.red70, colors.red70),
          '&:focus': getNotchedButtonStyle(colors.red60, colors.grey10),
          '&:hover:focus': getNotchedButtonStyle(colors.red70, colors.grey10),
          '&:hover:focus:active': getNotchedButtonStyle(colors.red80, colors.grey10),
          '&.Mui-disabled': { ...getNotchedButtonStyle(colors.grey70, colors.grey70), color: colors.grey50 },
        },
        containedSecondary: {
          ...getNotchedButtonStyle(colors.grey60, colors.grey60),
          '&:hover': getNotchedButtonStyle(colors.grey70, colors.grey70),
          '&:focus': getNotchedButtonStyle(colors.grey60, colors.grey10),
          '&:hover:focus': getNotchedButtonStyle(colors.grey70, colors.grey10),
          '&:hover:focus:active': getNotchedButtonStyle(colors.grey80, colors.grey10),
          '&.Mui-disabled': { ...getNotchedButtonStyle(colors.grey70, colors.grey70), color: colors.grey50 },
        },
        outlined: {
          ...getNotchedButtonStyle(colors.transparent, colors.grey10),
          '&:hover': { ...getNotchedButtonStyle(colors.grey10, colors.grey10), color: colors.grey100 },
          '&:focus': { ...getNotchedButtonStyle(colors.grey10, colors.grey10), color: colors.grey100 },
          '&:hover:focus': { ...getNotchedButtonStyle(colors.grey10, colors.grey10), color: colors.grey100 },
          '&:hover:focus:active': { ...getNotchedButtonStyle(colors.grey30, colors.grey10), color: colors.grey100 },
          '&.Mui-disabled': { ...getNotchedButtonStyle(colors.transparent, colors.grey60), color: colors.grey60 },
        },
        modalContained: {
          ...commonModalButtonStyle,
          border: `2px solid ${colors.red60}`,
          backgroundColor: colors.red60,
          '&:hover': getButtonStyle(colors.red70, colors.red70),
          '&:focus': getButtonStyle(colors.red60, colors.grey10),
          '&:hover:focus': getButtonStyle(colors.red70, colors.grey10),
          '&:hover:focus:active': getButtonStyle(colors.red80, colors.grey10),
          '&.Mui-disabled': getButtonStyle(colors.grey70, colors.grey70),
        },
        modalDanger: {
          ...commonModalButtonStyle,
          color: colors.grey100,
          border: `2px solid ${colors.yellow30}`,
          backgroundColor: colors.yellow30,
          '&:hover': getButtonStyle(colors.yellow40, colors.yellow40),
          '&:focus': getButtonStyle(colors.yellow30, colors.grey90),
          '&:hover:focus': getButtonStyle(colors.yellow40, colors.grey90),
          '&:hover:focus:active': getButtonStyle(colors.yellow50, colors.grey10),
          '&.Mui-disabled': getButtonStyle(colors.grey70, colors.grey70),
        },
        modalContainedSecondary: {
          ...commonModalButtonStyle,
          border: `2px solid ${colors.grey60}`,
          backgroundColor: colors.grey60,
          '&:hover': getButtonStyle(colors.grey70, colors.grey70),
          '&:focus': getButtonStyle(colors.grey60, colors.grey10),
          '&:hover:focus': getButtonStyle(colors.grey70, colors.grey10),
          '&:hover:focus:active': getButtonStyle(colors.grey80, colors.grey10),
          '&.Mui-disabled': getButtonStyle(colors.grey70, colors.grey70),
        },
        modalGhost: {
          ...commonModalButtonStyle,
          border: `2px solid ${colors.transparent}`,
          backgroundColor: colors.transparent,
          color: colors.teal30,
          '&:hover': getButtonStyle(colors.grey80, colors.grey80),
          '&:focus': getButtonStyle(colors.transparent, colors.grey10),
          '&:hover:focus': getButtonStyle(colors.grey90, colors.grey10),
          '&:hover:focus:active': getButtonStyle(colors.grey80, colors.grey10),
          '&.Mui-disabled': getButtonStyle(colors.grey70, colors.grey70),
        },
        small: {
          alignItems: 'center',
          justifyContent: 'center',
          border: `2px solid ${colors.grey60}`,
          backgroundColor: colors.grey60,
          '&:hover': getButtonStyle(colors.grey70, colors.grey70),
          '&:focus': getButtonStyle(colors.grey60, colors.grey10),
          '&:hover:focus': getButtonStyle(colors.grey70, colors.grey10),
          '&:hover:focus:active': getButtonStyle(colors.grey80, colors.grey10),
          '&.Mui-disabled': getButtonStyle(colors.grey70, colors.grey70),
        },
        text: {
          border: `2px solid ${colors.transparent}`,
          padding: 0,
          backgroundColor: colors.transparent,
          color: colors.teal30,
          '&:hover': {
            color: colors.teal20,
            backgroundColor: colors.transparent,
            border: `2px solid ${colors.transparent}`,
          },
          '&:focus': getButtonStyle(colors.transparent, colors.grey10),
          '&:hover:focus': getButtonStyle(colors.transparent, colors.grey10),
          '&:hover:focus:active': getButtonStyle(colors.transparent, colors.grey10),
          '&.Mui-disabled': getButtonStyle(colors.transparent, colors.grey70),
        },
        walletConnect: {
          border: `2px solid ${colors.transparent}`,
          borderRadius: '5px',
          width: '100%',
          fontFamily: 'Inter',
          backgroundColor: colors.grey10,
          color: colors.grey100,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'underline',
          color: colors.teal30,
          border: `2px solid ${colors.transparent}`,
          '&:hover': {
            color: colors.teal20,
            textDecoration: 'none',
          },
          '&:focus': getButtonStyle(colors.transparent, colors.grey10),
          '&:hover:focus': getButtonStyle(colors.transparent, colors.grey10),
          '&:hover:focus:active': getButtonStyle(colors.transparent, colors.grey10),
          '&.Mui-disabled': getButtonStyle(colors.transparent, colors.grey70),
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '20px',
          height: '20px',
          borderRadius: 0,
          marginRight: '8px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: { alignItems: 'center' },
        standardInfo: getAlertStyle(colors.teal90, colors.teal30),
        standardSuccess: getAlertStyle(colors.green90, colors.green30),
        standardWarning: getAlertStyle(colors.yellow90, colors.yellow30),
        standardError: getAlertStyle(colors.yellow90, colors.yellow30),
        message: {
          wordBreak: 'break-word', // helps with long error messages
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderStyle: 'dashed',
        },
      },
    },
    // Text field.
    MuiFormControl: {
      styleOverrides: {
        root: {
          minHeight: '6.2rem', // Makes height consistent with and without error message.
          paddingTop: '1.8rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: colors.grey80,
          textTransform: 'uppercase',
          caretColor: colors.teal30,
        },
        adornedEnd: {
          backgroundColor: colors.grey80,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          '& fieldset': {
            border: 'none',
            borderBottom: `1px solid ${colors.grey60}`,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderColor: 'none',
            borderBottom: `1px solid ${colors.grey60}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.grey10}`,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.yellow30}`,
          },
        },
        input: {
          padding: '0.75rem 1rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          transform: 'translateY(0.5rem)',
          '&.Mui-error': {
            color: colors.grey40,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          marginLeft: 0,
          textTransform: 'uppercase',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.red50,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          wordBreak: 'break-word', // helps with long error messages
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey100,
          borderBottom: `1px solid ${colors.grey80}`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.red50,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '14px 18px',
          '&.Mui-selected': {
            backgroundImage: `linear-gradient(${colors.transparent} 10%, ${colors.red80} 100%)`,
          },
          '&:hover': {
            color: colors.grey10,
          },
          '&:focus-visible': {
            padding: '12px 16px',
            border: `2px solid ${colors.grey10}`,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          m: 0,
        },
        paper: {
          '@media (max-width: 1280px)': {
            margin: 0,
            width: '100%',
          },
          '@media (max-width: 768px)': {
            maxHeight: 'unset',
            height: '100%',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey90,
          backgroundImage: 'none',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.grey10,
      contrastText: colors.grey10,
    },
    secondary: {
      main: colors.teal30,
      contrastText: colors.grey60,
    },
    background: {
      default: colors.grey100,
      paper: colors.grey100,
      // secondary: colors.grey80,
    },
    error: {
      main: colors.yellow30,
    },
    info: {
      main: colors.teal30,
    },
    success: {
      main: colors.green40,
    },
    text: {
      secondary: colors.grey40,
    },
  },
  typography: {
    fontFamily: fonts.orbitron,
    fontSize: 16,
    allVariants: commonFontStyle,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h4: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
    h6: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    h7: {
      ...commonFontStyle, // need to add common font styles because it's not a default variant
      fontFamily: fonts.orbitron,
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    body2: {
      fontFamily: fonts.exo,
    },
  },
})

// Responsive typography
customTheme.typography.h1 = {
  fontWeight: 800,
  fontSize: '2.5rem',
  lineHeight: '3rem',
  [customTheme.breakpoints.down('sm')]: {
    fontSize: '1.875rem',
    lineHeight: '2rem',
  },
}

customTheme.typography.h2 = {
  fontWeight: 800,
  fontSize: '2rem',
  lineHeight: '2.5rem',
  [customTheme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
}

customTheme.typography.h3 = {
  fontWeight: 500,
  fontSize: '1.5rem',
  lineHeight: '2rem',
  [customTheme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
}

export default customTheme
