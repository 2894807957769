// Map for finding the correct chain name for the wagmi api.
const chains = {
  polygon: {
    mainnet: 'matic',
    mumbai: 'maticmum',
  },
  ethereum: {
    mainnet: 'homestead',
    goerli: 'goerli',
  },
}

export const checkIfSupportedNetwork = (activeChain, assetChain, assetNetwork) => {
  // We use network and chain to distinguish between different chains and network and wagmi uses just a single name.
  const standardizedAssetNetwork = chains[assetChain][assetNetwork]

  // If assetChain is provided, check if activeChain is the same as assetChain.
  return standardizedAssetNetwork === activeChain
}

export const getCookie = (cookieName: string) => {
  const cookies = {}
  document.cookie.split('; ').forEach(cookie => {
    const [key, value] = cookie.split('=')
    cookies[key] = value
  })
  return cookies[cookieName] || null
}

export const getEnvironment = () => {
  const host = window.location.host
  switch (true) {
    case host.includes('atmos.xyz'):
      return 'prod'
    case host.includes('atmos-dash-staging'):
      return 'staging'
    case host.includes('atmos-dash-dev'):
      return 'dev'
    default:
      return 'local'
  }
}

export const normalizeWalletError = (errorMessage: string) => {
  const error = errorMessage.toLowerCase()
  if (error.includes('mint limit reached')) return 'Mint limit reached.'
  if (error.includes('user rejected transaction')) return 'Transaction rejected.'
  return errorMessage
}
