import React, { useState, useEffect } from 'react'
import { useListContext, FilterListItem as RaFilterListItem, Count } from 'react-admin'
import { Box, Typography, Stack, useMediaQuery, Theme, Drawer, IconButton } from '@mui/material'
import { Switch } from '@atmos/components/Switch'
import { Button } from '@atmos/components/buttons/Button'
import AppState, { UserUpdateEvent } from '@core/dash/app_state'
import CloseIcon from '@mui/icons-material/Close'
import { COLLECTIONS } from '@core/dash/resources/user/home/show'
import { cloneDeep } from 'lodash'
import { User } from 'shared/types'
import { useLocation } from 'react-router-dom'
import PubSub from 'pubsub-js'
import { isEqual } from 'lodash'

export const AssetFilterSidebar = ({ collections, isFilterOpen, toggleFilter }) => {
  const [showOnlyOwned, setShowOnlyOwned] = useState(true)
  const { filterValues, setFilters, displayedFilters, setPerPage } = useListContext()
  const ownerIds = AppState.getCurrentUser()?.web3Accounts
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const isFilterPersistent = isDesktop || isTablet
  const drawerWidth = isDesktop ? 400 : isTablet ? 320 : '100%'
  const { search } = useLocation()

  // Mui switch passes the event as the first argument and the value as the second.
  const toggleShowOnlyOwned = (_, isOnlyOwned) => {
    const filters = { ...filterValues, ownerIds, ownershipEnforced: isOnlyOwned }
    setFilters(filters, displayedFilters)
  }

  const exordiumKeys = Object.keys(COLLECTIONS.exordium)
  const collectiblesKeys = Object.keys(COLLECTIONS.collectibles)
  // This is a temporary solution to add names to collections and group them by category.
  // Those fields should be added in the Admin panel and this code should be removed.
  const accumulator = cloneDeep(COLLECTIONS)
  const displayCollections = collections.reduce((acc, collection) => {
    if (exordiumKeys.includes(collection.openseaKey)) {
      acc.exordium[collection.openseaKey] = {
        ...acc.exordium[collection.openseaKey],
        id: collection.id,
      }
    } else if (collectiblesKeys.includes(collection.openseaKey)) {
      acc.collectibles[collection.openseaKey] = {
        ...acc.collectibles[collection.openseaKey],
        id: collection.id,
      }
    } else {
      return acc
    }
    return acc
  }, accumulator)

  useEffect(() => {
    // Check if there are filters in the URL params. If there are no filters, clear the filters.
    if (!search) {
      clearFilters()
    }

    // If user deletes their wallet from a different page ownerIds in URL params will be out of sync.
    // This will update the ownerIds in URL params to match the current user's ownerIds from AppState.
    const ownerIdsMismatched = !isEqual(ownerIds, filterValues.ownerIds)
    if (filterValues.ownershipEnforced && ownerIdsMismatched) {
      setFilters({ ...filterValues, ownerIds }, displayedFilters)
    }

    // Cleat localStorage when unmounting.
    return () => {
      localStorage.setItem('RaStore.user/asset.listParams', '')
    }
  }, [])

  useEffect(() => {
    // Set toggle to true if there are ownerIds filters.
    if (filterValues.ownershipEnforced) {
      setShowOnlyOwned(true)
    } else {
      setShowOnlyOwned(false)
    }
  }, [filterValues])

  useEffect(() => {
    const setOwnerFilters = (_, user: User) => {
      if (filterValues.ownershipEnforced) {
        setFilters({ ...filterValues, ownerIds: user?.web3Accounts || [] }, displayedFilters)
      }
    }

    const subscriptionToken = PubSub.subscribe(UserUpdateEvent, setOwnerFilters)
    return () => PubSub.unsubscribe(subscriptionToken)
  }, [filterValues])

  const clearFilters = () => {
    setFilters({}, displayedFilters)
    setShowOnlyOwned(false)
  }

  return (
    <Drawer
      variant={isFilterPersistent ? 'persistent' : 'temporary'}
      anchor='left'
      open={isFilterPersistent || isFilterOpen}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          position: isFilterPersistent ? 'relative' : 'fixed',
          p: 2,
          pr: 0,
        },
      }}
    >
      <Stack direction='row' justifyContent='flex-end' p={1} display={isFilterPersistent ? 'none' : 'flex'}>
        <IconButton onClick={toggleFilter}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack spacing={2}>
        <Switch checked={showOnlyOwned} onChange={toggleShowOnlyOwned} />
        <Button onClick={clearFilters} label='CLEAR FILTERS' variant='text' />
        <Box>
          <Typography variant='h7' color='text.secondary' ml='2px'>
            COLLECTIBLES
          </Typography>
          {!!collections.length &&
            collectiblesKeys.map(key => (
              <FilterListItem collection={displayCollections.collectibles[key]} filterValues={filterValues} />
            ))}
        </Box>
        <Box>
          <Typography variant='h7' color='text.secondary' ml='2px'>
            EXORDIUM
          </Typography>
          {!!collections.length &&
            exordiumKeys.map(key => (
              <FilterListItem collection={displayCollections.exordium[key]} filterValues={filterValues} />
            ))}
        </Box>
      </Stack>
    </Drawer>
  )
}

const FilterListItem = ({ collection, filterValues }) => {
  if (!collection.id) return null

  const label = (
    <Stack direction='row' alignItems='center' justifyContent='space-between' pr={5} pl={1}>
      <Typography>{collection.name}</Typography>
      <Count filter={{ ...filterValues, collectionId: collection.id }} variant='body1' />
    </Stack>
  )

  return (
    <RaFilterListItem
      label={label}
      value={{ collectionId: collection.id }}
      sx={{
        '& .MuiButtonBase-root': {
          px: 0,
        },
      }}
    />
  )
}
