import React, { FC } from 'react'
import { Box } from '@mui/material'

interface CryptoChainBadgeProps {
  chain: string
}

const chainIcon = {
  ethereum: 'ethereum-dark',
  polygon: 'polygon-purple',
  imtbl_zkevm: 'immutable_passport-dark'
}

export const CryptoChainBadge: FC<CryptoChainBadgeProps> = ({ chain }) => {
  const imgSrc = `images/social/${chainIcon[chain]}.svg`

  if (!chain) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'primary.main',
        borderRadius: '50%',
        padding: '2px',
      }}
    >
      <img width='20' height='20' src={imgSrc} />
    </Box>
  )
}
