import * as React from 'react'
// import { AvatarIcon } from '../../dash/layout/AppBar/Avatar'
// import AvatarIcon from '../../dash/layout/CustomLayout/Avatar.svg'
import { Box } from '@mui/material'
// import MediaSelector from 'core/media/components/MediaSelector'
// import LibUserAvatar from 'react-user-avatar'
// import Avatar from 'react-avatar';

// const defaultPalette = [
// 	"#9ABF06", // olive-ish
// 	"#2E99B0", // cyan
// 	"#9F73FF", // ultralight purple
// 	"#F23D68", // pink
// 	"#FFBE5C", // mustard
// 	"#4AD9A5", // light green
// ]

// Separate palette with various grey tones
const defaultPalette = ['#eeeeee', '#dddddd', '#cccccc', '#bbbbbb', '#aaaaaa']

export interface UserAvatarProps {
  name: string

  src?: string
  srcset?: string

  color?: string
  colors?: string[]

  size?: number
  borderRadius?: string | number

  style?: React.StyleHTMLAttributes<HTMLDivElement> & React.CSSProperties
}

export const UserAvatar: React.FC<UserAvatarProps> = props => {
  return (
    <Box
      sx={{
        backgroundColor: '#dfdcce',
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src='/images/user-img.png' style={{ height: '24px' }}></img>
    </Box>
  )
}
