import { Stack, Box, useMediaQuery, Theme } from '@mui/material'
import { useAuthProvider } from './useAuthProvider'
import { DashAuthView } from './DashAuthView'
import { LogoutButton } from './logout'
import { Logo } from 'shared/atoms'

const AuthWrapper = ({ component: Component, ...props }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const onLoad = e => (e.currentTarget.style.opacity = '1')
  return (
    <Box className={`embed-container ${isDesktop ? '' : 'starfield-bg'}`}>
      {isDesktop && (
        <iframe
          src='https://player.vimeo.com/video/801706926?background=1&transparent=0'
          onLoad={onLoad}
          allow='autoplay; fullscreen; picture-in-picture; encrypted-media;'
          allowFullScreen
        ></iframe>
      )}
      <Box p='1rem' sx={{ position: 'absolute' }}>
        <Logo />
      </Box>
      <Stack
        alignItems='flex-start'
        p={{ xxs: 2, sm: 4, md: 12, lg: 20 }}
        py={{ xxs: 1, sm: 2, md: 8, lg: 12 }}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Component {...props} />
      </Stack>
    </Box>
  )
}

const LoginWrapper = props => <AuthWrapper {...props} component={DashAuthView} />

export default class DashAuth {
  static Login = LoginWrapper
  static LogoutButton = LogoutButton
  static useAuthProvider = useAuthProvider
}
