import { FC, useEffect, useState } from 'react'
import {
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useRedirect } from 'react-admin'
import AppState from 'dash/app_state'
import { Button } from '@atmos/components/buttons/Button'
import { getCookie } from '@atmos/components/utils'
import Pusher from 'pusher-js'

import { gql } from '@apollo/client'
import apolloClient from 'clients/apollo'

import { WalletConnectButtons } from '@atmos/components/Web3ConnectButton/WalletConnectButtons'
import { Spinner } from '@atmos/components/Spinner'
import { WalletActionType } from '@core/shared/types'
import { RegistrationForm } from '@atmos/components/Web3ConnectButton/RegistrationForm'
import { Analytics, AnalyticsEvents } from '@core/dash/utils/analytics'

interface UpdateUserAccountInfo {
  userId: string
  email: string
  callsign: string
  touAccepted: boolean
  showEmailConfirmation: () => void
  displayError: (error: string) => void
  referralKey?: string
}

const UPDATE_ACCOUNT_INFO = gql`
  mutation userRegistration(
    $id: ID!
    $email: String!
    $username: String!
    $touAccepted: Boolean!
    $referralKey: String
  ) {
    userRegistration(
      id: $id
      email: $email
      username: $username
      touAccepted: $touAccepted
      referralKey: $referralKey
    ) {
      user {
        id
      }
      errors
    }
  }
`

const updateUserAccount = async ({
  userId,
  email,
  callsign,
  touAccepted,
  showEmailConfirmation,
  displayError,
  referralKey,
}: UpdateUserAccountInfo) => {
  try {
    const result = await apolloClient.mutate({
      mutation: UPDATE_ACCOUNT_INFO,
      variables: { id: userId, email, username: callsign, touAccepted, referralKey },
    })
    // Response errors.
    const errors = result.data.userRegistration.errors
    // Proceed to email confirmation if no errors.
    errors.length ? displayError(errors[0]) : showEmailConfirmation()
  } catch (e) {
    console.error('Error caught:', e)
    displayError('Unknown error occured')
  }
}

const EmailSentView = ({ userId, email, onBackClick, onEmailConfirmed }) => {
  // Open web socket connecttion on mount and close on unmount.
  useEffect(() => {
    console.log('initing pusher')
    Analytics.publishEvent(AnalyticsEvents.UserAuthStart)
    const pusher = new Pusher(process.env.PUSHER_KEY, {
      cluster: process.env.PUSHER_CLUSTER,
    })

    pusher.connection.bind('error', function (err) {
      console.error('pusher err', err)
      if (err.error.data.code === 4004) {
        console.error('Pusher Over limit!')
      }
    })

    const channel = pusher.subscribe(userId)

    channel.bind('confirmable', function (data) {
      if (data['confirmed'] == true) {
        Analytics.publishEvent(AnalyticsEvents.UserRegister)
        onEmailConfirmed()
      }
    })

    return () => {
      console.log('disconnect pusher')

      pusher.disconnect()
    }
  }, [])

  return (
    <Stack spacing={2} alignItems='center'>
      <Typography textAlign='center'>A confirmation link has been sent to</Typography>
      <Typography variant='h2'>{email}</Typography>
      <Typography textAlign='center'>Please check your email and click the verification link.</Typography>
      <Spinner style={{ margin: '35px 0 35px 0' }} />
      <Button variant='outlined' label='back' onClick={onBackClick} />
    </Stack>
  )
}

const CompleteView = ({ callback }) => {
  if (typeof callback !== 'undefined')
    setTimeout(() => {
      callback()
    }, 3000)

  return (
    <Stack spacing={2}>
      <Typography variant='h1' component='h3' textAlign='center'>
        Account Confirmed
      </Typography>
      <Typography variant='h3' textAlign='center'>
        Taking you to the Portal Now
      </Typography>
    </Stack>
  )
}

type UserOnboardingProps = {
  onSumbit?: (wallets: string[], callsign: string, email: string) => void
  onLoginSuccess?: () => void
  onRegistrationSuccess?: () => void
  setIsModalVisible: (isVisible: boolean) => void
  walletActionType?: WalletActionType
}

// Used for creating a new user account, adding a wallet to an existing account, or logging in.
const UserAuthFlow: FC<UserOnboardingProps> = ({
  onLoginSuccess,
  onRegistrationSuccess,
  setIsModalVisible,
  walletActionType,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [userId, setUserId] = useState('')
  const [email, setEmail] = useState('')

  const onWalletVerified = () => {
    setCurrentStep(currentStep === 0 ? 1 : currentStep)
  }

  const onSignUpClick = async (formData: { email: string; callsign: string; touAccepted: boolean }) => {
    const { email, callsign, touAccepted } = formData
    // Get referral key from the cookie if it is present.
    const referralKey = getCookie('_core_referral_key')

    setEmail(email)

    await updateUserAccount({
      userId,
      email,
      callsign,
      touAccepted,
      showEmailConfirmation,
      displayError,
      referralKey,
    })
  }

  const showEmailConfirmation = () => {
    setErrorMessage('')
    setCurrentStep(2)
  }

  // Displays registration error message.
  const displayError = (errorMessage: any) => {
    if (typeof errorMessage === 'string') {
      setErrorMessage(errorMessage)
    } else {
      setErrorMessage('Unknown error occured')
    }
  }
  const onBackClick = () => {
    setCurrentStep(1)
  }

  const steps = [
    {
      title: 'LINK WALLET',
      content: (
        <WalletConnectButtons
          setUserId={setUserId}
          onWalletVerified={onWalletVerified}
          onLoginSuccess={onLoginSuccess}
          setIsModalVisible={setIsModalVisible}
          walletActionType={walletActionType}
        />
      ),
    },
    {
      title: 'CREATE ACCOUNT',
      content: <RegistrationForm onRegister={onSignUpClick} displayError={displayError} errorMessage={errorMessage} />,
    },
    {
      title: 'VERIFYING COMMS CHANNEL',
      content: (
        <EmailSentView
          userId={userId}
          email={email}
          onBackClick={onBackClick}
          onEmailConfirmed={() => setCurrentStep(3)}
        />
      ),
    },
    {
      title: 'COMPLETE',
      content: <CompleteView callback={onRegistrationSuccess} />,
    },
  ]

  // only init once

  return (
    <>
      <DialogTitle display='flex' justifyContent='space-between' sx={{ px: 2 }}>
        <Typography variant='h2'>{steps[currentStep].title}</Typography>
        <IconButton onClick={() => setIsModalVisible(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 2, pb: 3 }}>
        <Typography color='text.secondary' mb={2}>
          To use a different account, please select it from your wallet now
        </Typography>
        {steps[currentStep].content}
      </DialogContent>
    </>
  )
}

export interface ConnectButtonProps extends MuiButtonProps {
  disabled?: boolean
  showArrow?: boolean
  walletActionType?: WalletActionType
  onClickCallback?: () => void
  label?: string
}

const ConnectButton: FC<ConnectButtonProps> = ({
  disabled,
  variant,
  label = 'Link Wallet',
  showArrow = true,
  walletActionType,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const redirect = useRedirect()

  const launchModal = () => {
    setIsModalVisible(true)
  }

  const onLoginSuccess = () => {
    if (!!AppState.getLastPage()) redirect(AppState.getLastPage())
    else redirect('/')
  }

  const onRegistrationSuccess = () => {
    redirect('/')
  }

  return (
    <>
      <Button variant={variant} onClick={launchModal} disabled={disabled} label={label} showArrow={showArrow} />
      <Dialog open={isModalVisible} maxWidth='sm' keepMounted>
        <UserAuthFlow
          onLoginSuccess={onLoginSuccess}
          onRegistrationSuccess={onRegistrationSuccess}
          setIsModalVisible={setIsModalVisible}
          walletActionType={walletActionType}
        />
      </Dialog>
    </>
  )
}

export default ConnectButton
