import { useContext } from 'react';

import { PassportContext } from './PassportContext'
import { generateFetchRequest } from 'clients/fetch'
import { connectWeb3Identity } from '../../dash/utils/connectWeb3Identity';
import { hydrateCurrentUser } from '@core/dash/utils/hydrateCurrentUser';

export const usePassport = () => {
  return useContext(PassportContext);
}

export const usePassportLogout = () => {
  const { passportInstance, isLoading } = usePassport()

  const logout = async () => {
    try {
      await passportInstance.logout()
    } catch (error) {
      console.error('passport.logout error', error)
    }
  }

  return {
    logout: isLoading ? null : logout,
    isLoading: isLoading
  }
}

export const usePassportLogin = () => {
  const { passportInstance, isLoading } = usePassport()

  const login = async ({ onSuccess, onError }: { onSuccess?: (data: any) => void; onError?: (err: any) => void }) => {
    try {
      const userInfo = await passportInstance.login()
      const { email, sub: uid, nickname } = userInfo

      const request = generateFetchRequest({
        headers: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: 'POST',
        path: 'users/auth/immutable_passport/callback',
        body: JSON.stringify({
          omniauth: {
            provider: 'immutable_passport',
            uid,
            info: { email, nickname },
          },
        }),
      })

      const result = await fetch(request)
      const data = await result.json()
      if (result.ok) {
        if (onSuccess) onSuccess(data)
        else console.log('connect success', data)
      } else throw new Error(data.errors || data.error || 'error occurred on passport login')
    } catch (error) {
      if (onError) onError(error)
      else console.error('passport.login error', error)
    }
  }

  return {
    login: isLoading ? null : login,
    isLoading: isLoading
  }
}

export const usePassportConnectAccounts = () => {
  const { passportProvider, isLoading } = usePassport()

  const connectAccounts = async () => {
    const accounts = await passportProvider.request({ method: 'eth_requestAccounts' });

    if (accounts?.length) {
      for (const account of accounts) {
        try {
          await connectWeb3Identity(account, { provider: 'immutable_passport', type: 'account' })
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  return {
    connectAccounts: isLoading ? null : connectAccounts,
    isLoading: isLoading
  }
}

export const usePassportConnectLinkedAddresses = () => {
  const { passportInstance, isLoading } = usePassport()

  const connectLinkedAddresses = async () => {
    // NOTE: this connects each account individually so that we can use
    // the exacting BE logic for a web3 identity connect.
    // This could be refactored into a single call in the future.
    const linkedAddresses = await passportInstance.getLinkedAddresses();

    if (linkedAddresses?.length) {
      for (const linkedAddress of linkedAddresses) {
        try {
          await connectWeb3Identity(linkedAddress, { provider: 'immutable_passport', type: 'linked_address' })
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  return {
    connectLinkedAddresses: isLoading ? null : connectLinkedAddresses,
    isLoading: isLoading
  }
}

export const usePassportConnectWeb3Identities = () => {
  const { isLoading } = usePassportLogin()

  const { connectAccounts: connectPassportAccounts } = usePassportConnectAccounts()
  const { connectLinkedAddresses: connectPassportLinkedAddresses } = usePassportConnectLinkedAddresses()

  const connectWeb3Identities = async () => {
    try {
      await connectPassportAccounts()
      await connectPassportLinkedAddresses()
    } catch (e) {
      console.error('Failed to connect passport wallets', e)
    }

    // hydrate current user regardless of successful connection(s) of account(s) / linked address(es)
    try {
      await hydrateCurrentUser()
    } catch (e) {
      console.error('Failed to hydrate current user', e)
    }
  }

  return {
    connectWeb3Identities: isLoading ? null : connectWeb3Identities,
    isLoading: isLoading
  }
}