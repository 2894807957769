import _Helika, { DisableDataSettings } from 'helika-sdk'
import { Analytics, AnalyticsEvents } from './analytics'

// Define the HelikaEvents enum
enum HelikaEvents {
  UserRegister = 'user_register',
  UserLogin = 'user_login',
}

// Define the HelikaEventSubTypes enum
enum HelikaEventSubTypes {
  Register = 'register',
  Login = 'login',
}

// Define the HelikaSingleton class
class Helika {
  private static instance: Helika
  private helika: any

  private constructor() {
    this.helika = new _Helika.EVENTS(
      window.HELIKA_API_KEY,
      window.HELIKA_GAME_ID,
      parseInt(window.HELIKA_ENV)
    )

    this.helika.setDataSettings(DisableDataSettings.None)
    this.helika.startSession()

    Analytics.subscribeToEvent((msg, data) => this.handleEvent(msg, data))
  }

  static getInstance(): Helika {
    if (!Helika.instance) {
      Helika.instance = new Helika()
    }
    return Helika.instance
  }

  async createEvent(events: any) {
    try {
      this.helika.createEvent(events)
    } catch (error) {
      console.error('Error creating event', error)
    }
  }

  logUserRegisterEvent(userData: { id: string; email: string }) {
    const events = [
      {
        event_type: HelikaEvents.UserRegister,
        event: {
          event_sub_type: HelikaEventSubTypes.Register,
          user_id: userData.id,
          email: userData.email,
        },
      },
    ]
    this.createEvent(events)
  }

  logUserLoginEvent(userData: { id: string; email: string }) {
    const events = [
      {
        event_type: HelikaEvents.UserLogin,
        event: {
          event_sub_type: HelikaEventSubTypes.Login,
          user_id: userData.id,
          email: userData.email,
        },
      },
    ]
    this.createEvent(events)
  }

  handleEvent(msg: string, data: any) {
    switch (msg) {
      case AnalyticsEvents.UserRegister:
        this.logUserRegisterEvent(data)
        break
      case AnalyticsEvents.UserLogin:
        this.logUserLoginEvent(data)
        break
      default:
        break
    }
  }
}

export default Helika.getInstance()
