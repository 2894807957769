import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'

// import AppState from 'dash/app_state';

const authLink = setContext((_, { headers }) => {
  // include current account in headers if it exists

  // const currentAccount = AppState.getCurrentAccount();

  return {
    headers: {
      ...headers,
      // ...(currentAccount && currentAccount.id ? { account: currentAccount.id } : {}),
    },
  }
})

const uploadLink = createUploadLink({
  uri: `/api/v1/dash`,
})

const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(uploadLink),
})

export default apolloClient
