import { FC, useEffect, useState } from 'react'

import { gql } from '@apollo/client'
import apolloClient from 'clients/apollo'
import { generateFetchRequest } from 'clients/fetch'
import { Bytes } from 'ethers/lib/utils'
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi'
import { useNotify, useRefresh } from 'react-admin'
import { ConnectorButtons } from './ConnectorButtons'
import AppState from 'dash/app_state'
import { WalletActionType, WalletActionTypes } from '@core/shared/types'
import { Analytics, AnalyticsEvents } from '@core/dash/utils/analytics'

const GET_NONCE = gql`
  query fetchNonce {
    fetchNonce
  }
`

const getNonce = async () => {
  let nonceValue = ''

  try {
    const {
      data: { fetchNonce: nonce },
    } = await apolloClient.query({ query: GET_NONCE })
    nonceValue = nonce
  } catch (e) {
    console.error(e)

    nonceValue = Math.random().toString(16).slice(2)
  }

  return nonceValue
}

export interface WalletConnectButtonProps {
  // Callback function to handle UI interaction when this step is satisfied
  onWalletVerified: () => void
  // handler to allow parent component to handle login redirect
  onLoginSuccess: () => void
  setUserId: (userId: string) => void
  setIsModalVisible: (isVisible: boolean) => void
  walletActionType?: WalletActionType
}

export const WalletConnectButtons: FC<WalletConnectButtonProps> = ({
  setUserId,
  onWalletVerified,
  onLoginSuccess,
  setIsModalVisible,
  walletActionType = WalletActionTypes.AUTH, // default to registration and login
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [nonceMessage, setNonceMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { data: accountData } = useAccount()
  const { disconnect } = useDisconnect()
  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    getNonce().then(function (nonce) {
      setNonceMessage(
        "Welcome Pilot,\n\nSign this message to prove you have access to this wallet and we'll log you in. This won't cost you any Ether.\n\n" +
          "To stop hackers from using your wallet, here's a unique message ID they can't guess: \n" +
          `${nonce}`
      )
    })

    // If account is not connected, but wagmi cache stores the stale connection to account, disconnect it.
    if (!activeConnector) {
      disconnect()
    }
  }, [])

  const { activeConnector, connectAsync, connectors, isConnected } = useConnect({
    onConnect: async (connector: any) => {
      // If user is using an unsupported chain, return early.
      if (connector.chain.unsupported) {
        setIsDisabled(false)
        return
      }
      setIsDisabled(true)
      await signMessageAsync()
    },
    onError: (error: any) => {
      setIsDisabled(false)
      setErrorMessage(error.message)
    },
  })

  const { signMessageAsync, isLoading: isSignLoading } = useSignMessage({
    message: nonceMessage,
    onError(error: any) {
      console.error('Error signing message: ', error.message)
      if (error.message.includes('user rejected signing')) {
        error.message = 'You have rejected the signing request. Please try again.'
      }
      setErrorMessage(error.message)
      setIsDisabled(false)
    },
    async onSuccess(data, variables) {
      setErrorMessage('')
      await registerUserAccount(accountData.address, variables.message, data)
    },
  })

  const registerUserAccount = async (address: string, message: string | Bytes, data: string) => {
    const request = generateFetchRequest({
      headers: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
      },
      method: 'POST',
      path: 'users/auth/web3/callback.json',
      body: JSON.stringify({
        omniauth: {
          provider: 'web3',
          uid: address,
          info: { message: message, signature: data },
        },
      }),
    })

    try {
      const result = await fetch(request)
      const data = await result.json()

      if (result.ok) {
        setErrorMessage('')
        if (walletActionType === WalletActionTypes.ADD_WALLET) {
          console.log('Adding wallet...')
          notify('New wallet has been added', { type: 'success' })
          Analytics.publishEvent(AnalyticsEvents.UserWalletLink)
          // Use custom event handler to update app state.
          const currentUser = AppState.getCurrentUser()
          currentUser.web3Accounts.push(accountData.address)
          AppState.setCurrentUser(currentUser)

          refresh()
          // Close the modal.
          setIsModalVisible(false)
        } else if (walletActionType === WalletActionTypes.AUTH && data.confirmed) {
          console.log('Signing in...')
          Analytics.publishEvent(AnalyticsEvents.UserAuthStart)
          onLoginSuccess()
          setIsModalVisible(false)
        } else {
          console.log('Registering user account...')
          setUserId(data.id)
          onWalletVerified()
        }
      } else {
        console.log('Failed to register user account: ', data)
        if (data.errors) throw new Error(data.errors)
        else if (data.error) throw new Error(data.error)
        else throw new Error('An error has occurred. Please reach out to our team for support.')
      }
    } catch (fetchError) {
      console.error('Failed to register user account: ', fetchError)
      setErrorMessage(fetchError?.message)
      setIsDisabled(false)
    }
  }

  return (
    <ConnectorButtons
      accountData={accountData}
      connectors={connectors}
      connectAsync={connectAsync}
      isConnected={isConnected}
      isDisabled={isDisabled}
      isSignLoading={isSignLoading}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      setIsDisabled={setIsDisabled}
      signMessageAsync={signMessageAsync}
    />
  )
}
