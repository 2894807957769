import { SvgIcon, SvgIconProps } from '@mui/material'

export const SendIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' {...props}>
      <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.3194 3.18129C17.235 3.09733 17.1284 3.03921 17.0122 3.01374C16.8959 2.98828 16.7748 2.99653 16.6631 3.03754L2.91311 8.03754C2.79453 8.08252 2.69244 8.16251 2.62039 8.26689C2.54835 8.37126 2.50977 8.49509 2.50977 8.62191C2.50977 8.74874 2.54835 8.87256 2.62039 8.97694C2.69244 9.08132 2.79453 9.16131 2.91311 9.20629L8.91311 11.6063L11.3131 17.6063C11.3582 17.7197 11.4355 17.8174 11.5354 17.8873C11.6354 17.9573 11.7537 17.9965 11.8756 18C12.0019 17.9974 12.1245 17.9566 12.2271 17.883C12.3298 17.8094 12.4077 17.7064 12.4506 17.5875L17.4506 3.83754C17.4932 3.72696 17.5035 3.60656 17.4802 3.49037C17.457 3.37417 17.4012 3.26698 17.3194 3.18129ZM11.8756 15.625L10.1319 11.25L13.1256 8.25629L12.2444 7.37504L9.22561 10.3938L4.87561 8.62504L15.8319 4.66879L11.8756 15.625Z'
          fill='#F7F3F2'
        />
      </svg>
    </SvgIcon>
  )
}
