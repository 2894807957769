import React, { FC, useState, ChangeEvent } from 'react'
import { Alert, Stack, Typography, TextField, IconButton, InputAdornment } from '@mui/material'
import { Button } from '@atmos/components/buttons/Button'
import { WalletInput } from './WalletInput'
import { Contract } from '../../shared/types'
import { colors } from '@core/dash/layout/CustomLayout/theme'
import { AssetImage } from '@atmos/web3/react-admin/resources/user/assets/assetUtils'
import CloseIcon from '@mui/icons-material/Close'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
const mediaStyle = { width: '100%', objectFit: 'cover', flex: '0 1 50%' }
interface CollectTransferInputProps {
  transferableAssets: number
  onCancel: () => void
  onConfirm: ({ toAddress, quantity }: { toAddress: string; quantity: number }) => void
  isButtonDisabled: boolean
  setIsButtonDisabled: (disabled: boolean) => void
  selectedAsset: any
}

export const CollectTransferInput: FC<CollectTransferInputProps> = ({
  selectedAsset,
  transferableAssets,
  onCancel,
  onConfirm,
  validateTransfer,
  isButtonDisabled,
  setIsButtonDisabled,
}) => {
  const [toAddress, setToAddress] = useState('')
  const [verifyingAddress, setVerifyingAddress] = useState(false)
  const [quantityError, setQuantityError] = useState('')
  const [isValidAddress, setIsValidAddress] = useState(true)
  const [quantity, setQuantity] = useState(1)

  const onQuantityChange = value => {
    setQuantityError('')

    if (isNaN(value) || value <= 0) {
      setQuantityError('Quantity cannot be 0')
      value = 0
    }

    if (value > transferableAssets) {
      setQuantityError('Quantity is greater than owned assets')
    }

    setQuantity(value)
  }

  return (
    <>
      <Stack p={2}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h3'>TRANSFER AN ASSET</Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack direction={{ xxs: 'column', md: 'row' }} alignItems='start' spacing={1}>
          <AssetImage asset={selectedAsset} mediaStyle={mediaStyle} />
          <Typography variant='h6' textTransform='uppercase'>
            {selectedAsset.name || selectedAsset.collectionName}
          </Typography>
        </Stack>
        <TextField
          label='QUANTITY'
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={quantity}
          onChange={event => onQuantityChange(parseInt(event.target.value))}
          error={!!quantityError}
          helperText={quantityError}
          placeholder='0x000...0000'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' sx={{ backgroundColor: colors.grey80 }}>
                <IconButton size='small' onClick={() => onQuantityChange(quantity - 1)} disabled={quantity <= 0}>
                  <RemoveIcon />
                </IconButton>
                <IconButton
                  size='small'
                  onClick={() => onQuantityChange(quantity + 1)}
                  disabled={quantity >= transferableAssets}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <WalletInput
          setToAddress={setToAddress}
          setIsButtonDisabled={setIsButtonDisabled}
          value={toAddress}
          isValidAddress={isValidAddress}
          setVerifyingAddress={setVerifyingAddress}
          setIsValidAddress={setIsValidAddress}
        />

        <Alert severity='info' sx={{ mb: 3 }}>
          <Stack spacing={1}>
            <Typography color='primary.main'>CONFIRM WALLET ADDRESS</Typography>
            <Typography>ASSETS SENT TO AN INCORRECT DESTINATION CANNOT BE RECOVERED</Typography>
          </Stack>
        </Alert>
      </Stack>
      <Stack
        direction={{ xxs: 'column-reverse', md: 'row' }}
        sx={{
          position: 'sticky',
          bottom: 0,
          justifyContent: 'stretch',
          gap: '1px',
          backgroundColor: colors.grey90,
          zIndex: 100,
        }}
      >
        <Button variant='modalGhost' onClick={onCancel} label='CANCEL' />
        <Button
          variant='modalDanger'
          disabled={quantityError || isButtonDisabled}
          onClick={() => validateTransfer(() => onConfirm({ toAddress, quantity }))}
          label={verifyingAddress ? 'Verifying...' : 'Transfer'}
        />
      </Stack>
    </>
  )
}
