import React from 'react'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

let ErrorBoundary = null

try {
  const releaseStage = window.BUGSNAG_RELEASE_STAGE
  console.log('Bugsnag release stage', releaseStage)

  Bugsnag.start({
    apiKey: process.env.BUGSNAG_FE,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: [
      'production', 
      'staging', 
      'development'
    ],
    releaseStage: releaseStage, //process.env.BUGSNAG_RELEASE_STAGE
  })

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
} catch (e) {
  // this is the case where bugsnag keys aren't provided (i.e. local dev environments)
  // in this case, export error boundary that is null
  console.error('Bugsnag initialization error', e)
}

export { Bugsnag, ErrorBoundary }