import { useEffect, useState } from 'react';

import { PassportContext } from './PassportContext'

export const PassportProvider = ({ children }) => {
  const [passport, setPassport] = useState({ passportInstance: null, passportProvider: null, isLoading: true})

  const initPassport = async () => {
    const imtblSdk = window.immutable
    const host = window.location.host

    const passportInstance = new imtblSdk.passport.Passport({
      baseConfig: {
        environment: window.RELEASE_STAGE == 'production' ? imtblSdk.config.Environment.PRODUCTION : imtblSdk.config.Environment.SANDBOX,
        publishableKey: window.PASSPORT_PUBLISHABLE_KEY
      },
      clientId: window.PASSPORT_CLIENT_ID,
      redirectUri: `https://${host}/dash#/auth-callback?passport_logged_in=true`,
      audience: 'platform_api',
      scope: 'transact openid offline_access email',
      logoutRedirectUri: `https://${host}/dash#/auth-callback?passport_logged_out=true`,
      logoutMode: 'silent', // i.e. don't redirect
    })

    try {
      const passportProvider = passportInstance.connectEvm();
      setPassport({ passportInstance, passportProvider, isLoading: false })
    } catch (e) {
      console.error('passportInstance.connectEvm error', e)
    }
  }

  useEffect(() => {
    if (window.immutable) initPassport()
    else window.onload = initPassport
  }, [])

  return (
    <PassportContext.Provider value={passport}>
      {children}
    </PassportContext.Provider>
  )
}