import { generateFetchRequest } from "@core/clients/fetch";

// NOTE: this function can only be used for an already logged in user.
// The identity is connected to the current_user on the backend
export const connectWeb3Identity = async (account: string, info: any) => {
  try {
    const request = generateFetchRequest({
      headers: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: 'POST',
      path: 'users/auth/web3/callback',
      body: JSON.stringify({
        omniauth: {
          provider: 'web3',
          uid: account,
          info,
        },
      }),
    });

    const result = await fetch(request);
    const data = await result.json();
    if (!result.ok) throw new Error(data.errors || data.error || `error connecting account`);
  } catch (error) {
    throw new Error(`passport web3 identity connect error for account ${account}: ${error}`);
  }
};
