import React, { FC } from 'react'
import { IconButton, Snackbar, Typography, TypographyProps, Stack, Alert } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { useState } from 'react'

export const TypographyCopyable: FC<TypographyProps> = ({ children, sx, ...rest }) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(children.toString())
  }

  return (
    <>
      <Typography sx={{ whiteSpace: 'nowrap', ...sx }} {...rest}>
        {children}
        <IconButton onClick={handleClick} sx={{ display: 'inline-flex' }}>
          <ContentCopy color='primary' sx={{ fontSize: { xxs: '0.8rem', xs: '1rem', sm: '1.25rem' } }} />
        </IconButton>
      </Typography>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message='Copied to clipboard'
        sx={{
          '.MuiPaper-root': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
        }}
      >
        <Alert severity='success' sx={{ width: '100%' }}>
          COPIED TO CLIPBOARD
        </Alert>
      </Snackbar>
    </>
  )
}
