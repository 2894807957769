import { Account, Membership, User } from 'shared/types'
import { FETCH_ACCOUNT } from 'shared/gql'
import apolloClient from 'clients/apollo'
import PubSub from 'pubsub-js'

export const UserUpdateEvent = 'userUpdateEvent'

export default class AppState {
  static getCurrentAccount = (): Account => {
    const currentAccount = localStorage.getItem('currentAccount')
    return currentAccount != null ? JSON.parse(currentAccount) : null
  }

  static getCurrentUser = (): User => {
    const currentUser = localStorage.getItem('currentUser')
    return currentUser != null ? JSON.parse(currentUser) : null
  }

  static getIdentity = (): { account: Account; user: User } => {
    return { account: AppState.getCurrentAccount(), user: AppState.getCurrentUser() }
  }

  static getLastPage = (): string => {
    return localStorage.getItem('lastPage')
  }

  static getRoles = (): string[] => {
    const currentUser = AppState.getCurrentUser()
    if (!currentUser) return []
    return currentUser.roles
  }

  static getIsAccountView = (): boolean => {
    // TODO naive. Better solution?
    return window.location.href.includes('/dash#/account') || window.location.href.includes('/dash#/services')
  }

  static getAccountIdFromURL = (): string => {
    const url = new URL(document.location.href)
    const urlParts = url.hash.split('/')
    if (urlParts[1] == 'account') return urlParts[2]
    return null
  }

  static authCookiePresent = (): boolean => {
    return document.cookie.includes('core_session_signed_in')
  }

  static reset = (): void => {
    AppState.setIdentity({})
  }

  static resetLastPage = (): void => {
    localStorage.removeItem('lastPage')
  }

  static setCurrentAccount = (account?: Account): void => {
    localStorage.setItem('currentAccount', account != null ? JSON.stringify(account) : null)
  }

  static setCurrentUser = (user?: User): void => {
    localStorage.setItem('currentUser', user != null ? JSON.stringify(user) : null)
    // PubSub event to dynamically update current user data in the app, used for wallet addresses and username.
    if (user) PubSub.publish(UserUpdateEvent, user)
  }

  static setIdentity = async ({ account, user }: { account?: Account; user?: User }): Promise<void> => {
    AppState.setCurrentUser(user)

    if (!account && user?.memberships) account = user.memberships.find((m: Membership) => m.status == 'active')?.account

    if (account?.id) await AppState.updateCurrentAccount(account)
    else AppState.setCurrentAccount(null)
  }

  static setLastPage = (url: string): void => {
    localStorage.setItem('lastPage', url)
  }

  static updateCurrentAccount = async (account: Account): Promise<void> => {
    // set current account initially so that request.headers['account'] updates
    // and the api will throw an error if the user doesn't have access to the account

    AppState.setCurrentAccount(account)

    try {
      const {
        data: { Account: fetchedAccount },
      } = await apolloClient.query({
        query: FETCH_ACCOUNT,
        variables: { id: account.id },
        fetchPolicy: 'no-cache',
      })

      AppState.setCurrentAccount(fetchedAccount)
    } catch (e) {
      console.error(e)
    }
  }
}
