import React, { CSSProperties, useEffect, useRef } from 'react'

const imageFallback = '/images/fallback-image.png'

// Media style.
const defaultMediaStyle: CSSProperties = { width: '100%', height: '100%', objectFit: 'cover' }

// Card title, with fallbacks.
export const assetTitle = asset => {
  const upperCaseTitle = (asset.displayName || asset.name || asset.id).toString().toUpperCase()
  // Remove 'ATMOS | ' from title and return.
  return upperCaseTitle.replace('ATMOS | ', '')
}
// Card media cover.
export const AssetImage = ({ asset, mediaStyle = defaultMediaStyle, onlyImage = false }) => {
  // This is needed to force the video to play. Need to think about performance when autoplaying many videos at once.
  const vidRef = useRef()
  useEffect(() => {
    if (vidRef.current) vidRef.current.play()
  }, [])

  if (onlyImage || !asset.animationUrl) {
    return <img style={{ ...mediaStyle, overflow: 'hidden' }} src={asset.imageUrl || imageFallback} />
  }

  return (
    <video
      key={asset.animationUrl} // force re-render when animationUrl changes
      autoPlay
      ref={vidRef}
      loop
      controls
      muted
      playsInline
      disablePictureInPicture
      controlsList='nodownload noplaybackrate nofullscreen'
      poster={asset.imageUrl}
      preload='metadata'
      style={{ ...mediaStyle, overflow: 'hidden' }}
    >
      <source src={asset.animationUrl} type='video/mp4' />
    </video>
  )
}
