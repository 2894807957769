import React, { forwardRef, useCallback, useEffect, useState } from 'react'

import { MenuItemLink, UserMenu as ReactAdminUserMenu, useUserMenu } from 'react-admin'
import { Typography, Divider, Link, Stack, Avatar, Badge } from '@mui/material'
import { useAccount, useConnect } from 'wagmi'

import { User } from 'shared/types'
import AppState from 'dash/app_state'
import Auth from 'dash/auth'
import { WalletStatus } from './WalletStatus'
import { colors } from '@core/dash/layout/CustomLayout/theme'
import PubSub from 'pubsub-js'
import { UserUpdateEvent } from 'dash/app_state'

const UserMenuHeader = forwardRef<any, any>(() => {
  const [user, setUser] = useState(AppState.getCurrentUser())

  const fullUsername = user.username.toUpperCase()
  const callsign = fullUsername.split('#')[0]

  const updateUser = (_, user: User) => setUser(user)

  useEffect(() => {
    const subscriptionToken = PubSub.subscribe(UserUpdateEvent, updateUser)
    return () => PubSub.unsubscribe(subscriptionToken)
  }, [])

  return (
    <Stack direction='row' p={2} width='340px' spacing={2}>
      <img src='/images/user-img.png' style={{ height: '3rem' }}></img>
      <Stack spacing={1}>
        <Typography fontWeight={600}>{callsign}</Typography>
        <Typography variant='h7'>{fullUsername}</Typography>
      </Stack>
    </Stack>
  )
})

const ConfigurationMenu = forwardRef<any, any>((_, ref) => {
  const { onClose } = useUserMenu()
  return (
    <MenuItemLink
      ref={ref}
      to='/user/me/profile'
      sx={{ color: 'primary.main' }}
      tooltipProps={{
        children: null,
        title: null,
        open: false,
      }}
      primaryText={<Typography variant='h6'>PREFERENCES</Typography>}
      onClick={onClose}
    />
  )
})

export const UserMenu = props => {
  const { data } = useAccount()
  const accountAddress = data?.address
  const displayAddress = accountAddress ? accountAddress.slice(0, 5) + '...' + accountAddress.slice(-4) : 'WALLET'

  const { activeConnector } = useConnect() // accountAddress is updated only when disconnecting or switching wallet. This hook helps with updates when connecting a new wallet.
  const userWeb3Accounts = AppState.getCurrentUser()?.web3Accounts || []
  const [isLinkedAddress, setIsLinkedAddress] = useState(false)

  useEffect(() => {
    setIsLinkedAddress(userWeb3Accounts.includes(accountAddress) && !!activeConnector)
  }, [accountAddress, activeConnector, userWeb3Accounts])

  useEffect(() => {
    const subscriptionToken = PubSub.subscribe(UserUpdateEvent, toggleLinkedAddress)
    return () => PubSub.unsubscribe(subscriptionToken)
  }, [activeConnector])

  const toggleLinkedAddress = (_, user: User) => {
    setIsLinkedAddress(user.web3Accounts.includes(accountAddress) && !!activeConnector)
  }

  // Wrapped in a callback to force re-render when active connector is disconnedted.
  const getBadgeColor = () => {
    if (!accountAddress) return 'error.main'
    if (!isLinkedAddress) return 'text.secondary'
    return 'success.main'
  }

  return (
    <ReactAdminUserMenu
      {...props}
      icon={
        <>
          <Badge
            sx={{
              '&::after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: -3,
                right: -6,
                width: '10px',
                height: '10px',
                boxSizing: 'content-box',
                borderRadius: '50%',
                border: `2px solid ${colors.grey100}`,
                backgroundColor: getBadgeColor(),
              },
            }}
          >
            <Avatar
              src='/images/user-img.png'
              sx={{
                height: 32,
                width: 32,
              }}
            />
          </Badge>
        </>
      }
    >
      {AppState.getCurrentUser() && (
        <>
          <UserMenuHeader />
          <Divider sx={{ borderStyle: 'solid', my: 1 }} />
          <Typography variant='h7' color='text.secondary' pl={2}>
            {displayAddress}
          </Typography>
          <WalletStatus />
          <ConfigurationMenu />
        </>
      )}
      {/* {AppState.getCurrentAccount() && <AccountMenu />} */}
      <Auth.LogoutButton />
    </ReactAdminUserMenu>
  )
}
