import React, { FC, useEffect, useState } from 'react'
import { Box, Typography, Stack, IconButton, Tooltip } from '@mui/material'
import AppState from 'dash/app_state'
import { User } from 'shared/types'
import ConnectButton from '@atmos/components/Web3ConnectButton'
// Enums
import { WalletActionTypes } from '@core/shared/types'
import { useAccount, useConnect } from 'wagmi'
import { EthIcon } from '@atmos/icons/EthIcon'
import { EthErrorIcon } from '@atmos/icons/EthErrorIcon'
import PubSub from 'pubsub-js'
import { UserUpdateEvent } from 'dash/app_state'

const commonIconStyles = {
  content: '""',
  display: 'block',
  position: 'absolute',
  top: -2,
  right: -1,
  width: 9,
  height: 9,
  borderRadius: '50%',
}

export const WalletStatus: FC = ({}) => {
  const { data } = useAccount()
  const { activeConnector } = useConnect() // accountAddress is updated only when disconnecting or switching wallet. This hook helps with updates when connecting a new wallet.
  const accountAddress = data?.address
  const userWeb3Accounts = AppState.getCurrentUser()?.web3Accounts || []

  const [isLinkedAddress, setIsLinkedAddress] = useState(false)

  useEffect(() => {
    setIsLinkedAddress(userWeb3Accounts.includes(accountAddress) && !!activeConnector)
  }, [accountAddress, activeConnector, userWeb3Accounts])

  useEffect(() => {
    const subscriptionToken = PubSub.subscribe(UserUpdateEvent, toggleLinkedAddress)
    return () => PubSub.unsubscribe(subscriptionToken)
  }, [])

  const toggleLinkedAddress = (_, user: User) => {
    setIsLinkedAddress(user.web3Accounts.includes(accountAddress) && !!activeConnector)
  }

  if (!accountAddress)
    return (
      <Stack direction='row' px={2} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center'>
          <Typography variant='h6' color='error'>
            LOCKED
          </Typography>
          <StatusIcon status='disconnected' />
        </Stack>
        <ConnectButton variant='text' label='unlock' showArrow={false} walletActionType={WalletActionTypes.AUTH} />
      </Stack>
    )

  if (!isLinkedAddress)
    return (
      <Stack direction='row' px={2} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center'>
          <Typography variant='h6'>NOT LINKED</Typography>
          <StatusIcon status='not-linked' />
        </Stack>
        <ConnectButton variant='text' label='link' showArrow={false} walletActionType={WalletActionTypes.ADD_WALLET} />
      </Stack>
    )
  return (
    <Stack direction='row' px={2} alignItems='center'>
      <Typography variant='h6'>LINKED</Typography>
      <StatusIcon status='connected' />
    </Stack>
  )
}

const StatusIcon = ({ status }) => {
  const getColor = status => {
    switch (status) {
      case 'connected':
        return 'success.main'
      case 'not-linked':
        return 'text.secondary'
      case 'disconnected':
        return 'transparent'
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        '&::after': {
          ...commonIconStyles,
          backgroundColor: getColor(status),
        },
      }}
    >
      {status === 'disconnected' ? <EthErrorIcon /> : <EthIcon />}
    </Box>
  )
}
