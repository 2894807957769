import { Stack, Box, useMediaQuery, Theme } from '@mui/material'
import { APP_CONFIG } from 'app_config'
import { CSSProperties } from 'react'

const defaultStyle = {
  boxSizing: 'content-box',
  position: 'relative',
  height: 40,
  width: 'auto',
} as CSSProperties

interface LogoProps {
  styleDesktop?: CSSProperties
  styleMobile?: CSSProperties
}

const Image = ({ src, style = {} }) => {
  return <img src={src} style={{ ...defaultStyle, ...style }} alt={APP_CONFIG.name} />
}

export const Logo: React.FC<LogoProps> = ({ styleDesktop, styleMobile }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  if (isDesktop) {
    return <Image src='/images/logo.svg' style={styleDesktop} />
  }
  return <Image src='/images/logo-mobile.svg' style={{ height: 20, padding: 0, ...styleMobile }} />
}
