import { SvgIcon, SvgIconProps } from '@mui/material'

export const EthIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 31 33' {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.9989 0.882812L7.34375 17.0391L15.9989 30.8873L24.654 17.0391L15.9989 0.882812ZM11.3303 19.6439L15.9989 27.1137L20.6675 19.6439L15.9989 22.1338L11.3303 19.6439ZM22.1443 16.5896L15.9989 5.11811L9.85346 16.5896L15.9989 19.8671L22.1443 16.5896Z'
      />
    </SvgIcon>
  )
}
