import { FC, useEffect, useState } from 'react'

import { gql } from '@apollo/client'
import apolloClient from 'clients/apollo'
import { useNotify } from 'react-admin'
import { Navigate, useParams } from 'react-router-dom'

import { Spinner } from '@atmos/components/Spinner'
import { Analytics, AnalyticsEvents } from '@core/dash/utils/analytics'

const GET_UNLOCKABLE_CONTENT = gql`
  query allUnlockableContent($filter: UnlockableContentFilter) {
    allUnlockableContent(filter: $filter) {
      title
      contentType
      content
      assetKey
    }
  }
`

export const NFTViewer: FC = () => {
  const [unlockableContent, setUnlockableContent] = useState<any>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const { assetId, contentId } = useParams()

  const notify = useNotify()

  useEffect(() => {
    const fetchUnlockableContent = async () => {
      try {
        const {
          data: { allUnlockableContent },
        } = await apolloClient.query({
          query: GET_UNLOCKABLE_CONTENT,
          variables: {
            filter: { assetId, ids: [contentId] },
          },
        })
        if (allUnlockableContent.length === 0) {
          setErrorMessage('No unlockable content found for this asset')
        } else {
          Analytics.publishEvent(AnalyticsEvents.UnlockableContentOpen)
          setUnlockableContent(allUnlockableContent)
        }
      } catch (error) {
        setErrorMessage(error.message)

        console.error('error', error)
      }
      setIsLoading(false)
    }
    fetchUnlockableContent()
  }, [])

  if (isLoading)
    return (
      <div className='spinner-wrapper'>
        <Spinner />
      </div>
    )

  // Redirect to asset page if user is not an owner
  if (errorMessage) {
    notify('Error: ' + errorMessage, { type: 'error' })
    return <Navigate to='/user/asset/' replace />
  }

  return (
    <iframe src={unlockableContent[0].content} id='iframe_container' className='unlockable-content-viewer'>
      <h1>Your browser doesn't support iframes</h1>
    </iframe>
  )
}
