import React, { FC, useCallback } from 'react'
import { TextField, Stack, Typography } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { debounce } from 'shared/helpers'

interface UsernameFormProps {
  validateUniquenessFunc?: (username: string) => Promise<boolean>
  form: UseFormReturn<{ email: any; username: any; tag: any; language: any }>
  defaultFullUsername: string
}

const UsernameInput: FC<UsernameFormProps> = ({ form, validateUniquenessFunc, defaultFullUsername }) => {
  // State.
  const username = form.watch('username')
  const tag = form.watch('tag')
  const fullUsername = `${username}#${tag}`
  // Errors
  const { username: usernameError, tag: tagError } = form.formState.errors

  // Makes server request to validate username uniqueness.
  const validateUsername = async () => {
    const fullUsernameIsUnchanged = fullUsername === defaultFullUsername
    try {
      // Don't make the request if the username is unchanged.
      const isUnique = fullUsernameIsUnchanged || (await validateUniquenessFunc(fullUsername))
      if (isUnique) {
        return true
      } else {
        return 'Username is already taken'
      }
    } catch (error) {
      return 'Error validating username'
    }
  }

  const debouncedValidate = useCallback(
    debounce(() => {
      // This triggers custom validation declared in the rules prop if there are no errors from other rules.
      form.trigger(['username', 'tag'])
    }, 1000),
    []
  )

  // Validation rules.
  const usernameRules = {
    required: 'Username is required',
    pattern: {
      value: /^[a-zA-Z][a-zA-Z0-9-_]/,
      message: 'Username must start with an alpha character and can only contain alphanumeric characters',
    },
    minLength: { value: 3, message: 'Username must be at least 3 characters' },
    maxLength: { value: 16, message: 'Username cannot exceed 16 characters at most' },
    validate: validateUsername,
  }

  const tagRules = {
    required: 'Tag is required',
    pattern: { value: /^[0-9]{4}$/, message: 'Tag must be a 4 digit number' },
  }

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Controller
        name='username'
        control={form.control}
        rules={usernameRules}
        render={({ field }) => (
          <TextField
            {...field}
            id='usernameTextInput'
            label='USERNAME'
            sx={{ width: '70%' }}
            error={!!usernameError}
            helperText={usernameError?.message}
            onChange={event => {
              field.onChange(event)
              debouncedValidate()
            }}
            placeholder='PILOT'
          />
        )}
      />
      <Typography variant='body1'>#</Typography>
      <Controller
        name='tag'
        control={form.control}
        rules={tagRules}
        render={({ field }) => (
          <TextField
            {...field}
            id='usernameTagInput'
            error={!!tagError}
            helperText={tagError?.message}
            label='TAG'
            style={{ width: '30%' }}
            onChange={event => {
              field.onChange(event)
              debouncedValidate()
            }}
            placeholder='1234'
          />
        )}
      />
    </Stack>
  )
}

export default UsernameInput
